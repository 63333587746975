import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { Client} from '../../models/client.model';
import { forkJoin, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-quiz3',
  templateUrl: './quiz3.component.html',
  styleUrls: ['./quiz3.component.scss']
})
export class Quiz3Component implements OnInit {

  public isAuth: boolean = false;
  public contentUser = {};
  public answerQuestions = {};
  public client = new Client('', '');
  public questions = [];
  public modulefinished = false;
  public numberModule = 3;
  public completeAllQuiz = false;
  public quiz3Form = this.fb.group({
    question1: ['', [Validators.required]],
    question2: ['', Validators.required],
  },
    {}
  ); 

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    var moduleFinalized = localStorage.getItem('finish-3');
    if( moduleFinalized == 'final' ) {
      this.router.navigateByUrl('/inicio');
      return;
    }
    var now = new Date();
    if( now.getTime() > Date.parse( localStorage.getItem('date_expiration') ) && localStorage.getItem('date_expiration') != null ) {
      const requests = [
        this.clientService.refrescarToken(),
      ];
      from(requests).pipe(    
        concatMap((request) => request.pipe())
      ).subscribe((data) => {
        this.clientService.obtenerUsuario().subscribe(
          res => {
            if( res == false ) {
              this.router.navigateByUrl('/inicio');
              return;
            }
            this.contentUser = res['data'];
            this.client.address = this.contentUser['address'];
            this.client.age = this.contentUser['age'];
            this.client.cellPhone = this.contentUser['cellPhone'];
            this.client.ci = this.contentUser['ci'];
            this.client.city = this.contentUser['city'];
            this.client.completeRegister = this.contentUser['completeRegister'];
            this.client.currentModule = this.contentUser['currentModule'];
            this.client.disability = this.contentUser['disability'];
            this.client.educationLevel = this.contentUser['educationLevel'];
            this.client.email = this.contentUser['email'];
            this.client.gender = this.contentUser['gender'];
            this.client.nombre = this.contentUser['nameComplete'];
            this.client.nameComplete = this.contentUser['nameComplete'];
            this.client.phone = this.contentUser['phone'];
            this.client.role = this.contentUser['role'];        
            this.isAuth = true;
            if( typeof this.contentUser['finishedModules'] != 'undefined' ) {
              var existModule = this.contentUser['finishedModules'].filter( elem => {
                return elem.moduleNumber == this.numberModule;
              });
              if( existModule.length > 0 ) {
                this.router.navigateByUrl('/inicio');
              }  
            }        
          }
        );
      });
    } else {
      if( localStorage.getItem('email') ) {
        this.clientService.obtenerUsuario().subscribe(
          data => {
            if( data == false ) {
              this.router.navigateByUrl('/inicio');
              return;
            }
            this.isAuth = true;
            this.contentUser = data['data'];
            this.client.address = this.contentUser['address'];
            this.client.age = this.contentUser['age'];
            this.client.cellPhone = this.contentUser['cellPhone'];
            this.client.ci = this.contentUser['ci'];
            this.client.city = this.contentUser['city'];
            this.client.completeRegister = this.contentUser['completeRegister'];
            this.client.currentModule = this.contentUser['currentModule'];
            this.client.disability = this.contentUser['disability'];
            this.client.educationLevel = this.contentUser['educationLevel'];
            this.client.email = this.contentUser['email'];
            this.client.gender = this.contentUser['gender'];
            this.client.nombre = this.contentUser['nameComplete'];
            this.client.nameComplete = this.contentUser['nameComplete'];
            this.client.phone = this.contentUser['phone'];
            this.client.role = this.contentUser['role'];
            if( typeof this.contentUser['finishedModules'] != 'undefined' ) {
              var existModule = this.contentUser['finishedModules'].filter( elem => {
                return elem.moduleNumber == this.numberModule;
              });
              if( existModule.length > 0 ) {
                this.router.navigateByUrl('/inicio');
              }  
            }
          },
          err => {
            this.router.navigateByUrl('/inicio');
          }
        );
      }
    }
    
    this.clientService.obtenerQuiz( this.numberModule ).subscribe(
      data => {
        this.questions = data['data'].questions;
          // Uniendo en un solo objeto las respuestas para poder manejar la data fácil
        this.questions.forEach(question => {
          question.answers.forEach(answer => {
            this.answerQuestions[answer.idAnswer] = answer.state;
          });
        });
      },
      err => {
        this.router.navigateByUrl('/inicio');
      }
    );
  }

  completarQuiz3( dataQuiz ) {
    let emptyMessage = document.querySelector('#empty-quiz' );
    emptyMessage.setAttribute( 'style', 'display:none;' );
    if( this.verificarVacio( dataQuiz ) ) {
      emptyMessage.setAttribute( 'style', 'display:block' );
      return;
    }
    if( this.verificarRespuestas( dataQuiz ) == false ) {
      return;
    };

      // MODAL QUE INDICA SE FINALIZÓ TODOS LOS MÓDULOS
    let btnFinishModuleQuiz = document.getElementById('modal-quiz-5') as HTMLElement;

      // MODAL QUE INDICA QUE SOLO SE MODIFICÓ EL MÓDULO ACTUAL
    let btnQuiz        = document.getElementById('btn-quiz-' + this.numberModule) as HTMLElement;;
    if( this.completeAllQuiz ) {
      btnFinishModuleQuiz.click();
      return;
    } else if( this.modulefinished ) {
      btnQuiz.click();
      return;
    }
    var now = new Date();
    if( now.getTime() > Date.parse( localStorage.getItem('date_expiration') ) && localStorage.getItem('date_expiration') != null ) {
      const requests = [
        this.clientService.refrescarToken(),
      ];
      from(requests).pipe(    
        concatMap((request) => request.pipe())
      ).subscribe((data) => {
        this.clientService.guardarQuiz(this.numberModule).subscribe(
          res => {
            localStorage.removeItem( 'module' );
            if ( ( this.contentUser['finishedModules'].length + 1 ) == 5 ) {
              this.completeAllQuiz = true;
              btnFinishModuleQuiz.click();
            } else {
              btnQuiz.click();
            }
            this.modulefinished = true;
          },
          err => {
            alert( err.error.message );
          }
        )
      });
    } else {
      this.clientService.guardarQuiz( this.numberModule ).subscribe(
        data => {
          localStorage.removeItem( 'module' );
          if ( ( this.contentUser['finishedModules'].length + 1 ) == 5 ) {
            this.completeAllQuiz = true;
            btnFinishModuleQuiz.click();
          } else {
            btnQuiz.click();
          }
          this.modulefinished = true;
        },
        err => {
          alert( err.error.message );
        }
      );  
    }    
  }

  verificarRespuestas( dataQuiz ) {
    var allOkey = true;
      // Limpiando los checks al crearlos
    var el = document.getElementsByClassName("delete-this-icon");
    while (el.length > 0) el[0].remove();
    for( let element in dataQuiz ) {
      var icon = document.createElement('i');
      if( this.answerQuestions[dataQuiz[element]] ) {
        icon.setAttribute('class', 'delete-this-icon fa fa-check-circle color-succes ml-2');
        icon.setAttribute('aria-hidden', 'true');
      } else {
        allOkey = false;
        icon.setAttribute('class', 'delete-this-icon fa fa-times-circle color-error ml-2');
        icon.setAttribute('aria-hidden', 'true');
      }
      var app = document.querySelector('#check-answer-' + dataQuiz[element] );
      app.append( icon );
    }
    return allOkey;

  }

  verificarVacio( dataQuiz ) {
    for( let element in dataQuiz ) {
      if( dataQuiz[element] == '' ) {
        return true;
      }
    }
    return false;
  }

  volverAtras() {
    this.router.navigateByUrl('/modulo-3', { state: [{back:true}] });
  }    

  redirectHome() {
    this.router.navigate(['/inicio']);
  }
}
