<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 text-left module_header">
                    <h2 class="color-2 wow zoomIn">Ahorro</h2>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-4 wow fadeIn">Podemos hacer tres cosas con nuestro dinero:</h2>
                            <br>
                            <div class="content-select-card">
                                <div class="row align-items-end justify-content-center">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.2s">
                                            <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert()">
                                                <img class="mb-4" src="assets/images/module-2/1.svg" width="50%" alt="Ecofuturo">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>Gastar</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.6s">
                                            <a data-toggle="modal" data-target="#slide2modal" (click)="dismissAlert2()">
                                                <img class="mb-4" src="assets/images/module-2/2.svg" width="40%" alt="Ecofuturo">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>Ahorrar</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="2s">
                                            <a data-toggle="modal" data-target="#slide3modal" (click)="dismissAlert3()">
                                                <img class="mb-4" src="assets/images/module-2/3.svg" width="40%" alt="Ecofuturo">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>Invertir</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissThree">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="col-lg-4">
                        <a class="btn bg-2 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s"
                            (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 3%;" aria-valuenow="3"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite" >
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 text-left module_header">
                    <h2 class="color-2">Ahorro</h2>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-xl-11 col-12">
                            <img class="mb-4 wow wobble" data-wow-delay="0.5s" data-wow-duration="1.5s" data-wow-iteration="infinite" src="assets/images/module-2/4.svg" width="25%">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay="1s">¿Por qué es importante ahorrar?</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1.5s">Porque mejora la administración de tu dinero, facilitando su disposición a futuro ante algún evento importante, imprevisto o emergencia económica.</p>
                            <p class="text-justify wow bounceInUp" data-wow-delay="2s">Asimismo, te ayuda a cumplir tus objetivos en el menor tiempo posible a través del establecimiento de los mismos a corto, mediano y largo plazo y la determinación de un plan de ahorro mensual para cada uno de ellos. </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-auto">
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="activeDemo()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 20%;" aria-valuenow="20"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 text-left module_header">
                    <h2 class="color-2">Ahorro</h2>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-2 wow bounceIn" data-wow-delay="1s">¿Cómo calcular cuánto ahorrar?</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1.4s">Con tus objetivos establecidos, requieres
                                calcular cuánto debes y puedes ahorrar mensualmente para alcanzarlos. Para ello
                                necesitas hacer un registro de tus <strong>ingresos y tus gastos</strong>, es decir,
                                elaborar un presupuesto.</p>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1.6s">El presupuesto es el cálculo de tus ingresos (el dinero que recibes), tus gastos (el dinero que gastas) y el saldo (el dinero sobrante, el cual puedes destinar a otros aspectos como la inversión o el ahorro). </p>
                            <!-- <p class="wow bounceInUp" data-wow-delay="1.8s">Para ello debes hacer una lista de INGRESOS, otra lista de GASTOS, como el siguiente ejemplo:</p> -->
                            <!-- <div class="center text-center my-3 d-inline-block wow bounceInUp" data-wow-delay="2s">
                                <h6 class="text-white center bg-2 rounded-lg d-table-cell px-3 py-2 my-5 wow pulse pointer" data-wow-iteration="infinite" (click)="viewIncomeList()" *ngIf="hideBtn3">Ver ejemplo de Presupuesto</h6>
                            </div>                 -->
                            <!-- <div class="row justify-content-center align-items-start income_table bg_income wow fadeIn" data-wow-delay="3s">
                                <div class="col-12">
                                    <h4 class="main-title color-2 mb-2"></h4>
                                </div>
                                <div class="col-auto col-xl-6 wow fadeIn" data-wow-delay="2s">
                                    <div class="px-4 text-left">
                                        <h5 class="incomes-title color-2"></h5>
                                        <p class="incomes"></p>
                                    </div>
                                </div>
                                <div class="col-auto col-xl-6 wow fadeIn" data-wow-delay="2s">
                                    <div class="px-4 text-left">
                                        <h5 class="color-2 expenses-title"></h5>
                                        <p class="expenses"></p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-auto">
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="activeWow()">SIGUIENTE</a>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 30%;" aria-valuenow="30"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol>
                        <li class="light-text">
                            <h2 class="color-2">Ahorro</h2>
                        </li>
                        <li>
                            <h2 class="color-2">Presupuesto</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center screen-1 text-center">
                        <div class="col-xl-12 col-lg-12 col-md-10">
                            <!-- <h1 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">INTERACTIVO: UNA ANIMACIÓN</h1> -->
                            <h2 class="color-2 pb-2 wow bounceIn" data-wow-delay="1s">¿Qué es y cómo hacer un presupuesto? </h2>

                            <p class="text-justify wow bounceInUp mb-2" data-wow-delay="2s">Luego de sumar ambas columnas, obtendrás el
                                total de ingresos y gastos que tienes al mes. A continuación, deberás restar tus gastos de tus ingresos para obtener el saldo. Dicho saldo es el dinero que tienes para invertir, gastar o AHORRAR.</p>
                        </div>
                        <div class="col-xl-7">
                            <video class="video-presupuesto" id="sample_video" width="100%" controls="false" controlslist="nodownload">
                                <source src="assets/video/credito.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-center align-items-start income_table bg_income wow fadeIn" data-wow-delay="3s">
                        <div class="col-12 col-xl-12">
                            <h4 class="main-title color-2 mb-4">Mi Presupuesto</h4>
                        </div>
                        <div class="col-auto col-xl-6 wow fadeIn" data-wow-delay="2s">
                            <div class="px-4 text-left">
                                <h5 class="color-2">Lista de Ingresos</h5>
                                <p>Sueldo básico -- 3.645 Bs.</p>
                                <p>Cobro de alquileres -- 2580 Bs.</p>
                                <p>Préstamo cobrado -- 1065 Bs.</p>
                                <p><small class="color-3 instruction-1"></small></p>
                                <div class="d-flex align-items-center">
                                    <h6 class="color-2 m-0 total-incomes-title"></h6>
                                    <p class="m-0 ml-2 incomes_total"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto col-xl-6 wow fadeIn" data-wow-delay="2s">
                            <div class="px-4 text-left">
                                <h5 class="color-2">Lista de Gastos</h5>
                                <p>Canasta familiar -- 634 Bs.</p>
                                <p>Consulta médica -- 345 Bs.</p>
                                <p>Servicio de agua -- 80 Bs.</p>
                                <p><small class="color-3 instruction-2"></small></p>
                                <div class="d-flex align-items-center">
                                    <h6 class="color-2 m-0 total-expenses-title"></h6>
                                    <p class="m-0 ml-2 expenses_total"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 center">
                            <h6 class="text-white center bg-2 rounded-lg px-3 py-2 my-5 wow pulse pointer" data-wow-iteration="infinite" (click)="viewOperations()" *ngIf="hideBtn1">Realizar las Operaciones</h6>
                        </div>

                        <div class="col-12">
                            <p><small class="color-3 instruction-3"></small></p>
                            <div class="d-flex align-items-center justify-content-center">
                                <h5 class="color-2 mt-2 balance-title"></h5>
                                <p class="m-0 ml-2 balance"></p>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="col-lg-auto">
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="stopVideo()">ANTERIOR</a>
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="stopVideo()">SIGUIENTE</a>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 60%;" aria-valuenow="60"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol>
                        <li class="light-text">
                            <h2 class="color-2">Ahorro</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Presupuesto</h2>
                        </li>
                        <li>
                            <h2 class="color-2">Beneficios</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center justify-content-center">
                        <div class="col-md-4 col-sm-6 col-12">
                            <img class="icon_progress wow slideInRight" data-wow-delay=".5s" src="assets/images/module-2/5.svg" width="70%">
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">¿Cuáles son los beneficios de ahorrar?</h2>
                        </div>
                        <div class="col-12">
                            <div class="row align-items-center module_items">
                                <div class="col-md-6 col-sm-6 col-12" *ngFor="let item of items">
                                    <div class="row">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 text-right">
                                            <img class="icon_progress wow fadeIn" data-wow-delay="1s" src="{{item.icon}}" width="{{item.size}}">
                                        </div>
                                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8 p-0 text-justify">
                                            <h6 class="wow fadeIn" data-wow-delay="1.4s"><strong>{{item.title}}</strong></h6>
                                            <p class="wow fadeIn" data-wow-delay="1.8s">{{item.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter2"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 75%;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 6 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol>
                        <li class="light-text">
                            <h2 class="color-2">Ahorro</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Presupuesto</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Beneficios</h2>
                        </li>
                        <li>
                            <h2 class="color-2">Recomendaciones</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">Recomendaciones para ahorrar:</h2>
                            <div class="row align-items-center">
                                <div class="col-xl-3 col-lg-5 col-md-5 col-sm-12 img-size-resp">
                                    <img class="icon_progress mb-3 wow wobble" data-wow-delay="0.5s" data-wow-duration="1.5s" data-wow-iteration="infinite" src="assets/images/module-2/11.svg">
                                </div>
                                <div class="col-xl-9 col-lg-7 col-md-7 col-sm-12">
                                    <ul class="module_list">
                                        <li class="wow fadeInRight" data-wow-delay="1.2s">
                                            <p class="text-justify mb-0">Debes fijar una meta o un objetivo a corto, mediano o largo plazo.</p>
                                        </li>
                                        <li class="wow fadeInRight" data-wow-delay="1.4s">
                                            <p class="text-justify mb-0">Debes conocer y detallar bien tus ingresos y gastos de manera mensual.</p>
                                        </li>
                                        <li class="wow fadeInRight" data-wow-delay="1.6s">
                                            <p class="text-justify mb-0">Destinar un monto de tus ingresos al ahorro.</p>
                                        </li>
                                        <li class="text-justify wow fadeInRight" data-wow-delay="1.8s">
                                            <p class="mb-1">Mientras más ahorres, generas mayor interés y mejoras tu situación financiera. Debes determinar tus objetivos y de acuerdo a los mismos, escoger el tipo de producto adecuado: caja de ahorro, DPF o cuenta corriente.</p>
                                            <!-- <p class="mt-2 mb-0">Debes determinar tus objetivos y de acuerdo a los mismos, escoger el tipo de producto adecuado: caja de ahorro, DPF o cuenta corriente.</p> -->
                                        </li>
                                        <li class="wow fadeInRight" data-wow-delay="2s">
                                            <p class="text-justify">Finalmente, apersónate por el banco para averiguar más sobre los distintos productos, sus características y sus términos y condiciones. Esto te ayudará en la elección del producto adecuado para tí.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter2"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 85%;" aria-valuenow="85"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 7 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol>
                        <li class="light-text">
                            <h2 class="color-2">Ahorro</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Presupuesto</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Beneficios</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Recomendaciones</h2>
                        </li>
                        <li>
                            <h2 class="color-2">Productos</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">Productos destinados a la
                                administración de efectivo</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1s">Existen distintos productos que una entidad financiera puede ofrecerte, tu elección dependerá de los objetivos que persigas. Puedes abrir y/o constituir:</p>
                            <div class="content-select-card">
                                <div class="row align-items-end justify-content-center">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1s">
                                            <a data-toggle="modal" data-target="#slide4modal" (click)="dismissAlert()">
                                                <img class="mb-4" src="assets/images/module-2/6.svg" width="60%" alt="">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>CAJA DE AHORRO</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.2s" >
                                            <a data-toggle="modal" data-target="#slide5modal" (click)="dismissAlert2()">
                                                <img class="mb-4" src="assets/images/module-2/7.svg" width="50%" alt="">
                                            </a>
                                            <ul class="module_list type_2 ">
                                                <li>
                                                    <h5>DEPÓSITO A PLAZO FIJO</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.4s">
                                            <a data-toggle="modal" data-target="#slide6modal" (click)="dismissAlert3()">
                                                <img class="mb-4" src="assets/images/module-2/8.svg" width="70%" alt="">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>CUENTA CORRIENTE</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissThree">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter2"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 91%;" aria-valuenow="91"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 8 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol>
                        <li class="light-text">
                            <h2 class="color-2">Ahorro</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Presupuesto</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Beneficios</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-2">Recomendaciones</h2>
                        </li>
                        <li>
                            <h2 class="color-2">Productos</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-12 img-size-resp">
                            <img class="wow slideInLeft" data-wow-delay=".5s" src="assets/images/module-2/10.svg">
                        </div>
                        <div class="col-xl-8 col-lg-7 col-md-7 col-sm-12">
                            <h2 class="color-2 pb-4 wow bounceInLeft">¿Cuándo utilizar cada producto?</h2>
                            <ul class="module_list">
                                <li class="wow fadeIn text-justify" data-wow-delay="1s">Si requieres utilizar el dinero en cualquier momento, lo mejor es depositarlo en una cuenta de ahorro.</li>
                                <li class="wow fadeIn text-justify" data-wow-delay="1.2s">
                                    Si necesitas ahorrar para el futuro y puedes inmovilizar tu efectivo, una buena opción es depositar tu dinero en una cuenta de Depósito a Plazo Fijo. De esta manera, no tendrás la tentación de gastarlo y podrás obtener una mejor tasa de interés.
                                    <div class="py-1"></div>
                                    Si prefieres ahorrar poco a poco, puedes abrir una cuenta de ahorro programado, en la cual tu escoges el plazo y las fechas en las que quieres realizar tus depósitos.
                                </li>
                                <li class="wow fadeIn text-justify" data-wow-delay="1.4s">Si tienes un negocio, una cuenta corriente te ayuda a disponer de dinero a través de cheques, banca por internet y tarjetas de débito, para honrar tus obligaciones y evitando el movimiento en efectivo.</li>
                            </ul>
                        </div>

                        <div class="col-xl-10 col-lg-10 col-12 mt-4">
                            <h3 class="color-2 wow bounceInLeft" data-wow-delay="2s">Las opciones que te ofrece <span class="bank-name color-2">Banco <small class="versalitas">PYME</small> Ecofuturo</span>, son las siguientes</h3>
                            <ol class="horizontal_list">
                                <li class="sub_list wow slideInLeft" data-wow-delay="2.2s">
                                    <div class="item-list color-3">
                                        <span>1</span>
                                        <span>Caja de Ahorro</span>
                                    </div>
                                    <ul class="sublist">
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi/5" target="_blank">Eco Aguinaldo</a></li>
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-contenido/12" target="_blank">Eco Pasanaku</a></li>
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-contenido/13" target="_blank">Economista</a></li>
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi/10" target="_blank">Eco Maestro</a></li>
                                    </ul>
                                </li>
                                <li class="sub_list color-1  wow slideInLeft" data-wow-delay="2.4s">
                                    <div class="item-list color-1">
                                        <span>2</span>
                                        <span>Depósito a Plazo Fijo</span>
                                    </div>
                                    <ul class="sublist">
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-contenido/9" target="_blank">DPF</a></li>
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-contenido/2134" target="_blank">Super DPF</a></li>
                                    </ul>
                                </li>
                                <li class="sub_list  color-2 wow slideInLeft" data-wow-delay="2.6s">
                                    <div class="item-list">
                                        <span>3</span>
                                        <span>Cuenta Corriente</span>
                                    </div>
                                    <ul class="sublist">
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-negocio-contenido/24" target="_blank">Rentabiliza</a></li>
                                        <li><a href="https://www.bancoecofuturo.com.bo/sitio/ahorro-para-mi-negocio-contenido/23" target="_blank">Cuenta Corriente</a></li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4" style="z-index: 2;">
                        <a class="btn bg-2 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="3s"
                            (click)="activeWow()">ANTERIOR</a>
                        <a *ngIf="this.finalModule == false" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="3s"
                            data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                        <a *ngIf="this.finalModule == true" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="3s" (click)="goToHome()">IR AL INICIO</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2" role="progressbar" style="width: 100%;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100">
                                <!-- <img class="icon_progress" src="assets/images/module-2/progress-icon.svg"> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/1.svg" width="50%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Gastar:</h4>
                        <p class="text-justify">Un gasto es un egreso o salida de dinero que una persona o empresa debe pagar para un fin, ya sea adquirir un producto o recibir un servicio.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/2.svg" width="45%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Ahorrar:</h4>
                        <p class="text-justify">Es la acción de separar una parte del ingreso, que obtiene una persona o empresa, con el fin
                            de guárdalo para necesidades futuras.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/3.svg" width="50%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Invertir:</h4>
                        <p class="text-justify">Emplear una cantidad de dinero en un proyecto, actividad o negocio con el objetivo de obtener
                            un beneficio, ganancia adicional o ingreso.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/6.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CAJA DE AHORRO:</h4>
                        <p>Es un tipo de cuenta bancaria donde el cliente deposita su dinero para que el banco lo guarde y le pague intereses periódicamente por la suma depositada.</p>
                        <p>El acceso a los fondos es inmediato por lo que se conoce como cuenta vista. Cuando el cliente desea realizar movimientos de dinero (depósitos o retiros) muy altos, se solicita el llenado de un formulario en el marco de lo dispuesto por la Unidad de Investigación Financiera (UIF).</p>
                        <p>Al abrir una caja de ahorro, el cliente puede recibir una tarjeta de débito y/o su usuario y claves de acceso a la banca digital para administrar y disponer de su dinero los los 7 días de la semana y las 24 horas del día.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/7.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">DEPÓSITO A PLAZO FIJO:</h4>
                        <p>Es un producto financiero que consiste en la entrega de una cantidad de dinero a una entidad bancaria, por un lapso previamente definido, a cambio de un interés cuya tasa y periodicidad de pago es pactada inicialmente.</p>
                        <p>A diferencia, de las cuentas de ahorro, si la persona necesita retirar su dinero antes del plazo acordado, tendrá una penalidad, que generalmente consiste en la pérdida total o parcial de los intereses ganados. Es un instrumento de inversión.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/8.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CUENTA CORRIENTE:</h4>
                        <p>Es un contrato bancario donde el titular deposita su dinero y puede retirarlo el momento que deseé, ya sea parcial o totalmente, característica por la cual también es una cuenta vista. Se diferencia, principalmente, porque su manejo contempla la emisión de cheques para el retiro de fondos. Sin embargo, este último también puede ejecutarse a través de la ventanilla en agencias, cajeros automáticos y/o canales digitales.</p>
                        <p>Este tipo de cuenta se utiliza generalmente para pagar a clientes, proveedores, personal, etc.</p>
                        <p>Finalmente, la cuenta corriente generalmente no paga ningún tipo de interés al usuario por tratarse de un producto transaccional.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/module-2/module-completed.svg">
                            <h2 class="color-2 mt-2">COMPLETASTE EL MÓDULO 2</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module-2/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-2">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/intermedio/modulo-3" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-2">módulo que termines,</strong>
                                    obtienes un cupón más, es decir, <strong class="color-2">una oportunidad más de
                                        ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
