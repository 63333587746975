import { environment } from '../../environments/environment';

const base_url = environment.base_url;

export class Client {
    constructor(
        public nombre?: string,  // nameComplete
        public email?: string,
        public password?: string,
        public nameComplete?: string,  // nameComplete
        public role?: string,
        public ci?: string,
        public birthdate?: Date,
        public age?: 0,
        public department?: string,
        public city?: string,
        public address?: string,
        public cellPhone?: string,
        public phone?: string,
        public educationLevel?: string,
        public disability?: true,
        public referenceName?: string,
        public referencePhone?: string,
        public userAplication?: string,
        public passwordAplication?: string,
        public publicToken?: string,
        public appUserId?: string,    
        public completeRegister? : boolean,
        public currentModule? : number,
        public gender? : string,
        public ciExpedition? : string,
        public finishedModules? : [],
        public typeDisability? : string,

        // modulo actual,
        // quiz a
        // quiz a cupon
        // .. 
    ) {}
}