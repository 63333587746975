import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Client} from '../../models/client.model';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  @Input() nombreCompleto: string;

  public client = new Client('', '');
  
  constructor(     
    private router: Router,
    private clientService: ClientService ) { }

  ngOnInit(): void {
  }

  descargarCertificado() {
    this.clientService.descargarCertificado( this.nombreCompleto ).subscribe(
      res => {   
        const linkSource = `${res['data']['pdfCertificate']}`;
        const downloadLink = document.createElement("a");
        const fileName = `CERTIFICADO-${this.nombreCompleto}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();         
      },
      err => {
        alert( err.error.message );
      }
    );
  }
  
  redirectHome() {
    this.router.navigate(['/inicio']);
  }
}
