import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { Client} from '../../models/client.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public users = [];
  public usersDepto = {
    'lpz'     : 0,
    'cbba'    : 0,
    'stacruz' : 0,
    'oru'     : 0,
    'psi'     : 0,
    'chq'     : 0,
    'pnd'     : 0,
    'bni'     : 0,
    'tja'     : 0,
  };

  constructor( private router: Router, private clientService: ClientService ) {}

  ngOnInit(): void {
    this.clientService.obtenerUsuarios().subscribe(
      data => {
        this.users = data['data']['winners'];
        if( this.users.length > 0 ) {
          this.usersDepto = this.contarUsuariosPorDepartamento( this.users );
        }        
      },
      err => {
        this.router.navigateByUrl('/inicio');
        // this.contentUser = JSON.parse(err.error).message;
      }
    );
  }

  contarUsuariosPorDepartamento( users ) {
    var numCbba    = 0;
    var numStaCruz = 0;
    var numLPz     = 0;
    var numOru     = 0;
    var numPsi     = 0;
    var numChq     = 0;
    var numPnd     = 0;
    var numBni     = 0;
    var numTja     = 0;
    users.forEach(element => {
      switch( element.department ) {
        case 'La Paz' :
          numLPz++;
          break;
        case 'Cochabamba' :
          numCbba++;
          break;
        case 'Santa Cruz' :
          numStaCruz++;
            break;
        case 'Oruro' :
          numOru++;
        break;
        case 'Potosí' :
          numPsi++;
        break;
        case 'Chuquisaca' :
          numChq++;
        break;
        case 'Pando' :
          numPnd++;
        break;
        case 'Beni' :
          numBni++;
        break;
        case 'Tarija' :
          numTja++;
        break;
      }
    });
    return {
      'lpz'     : numLPz,
      'cbba'    : numCbba,
      'stacruz' : numStaCruz,
      'oru'     : numOru,
      'psi'     : numPsi,
      'chq'     : numChq,
      'pnd'     : numPnd,
      'bni'     : numBni,
      'tja'     : numTja,
    };
  }

}
