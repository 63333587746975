<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Arma tu presupuesto</h2>
                  </li>
                  <li>
                      <h2 class="color-2">Profundiza en el Presupuesto</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center text-center align-items-start">
                      <div class="col-12">
                        <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay = ".5s">Profundiza en el Presupuesto</h2>
                        <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = "1s">El presupuesto viene a ser definido como el detalle de ingresos y gastos de una persona, familia o negocio que permite tener un mejor y más preciso control de sus finanzas, facilitando así el ahorro para las metas que se tengan. Se toma en cuenta diferentes elementos para construirlo adecuadamente. </p>
                      </div>
                      <div class="col-12">
                        <h3 class="color-2 pb-4 wow fadeIn text-left" data-wow-delay = "1.5s">¿Por qué es importante elaborar un presupuesto?</h3>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="wow bounceInDown d-flex gap-2 justify-content-center text-left mb-4" data-wow-delay = "1.5s">
                            <div class="img-container">
                              <img class="max-w-m" src="assets/images/initial/chapter2/1.png">
                            </div>
                            <p class="mb-0">Nos permite plasmar y alcanzar nuestros objetivos en un periodo de tiempo, sean profesionales, personales, empresariales, etc.</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="wow bounceInDown d-flex gap-2 justify-content-center text-left mb-4" data-wow-delay = "1.5s">
                            <div class="img-container">
                              <img class="max-w-m" src="assets/images/initial/chapter2/2.png">
                            </div>
                            <p class="mb-0">Evitamos los gastos hormiga o gastos innecesarios, ya que delimitamos las cantidades de dinero a utilizar en el mes.</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="wow bounceInDown d-flex gap-2 justify-content-center text-left" data-wow-delay = "1.5s">
                            <div class="img-container">
                              <img class="max-w-m" src="assets/images/initial/chapter2/3.png">
                            </div>
                            <p class="mb-0">Brinda organización a nuestro panorama financiero actual, facilitando y fomentando la correcta toma de decisiones sobre nuestro dinero.</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="wow bounceInDown d-flex gap-2 justify-content-center text-left" data-wow-delay = "1.5s">
                            <div class="img-container">
                              <img class="max-w-m" src="assets/images/initial/chapter2/4.png">
                            </div>
                            <p class="mb-0">Permite conocer a detalle el destino final de nuestro dinero, para mejorar la gestión de nuestros recursos.</p>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="col-lg-4">
                        <a class="btn bg-2 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s"
                            (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 3%;" aria-valuenow="3"
                                aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite" >
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Arma tu presupuesto</h2>
                  </li>
                  <li>
                      <h2 class="color-2">¿Cómo armar un presupuesto adecuado?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-xl-11 col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay="1s">¿Cómo armar un presupuesto adecuado?</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1.5s">El presupuesto viene a ser definido como el detalle de ingresos y gastos de una persona, familia o negocio que permite tener un mejor y más preciso control de sus finanzas, facilitando así el ahorro para las metas que se tengan. Se toma en cuenta diferentes elementos para construirlo adecuadamente.</p>
                            <div class="row mb-4">
                              <div class="col-4 px-4">
                                  <div class="wow bounceInDown justify-content-center text-justify" data-wow-delay = "1.5s">
                                    <div class="rounded_decoration">
                                      <div class="label">Ingresos</div>
                                      <img class="max-w-l" src="assets/images/initial/chapter2/5.png">
                                    </div>
                                      <p class="mb-0">Los ingresos refieren a toda cantidad de dinero que se recibe de manera periódica constante o no recurrente. </p>
                                  </div>
                              </div>
                              <div class="col-4 px-4">
                                <div class="wow bounceInDown justify-content-center text-left" data-wow-delay = "1.5s">
                                  <div class="rounded_decoration">
                                    <div class="label">Gastos</div>
                                    <img class="max-w-l" src="assets/images/initial/chapter2/6.png">
                                  </div>
                                  <p class="mb-0">Refiere a toda salida de dinero que se realiza para adquirir un bien o servicio. Es importante mantener control y detalle de los gastos que se hacen para evitar los gastos hormiga.</p>
                                </div>
                              </div>
                              <div class="col-4 px-4">
                                <div class="wow bounceInDown justify-content-center text-left" data-wow-delay = "1.5s">
                                  <div class="rounded_decoration">
                                    <div class="label">Sobrante</div>
                                    <img class="max-w-l" src="assets/images/initial/chapter2/7.png">
                                  </div>
                                  <p class="mb-0">El sobrante monetario tras calcular el presupuesto es dinero que puede ser destinado al ahorro luego de cubrir las necesidades y obligaciones.</p>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-auto">
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="activeDemo()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 33.3%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:33.8%" src="assets/images/module-2/progress-icon.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Arma tu presupuesto</h2>
                  </li>
                  <li>
                      <h2 class="color-2">¿Cómo armar un presupuesto?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-2 wow bounceIn" data-wow-delay="1s">¿Cómo armar un presupuesto?</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1.5s">Con el siguiente ejemplo, te mostramos los tres pasos iniciales para elaborar tu presupuesto:</p>

                            <img class="max-width-100 wow bounceInUp" data-wow-delay="2s" src="assets/images/initial/chapter2/budget-table.jpg" alt="budget table">
                        </div>
                    </div>
                </div>

                <div class="col-lg-auto">
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                    <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="activeWow()">SIGUIENTE</a>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 66.6%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:67.1%" src="assets/images/module-2/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Arma tu presupuesto</h2>
                  </li>
                  <li>
                      <h2 class="color-2">Arma tu presupuesto</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center screen-1 text-center">
                        <div class="col-xl-12 col-lg-12 col-md-10">
                            <!-- <h1 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">INTERACTIVO: UNA ANIMACIÓN</h1> -->
                            <h2 class="color-2 pb-2 wow bounceIn" data-wow-delay=".5s">Arma tu presupuesto</h2>

                            <p class="text-justify wow bounceInUp mb-2" data-wow-delay="1s">Ahora es tu turno de elaborar tu propio presupuesto, te brindamos algunos consejos para que el desarrollo de tu presupuesto personal sea cada vez más preciso.</p>
                        </div>
                        <div class="col-6">
                          <img class="max-width-100 wow bounceInUp" data-wow-delay="1.5s" src="assets/images/initial/chapter2/table.jpg" alt="table">
                        </div>
                        <div class="col-6">
                          <p class="text-justify wow bounceInUp mb-2" data-wow-delay="1s">Como revisamos en la diapositiva anterior, anota los ingresos netos percibidos por mes, así como los egresos para conocer hacia dónde se va nuestro dinero.</p>
                          <p class="text-justify wow bounceInUp mb-2" data-wow-delay="1.2s">Es importante establecer metas u objetivos claros y realistas, tanto a corto, mediano o largo plazo, para construir un presupuesto que permita el cumplimiento de ese objetivo.</p>
                          <p class="text-justify wow bounceInUp mb-2" data-wow-delay="1.4s">Al elaborar el presupuesto, considera los límites de gasto específicos y busca reducir o cortar los gastos hormiga, que suelen terminar sumando una gran cantidad de dinero gastada.</p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-4" style="z-index: 2;">
                      <a class="btn bg-2 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s"
                          (click)="activeWow()">ANTERIOR</a>
                      <a *ngIf="this.finalModule == false" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
                          data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                      <a *ngIf="this.finalModule == true" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
                  </div>
              </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 100%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-2/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-2/pork-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/1.svg" width="50%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Gastar:</h4>
                        <p class="text-justify">Un gasto es un egreso o salida de dinero que una persona o empresa debe pagar para un fin, ya sea adquirir un producto o recibir un servicio.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/2.svg" width="45%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Ahorrar:</h4>
                        <p class="text-justify">Es la acción de separar una parte del ingreso, que obtiene una persona o empresa, con el fin
                            de guárdalo para necesidades futuras.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/3.svg" width="50%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Invertir:</h4>
                        <p class="text-justify">Emplear una cantidad de dinero en un proyecto, actividad o negocio con el objetivo de obtener
                            un beneficio, ganancia adicional o ingreso.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/6.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CAJA DE AHORRO:</h4>
                        <p>Es un tipo de cuenta bancaria donde el cliente deposita su dinero para que el banco lo guarde y le pague intereses periódicamente por la suma depositada.</p>
                        <p>El acceso a los fondos es inmediato por lo que se conoce como cuenta vista. Cuando el cliente desea realizar movimientos de dinero (depósitos o retiros) muy altos, se solicita el llenado de un formulario en el marco de lo dispuesto por la Unidad de Investigación Financiera (UIF).</p>
                        <p>Al abrir una caja de ahorro, el cliente puede recibir una tarjeta de débito y/o su usuario y claves de acceso a la banca digital para administrar y disponer de su dinero los los 7 días de la semana y las 24 horas del día.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/7.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">DEPÓSITO A PLAZO FIJO:</h4>
                        <p>Es un producto financiero que consiste en la entrega de una cantidad de dinero a una entidad bancaria, por un lapso previamente definido, a cambio de un interés cuya tasa y periodicidad de pago es pactada inicialmente.</p>
                        <p>A diferencia, de las cuentas de ahorro, si la persona necesita retirar su dinero antes del plazo acordado, tendrá una penalidad, que generalmente consiste en la pérdida total o parcial de los intereses ganados. Es un instrumento de inversión.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/8.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CUENTA CORRIENTE:</h4>
                        <p>Es un contrato bancario donde el titular deposita su dinero y puede retirarlo el momento que deseé, ya sea parcial o totalmente, característica por la cual también es una cuenta vista. Se diferencia, principalmente, porque su manejo contempla la emisión de cheques para el retiro de fondos. Sin embargo, este último también puede ejecutarse a través de la ventanilla en agencias, cajeros automáticos y/o canales digitales.</p>
                        <p>Este tipo de cuenta se utiliza generalmente para pagar a clientes, proveedores, personal, etc.</p>
                        <p>Finalmente, la cuenta corriente generalmente no paga ningún tipo de interés al usuario por tratarse de un producto transaccional.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/icons/advanced/1.png">
                            <h2 class="color-2 mt-2">COMPLETASTE EL MÓDULO 2</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                          <img class="img-2" src="assets/images/module-2/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-2">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/inicial/modulo-3" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-2">módulo que termines,</strong>
                                    obtienes un cupón más, es decir, <strong class="color-2">una oportunidad más de
                                        ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
