<section class="home-page nopagehome">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <img class="logo-click wow pulse" src="assets/images/logo-click-light.svg">
                <h5 class="main-title wow slideInDown color-1 mb-4">PÁGINA NO ENCONTRADA</h5>
                <h3 class="main-title wow slideInDown color-1"><strong>Error 404</strong></h3>
                <a routerLink="" class="btn main_btn bg-1 text-white wow bounceIn" data-wow-delay = "2.5s"><STRONG>VOLVER AL INICIO</STRONG></a>
            </div>
            <div class="col-12 text-center">
                <img src="assets/images/module/1.svg" width="75%">
            </div>
        </div>
    </div>
</section>