import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../services/client.service';
import { Client} from '../../models/client.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public token = '';
  public formSubmitted = false;
  public changePasswordForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  },
    {
      validators: this.passwordsIguales('password', 'confirmPassword')
  });
  public textLoading = 'Cargando...';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      var urlTokenPosition = (window.location.href).indexOf("token=") + 6;
      this.token = (window.location.href).substring(urlTokenPosition).toString();
      if( this.token == '' ) {
        this.router.navigateByUrl('/inicio');
      }
    }, 2000);
  }

  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup)=> {
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null)
      } else {
        pass2Control.setErrors({ noEsigual: true })
      }
    }
  }

  contrasenasInvalidas() {
    const pass1 = this.changePasswordForm.get('password').value;
    const pass2 = this.changePasswordForm.get('confirmPassword').value;
    if ((pass1 != pass2) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }
  }  

  cambiarPassword( data ) {
    this.formSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.textLoading = 'Cambiando contraseña...';
    this.spinner.show();
    this.clientService.cambiarPassword( data, this.token )
      .subscribe( resp => {
        setTimeout(() => {
          this.spinner.hide();
          alert( 'Contraseña cambiada con éxito' );
          this.router.navigateByUrl('/inicio');
        }, 2000);
        // location.reload(); 
      }, (err) => {
        setTimeout(() => {
          this.spinner.hide();
          alert( err.error['message'] );
        }, 1000);
    });
  }
}
