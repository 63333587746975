<div class="row justify-content-center">
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">La Paz</h4>
                <p class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['lpz'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Cochabamba</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['cbba'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Santa Cruz</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['stacruz'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Oruro</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['oru'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Potosí</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['psi'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Chuquisaca</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['chq'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Pando</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['pnd'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Beni</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['bni'] }}</span></p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 m-2">
        <div class="row box">
            <div class="col-2">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="col">
                <h4 class="color-3">Tarija</h4>
                <p  class="mb-0">Usuarios registrados <span class="badge badge-success">{{ this.usersDepto['tja'] }}</span></p>
            </div>
        </div>
    </div>
</div>
