import { Component, OnInit } from '@angular/core';
import { ClientService } from '../services/client.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    // this.revisarUsuario();
  }

  

}
