import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { NgwWowService } from 'ngx-wow';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chapter3intermediate',
  templateUrl: './chapter3intermediate.component.html',
  styleUrls: ['./chapter3intermediate.component.scss']
})
export class Chapter3IntermediateComponent implements OnInit {
  public finalModule;
  config: SwiperOptions = {
    mousewheel: false,
    cssMode: false,
    allowTouchMove: false,
    autoHeight: true,
    slidesPerView: 1,
    initialSlide: ( typeof history.state[0] != 'undefined' ) ? 8 : 0,
    effect: 'coverflow',
    navigation: {
      nextEl: '.swiper_next',
      prevEl: '.swiper_prev'
    },
  };

  constructor(
    private wowService: NgwWowService,
    private router: Router
  ) {
    this.activeWow();
    // this.wowService.init();
  }

  getBrowserInfo() {
    var ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
  }

  dismissOne=  true;
  dismissTwo=  false;
  dismissThree=  false;
  dismissFour=  false;
  dismissFive=  false;

  ngOnInit(): void {
    window.scroll(0,0);
    if( localStorage.getItem('email') == null ) {
      this.router.navigateByUrl('/inicio');
    }
    this.finalModule = localStorage.getItem('finish-3') == 'final' ? true : false;
    if ( this.finalModule == false ) {
      localStorage.setItem('module', '3' );
    }
  }
  dismissAlert(){
    this.dismissOne = false;
    this.dismissTwo = true;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
  }
  dismissAlert2(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = true;
    this.dismissFour = false;
    this.dismissFive = false;
  }
  dismissAlert3(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = true;
    this.dismissFive = false;
  }
  dismissAlert4(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = true;
  }
  dismissAlert5(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
  }
  activeWow() {
    var browserInfo = this.getBrowserInfo();
    if( browserInfo != "Chrome 4" && browserInfo != "Safari 15" && browserInfo != "Safari 14" && browserInfo != "Safari 13" && browserInfo != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
  }
  goToHome() {
    this.router.navigateByUrl('/inicio');
  }
}
