// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const clientId = 'APP_USER_ANGULAR';
const clientSecret = '%vBoyx@EZArC';

export const environment = {
  userAplication: clientId,
  passwordAplication : clientSecret,
  publicToken : 'DF9516E6-2C09-4E62-A98F-2E36DE154C52',
  appUserId : 'AppUseIdAngular20201031',
  authorizationBasic: window.btoa(clientId + ':' + clientSecret),
  production: false,
  apiUrl: "api/v1",
  base_url: "https://192.168.204.168:40855/WebApi_Financial_Education_Ecofuturo"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
