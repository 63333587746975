<!-- CONGRATULATIONS QUIZ-->
<div id="modal-quiz-5" class="modal fade certificate-completed popup_form" data-toggle="modal" data-target=".certificate-completed"  tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content bg-module-congratulations">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button (click)="redirectHome()" type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-modal-finish">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid center">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-8 col-sm-8 col-12 text-center">
                            <h2 class="color-1 mt-2" style="margin-top: -5% !important;">COMPLETASTE EL QUIZ CON ÉXITO</h2>
                            <p>El cupón para participar del sorteo ha sido enviado automáticamente a tu correo. ¡Verifícalo!</p>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <!-- <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/icons/certificate.svg">
                        </div> -->
                        <div class="col-lg-12 col-md-7 col-sm-7 col-12 text-center">
                            <!-- <p>Puedes descargar tu certificado de participación desde el siguiente link. ¡Muchas gracias por tu empeño!</p> -->
                            <p>Revisa tu correo para obtener tu cupón de participación.</p>
                        </div>
                    </div>
                </div>                
                <div class="container-fluid center">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-8 col-sm-8 col-12 text-center">
                            <img src="assets/images/icons/congratulations.svg" style="width: 50% !important; margin-top: -10% !important;">
                            <h2 class="color-1 mt-2" style="margin-top: -5% !important;">COMPLETASTE TODOS LOS MÓDULOS</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-7 col-sm-7 col-12 text-center">
                            <!-- <p>Puedes descargar tu certificado de participación desde el siguiente link. ¡Muchas gracias por tu empeño!</p> -->
                            <!-- <a (click)="descargarCertificado()" class="btn bg-3 text-white py-2 px-4 ml-2" data-wow-delay="2s"><strong>DESCARGAR CERTIFICADO</strong></a> -->
                            <h4 class="mt-3 color-2"><small>Vuelve a la página de Inicio y descarga tu Certificado</small></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
