<div class="page-wrapper chiller-theme toggled">
	<a id="show-sidebar" class="btn btn-sm btn-dark">
		<i class="fa fa-bars" aria-hidden="true"></i>
	</a>

	<nav id="sidebar" class="sidebar-wrapper">
		<div class="sidebar-content">
			<div class="sidebar-brand">
				<a class="color-3" href="#">ECOFUTURO</a>
				<div id="close-sidebar">
					<i class="fa fa-times"></i>
				</div>
			</div>
			<div class="sidebar-header">
				<div class="user-pic mb-4">
					<img class="img-responsive img-rounded"
						src="assets/images/icons/user-icon.svg"
						alt="User picture">
				</div>
				<div class="user-info text-center">
					<span class="user-name">
						<strong>{{ this.client.email }}</strong>
					</span>
					<span class="user-role">{{ this.client.role }}</span>
					<span class="user-status">
						<i class="fa fa-circle"></i>
						<span>Conectado</span>
					</span>
				</div>
			</div>
			<!-- sidebar-header  -->
			<!-- <div class="sidebar-search">
				<div>
					<div class="input-group">
						<input type="text" class="form-control search-menu" placeholder="Buscar...">
						<div class="input-group-append">
							<span class="input-group-text">
								<i class="fa fa-search" aria-hidden="true"></i>
							</span>
						</div>
					</div>
				</div>
			</div> -->
			<!-- sidebar-search  -->
			<div class="sidebar-menu">
				<ul>
					<li class="header-menu">
						<span>General</span>
					</li>

					<li class="sidebar-dropdown">
						<a routerLink="dashboard">
							<i class="fa fa-home" aria-hidden="true"></i>
							<span>Inicio</span>
						</a>
					</li>

					<li class="sidebar-dropdown">
						<a routerLink="usuarios">
							<i class="fa fa-users" aria-hidden="true"></i>
							<span>Usuarios registrados</span>
						</a>
					</li>

					<li class="sidebar-dropdown">
						<a (click)=logout()>
							<i class="fa fa-sign-out" aria-hidden="true"></i>
							<span>Cerrar sesión</span>
						</a>
					</li>

				</ul>
			</div>
			<!-- sidebar-menu  -->
		</div>
		<!-- sidebar-content  -->
		<div class="sidebar-footer">
			<img class="w-50 py-3 m-auto" src="assets/images/ecofuturo-logo.svg">
		</div>
	</nav>
	<!-- sidebar-wrapper  -->
	<main class="page-content">
		<div class="container-fluid">
			<h2 class="color-3">Administrador Ecofuturo</h2>
			<hr>
			<div class="row">
				<div class="form-group col-md-12">
					<p>Información de usuarios registrados en la plataforma</p>
				</div>

				<!-- <div class="form-group col-md-12">
					<div class="alert alert-success" role="alert">
						<p class="mb-0">Lorem ipsum dolor sit amet consectetur.</p>
					</div>
				</div> -->
			</div>

			<router-outlet></router-outlet>

		</div>
	</main>
	<!-- page-content" -->
</div>
