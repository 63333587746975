import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client} from '../../models/client.model';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
declare var $:any;

declare function dataPicker();

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public phone = '';
  public isLogged = true;
  public formSubmitted = false;
  public client = new Client( '', '', '');
  public isAuth: boolean = false;
  public contentUser = {};
  public registrationCompleted = false;
  public show = false;
  public registerCompleteCount = 0;
  public textInitSession = 'Cargando...';

  public registerForm = this.fb.group({
    // nombre: '',
    nameComplete: ['', [Validators.required, Validators.minLength(5)]],
    gender: ['', Validators.required],
    birthdate: ['', [Validators.required]],
    // age: '',
    department: ['', Validators.required],
    city: ['', [Validators.required, Validators.minLength(3)]],
    address: ['', Validators.required],
    cellPhone: ['', [Validators.required]],
    // phone: '',
    educationLevel: ['', Validators.required],
    // email: ['', Validators.required],
    disability_condition: ['', Validators.required],
  },
    {}
  );
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    dataPicker();
    this.clientService.obtenerUsuario().subscribe(
      data => {
        if( data == false ) {
          this.router.navigateByUrl('/inicio');
          return;
        }
        this.contentUser = data['data'];
        this.client.completeRegister = this.contentUser['completeRegister'];
        this.client.email = localStorage.getItem('email');
        if( this.client.completeRegister ) {
          this.router.navigateByUrl('/inicio');
        }
      },
      err => {
        alert( err.error.message );
        this.router.navigateByUrl('/inicio');
        // this.contentUser = JSON.parse(err.error).message;
      }
    );

  }

  getCliente() {
    var data = {};
    if( this.isLogged == true ) {
      data = {
        'status' : 'ok',
        'data' : {
          'nombre' : 'Josue Gutierrez',
          'email'  : 'josue@gmail.com',
          'role'   : 'user'
        }
      };
    }
    return data;
  }

  simulateClick (btnQuiz) {
    var evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    var canceled = !btnQuiz.dispatchEvent(evt);
  };      

  completarRegistroUsuario() {
    var disabilityString = '';
    this.formSubmitted = true;
    if( this.registerForm.value['disability_condition'] == 'si' ) {
      var disabilityElements = document.querySelectorAll('input[name="disability"]:checked');
      var disabilityOptions = Array.prototype.map.call(disabilityElements, function (e) {return e.value;});
      if( disabilityOptions.length == 0 ) {
        this.registerCompleteCount = 0;
        alert( 'Debe seleccionar una discapacidad' );
        return;
      }
      disabilityString = disabilityOptions.join(', ');
    }    
    if( isNaN(parseInt( document.querySelector('#text-birthday').textContent ) ) ) {
      this.registerCompleteCount = 0;
      alert( 'La edad deber ser un número válido.');
      return; 
    }
    if (this.registerForm.invalid) {
      this.registerCompleteCount = 0;
      return;
    }
    if( (this.registerForm.value['cellPhone']).toString().length < 8 ) {
      this.registerCompleteCount = 0;
      alert( 'El número de celular debe tener 8 dígitos.')
      return;
    }
    var phone = (<HTMLInputElement>document.getElementById('phone')).value || 'NO';
    if( phone != 'NO' && ( phone ).toString().length < 7 ) {
      this.registerCompleteCount = 0;
      alert( 'El número de teléfono fijo debe tener 7 dígitos.')
      return;      
    }
    this.registerForm.value['email'] = localStorage.getItem('email');
    this.registerForm.value['phone'] = phone;
    this.registerForm.value['age'] = parseInt( document.querySelector('#text-birthday').textContent );
    // this.registerForm.value['nombre'] = this.registerForm.value.nameComplete;    
    this.registerForm.value['referenceName'] = 'No';
    this.registerForm.value['referencePhone'] = 'No';
    let btnQuiz = document.querySelector('#register-success');
    let btnClose = document.querySelector('#close-modal');

    if( this.registrationCompleted ) {
      this.simulateClick(btnQuiz); 
      return;
    }
    if( this.registerCompleteCount > 0 ) {
      return;
    }
    this.registerCompleteCount = this.registerCompleteCount+ 1;
    let btnSubmitForm = document.querySelector('#btn__submit_register_complete');
    btnSubmitForm.setAttribute('disabled', '');
    this.spinner.show();
    this.clientService.completarRegistroUsuario(this.registerForm.value, disabilityString)
    .subscribe( resp => {
      this.registrationCompleted = true;
      this.spinner.hide();
      this.simulateClick(btnQuiz);
      setTimeout(() => {
        this.simulateClick(btnClose);
        this.router.navigateByUrl('/inicio');
      }, 3000);
    }, (err)=> {
      alert('Revise que llenó todos los datos obligatorios corréctamente.');
      return;
    });
  }

  calcularEdad() {
    var birthDate = new Date( ((document.getElementById("birth-date") as HTMLInputElement).value) );
    if( birthDate ) {
      var now = new Date();
      var edad = now.getFullYear() - birthDate.getFullYear();
      var evalDate = now.getMonth() - birthDate.getMonth();
      if (evalDate < 0 || (evalDate === 0 && now.getDate() < birthDate.getDate())) {
          edad--;
      } 
    }
    var textBirthDay = document.getElementById("text-birthday");
    if( edad >= 0 && edad <=122 ) {
      textBirthDay.textContent = ( edad ).toString();
    } else {
      textBirthDay.textContent = 'Fecha de nacimiento inválida';
    }
  }
  
  campoNoValido(campo: string): boolean {
    if (this.registerForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  validarDosPalabras(): boolean {
    if ( ( ( this.registerForm.value['address'] ).trim().split(' ').length < 2 ) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }
  }  

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }  

  keyPressAlphabetic(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }  
}
