<div class="puzzle-page">
    <div id="container">	
        <a class="btn bg-3 text-white py-2 px-4 button start"><strong>Comenzar juego</strong></a>
        <div class="box">	
            <div class="me full"></div>
        </div>
        <!-- <div class="pre_img">	
            <li data-bid="0"><img src="assets/images/module-3/puzzle.jpg"></li>
            <li data-bid="1"><img src="https://preview.ibb.co/kWOEt6/minion.png"></li>
            <li data-bid="2"><img src="https://preview.ibb.co/e0Rv0m/ab.jpg"></li>	
            <li data-bid="3" id="upfile1"><img src="https://image.ibb.co/cXSomR/upload1.png" /></li>
            <input type="file" name="image" id="file1" style="display: none">
        </div> -->
        <div class="center"><a class="btn bg-1 text-white py-2 px-4 mt-5 reset"><strong>Reiniciar/ Volver a jugar</strong></a></div>
    </div>
    
    <div class="cover">
        <div class="score">
            <p id="scr_head"> ¿Qué es un crédito?</p>
            <p id="scr_time"> Tiempo : <span id="min">00</span> Min <span id="sec">00</span> Sec</p>
            <p id="scr_moves"> Movimientos : <span id="moves"></span></p>
            <div class="btn bg-3 text-white py-2 px-4 OK swiper_next">SIGUIENTE</div>
        </div>
    </div>
</div>

<!-- "src/assets/js/puzzle.js", -->