import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ecofuturo';

  verificarToken() {
    const token = localStorage.getItem('token') || '';
    if( token != '' ) {
      return  true;
        // Petici'on post para obtener datos del perfil del usuario| Enviar token para validar Bear
      // this.client.nombre = 'PEDRO PICAPIEDRA';
      // this.client.email = 'piter@gmail.com';
      // this.client.role = 'User';
    }
    return false;
  }
}


