import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { NgwWowService } from 'ngx-wow';

declare function stopAudio();

@Component({
  selector: 'app-chapter5intermediate',
  templateUrl: './chapter5intermediate.component.html',
  styleUrls: ['./chapter5intermediate.component.scss']
})

export class Chapter5IntermediateComponent implements OnInit {
  public finalModule;
  config: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: false,
    mousewheel: false,
    direction: 'horizontal',
    slidesPerView: 1,
    effect: 'coverflow',
    pagination: { el: '.swiper-pagination', type: 'custom', clickable: false },
    initialSlide: ( typeof history.state[0] != 'undefined' ) ? 5 : 0,
    navigation: {
      nextEl: '.swiper_next',
      prevEl: '.swiper_prev'
    },
    fadeEffect: {
      crossFade: true
    },
    spaceBetween: 0
  };

  constructor(
    private wowService: NgwWowService,
    private router: Router
    ) {
      this.activeWow();
      // this.wowService.init();
      stopAudio();
  }

  getBrowserInfo() {
    var ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
  }

  dismissOne=  true;
  dismissTwo=  false;
  dismissThree=  false;
  dismissFour=  false;
  dismissFive=  false;
  dismissSix=  false;
  dismissSeven=  false;

  bubble1 = true;
  bubble2 = false;
  bubble3 = false;
  bubble4 = false;
  bubble5 = false;
  ngOnInit(): void {
    stopAudio();
    window.scroll(0,0);
    if( localStorage.getItem('email') == null ) {
      this.router.navigateByUrl('/inicio');
    }
    this.finalModule = localStorage.getItem('finish-5') == 'final' ? true : false;
    if ( this.finalModule == false ) {
      localStorage.setItem('module', '5' );
    }
  }
  stopAudio(){};
  dismissAlert(){
    this.dismissOne = false;
    this.dismissTwo = true;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = false;
    stopAudio();
  }
  dismissAlert2(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = true;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = false;
    stopAudio();
  }
  dismissAlert3(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = true;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = false;
    stopAudio();
  }
  dismissAlert4(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = true;
    this.dismissSix = false;
    this.dismissSeven = false;
    stopAudio();
  }
  dismissAlert5(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = true;
    this.dismissSeven = false;
    stopAudio();
  }
  dismissAlert6(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = true;
    stopAudio();
  }
  dismissAlert7(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = false;
    stopAudio();
  }
  activeWow() {
    var browserInfo = this.getBrowserInfo();
    if( browserInfo != "Chrome 4" && browserInfo != "Safari 15" && browserInfo != "Safari 14" && browserInfo != "Safari 13" && browserInfo != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
    this.dismissOne = true;
    this.dismissTwo = false;
    this.dismissThree = false;
    this.dismissFour = false;
    this.dismissFive = false;
    this.dismissSix = false;
    this.dismissSeven = false;
  }

  viewbubble1() {
    this.bubble1 = false;
    this.bubble2 = true;
    this.bubble3 = false;
    this.bubble4 = false;
    this.bubble5 = false;
  }
  viewbubble2() {
    this.bubble1 = false;
    this.bubble2 = false;
    this.bubble3 = true;
    this.bubble4 = false;
    this.bubble5 = false;
  }
  viewbubble3() {
    this.bubble1 = false;
    this.bubble2 = false;
    this.bubble3 = false;
    this.bubble4 = true;
    this.bubble5 = false;
  }
  viewbubble4() {
    this.bubble1 = false;
    this.bubble2 = false;
    this.bubble3 = false;
    this.bubble4 = false;
    this.bubble5 = true;
  }
  viewbubble5() {
    this.bubble1 = false;
    this.bubble2 = false;
    this.bubble3 = false;
    this.bubble4 = false;
    this.bubble5 = false;
  }
  goToHome() {
    this.router.navigateByUrl('/inicio');
  }
}
