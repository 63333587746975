<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <h2 class="color-1 wow bounceInLeft">Sistema Financiero</h2>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay=".5s">¿Qué es el Sistema Financiero?</h2>
                            <div class="video wow bounceInUp" data-wow-delay = "1s">
                                <video class="video_screen" id="sample_video" controls="false" controlslist="nodownload">
                                    <source src="https://ecofuturo.s3.us-east-1.amazonaws.com/videos/00_ECOFUTURO_EL%20SISTEMA%20FINANCIERO.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="js-stop btn bg-1 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2.5s" style="left:3.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué es la ASFI?</h2>
                                <div class="row align-items-center justify-content-center mb-4">
                                    <div class="col-4">
                                        <div class="wow bounceInDown" data-wow-delay = "1s">
                                            <img class="w-50" src="assets/images/module/logo-asfi.svg">
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="wow bounceInDown" data-wow-delay = "1.5s">
                                            <img class="w-75" src="assets/images/module/1.svg">
                                        </div>
                                    </div>
                                </div>
                            <p class="text-justify wow bounceInUp" data-wow-delay = "1.5s">La ASFI (Autoridad de Supervisión del Sistema Financiero), es una institución que se encarga de regular, controlar y supervisar el funcionamiento del Sistema Financiero Boliviano. De igual manera, la ASFI tiene el objeto de defender y proteger al consumidor financiero frente a actos, hechos u omisiones de las entidades financieras.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 7.5%;" aria-valuenow="7"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:7.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Tareas esenciales de la ASFI:</h2>
                        </div>
                        <div class="col-12">
                            <div class="row align-items-center justify-content-center wow fadeInRight" data-wow-delay = "1s">
                                <div class="col-lg-3 col-3">
                                    <img class="w-75" src="assets/images/module/2.svg">
                                </div>
                                <div class="col-lg-8 col-9 text-left list_icon">
                                    <img src="assets/images/module/right-icon.svg">
                                    <p class="text-justify">Emitir normas para regular las actividades de intermediación financiera y de valores.</p>
                                </div>
                            </div>
                            <div class="row mt-lg-2 mt-5 align-items-center wow fadeInLeft" data-wow-delay = "2s">
                                <div class="col-lg-8 col-9 text-left list_icon">
                                    <img src="assets/images/module/right-icon.svg">
                                    <p class="text-justify">Supervisar el cumplimiento efectivo de dichas normas por parte de las entidades de intermediación financiera que se encuentran bajo el ámbito de control y regulación.</p>
                                </div>
                                <div class="col-lg-3 col-3">
                                    <img class="w-75" src="assets/images/module/3.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 15%;" aria-valuenow="15"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:15%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center justify-content-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = "1s">¿Cuáles son los objetivos de la regulación y supervisión financiera?</h2>
                            <img class="wow fadeInUp mb-4 w-50" data-wow-delay = "1s" src="assets/images/module/4.svg" width="70%">
                        </div>
                        <div class="col-lg-8 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Proteger</strong> los ahorros colocados en las entidades de intermediación financiera autorizadas, fortaleciendo la confianza del público en el Sistema Financiero Boliviano.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 22%;" aria-valuenow="22"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:22%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="wow slideInDown mb-4" data-wow-delay = "1s" src="assets/images/module/5.svg" width="70%">
                        </div>
                        <div class="col-lg-8 wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Promover</strong> el acceso universal a los servicios financieros.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 30%;" aria-valuenow="30"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:30%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 6 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/6.svg" width="70%">
                        </div>
                        <div class="col-lg-12 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Asegurar</strong> que las entidades financieras proporcionen medios transaccionales eficientes y seguros, que faciliten la actividad económica y satisfagan las necesidades del consumidor financiero.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 37%;" aria-valuenow="37"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:37%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 7 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/7.svg" width="30%">
                        </div>
                        <div class="col-lg-10 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Controlar</strong> el cumplimiento de las políticas y metas de financiamiento establecidas por el Órgano Ejecutivo a nivel central del Estado.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 44%;" aria-valuenow="44"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:44%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 8 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/8.svg" width="30%">
                        </div>
                        <div class="col-lg-6 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Proteger</strong> al consumidor financiero e investigar denuncias en el ámbito de su competencia.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 51%;" aria-valuenow="51"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:51%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 9 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/9.svg" width="70%">
                        </div>
                        <div class="col-lg-8 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Controlar</strong> el financiamiento destinado a satisfacer las necesidades de vivienda de las personas. Principalmente la vivienda de interés social para la población de menores ingresos.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 58%;" aria-valuenow="58"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:58%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 10 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/10.svg" width="40%">
                        </div>
                        <div class="col-lg-12 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Promover</strong> una mayor transparencia de información en el Sistema Financiero, como un mecanismo que permita a los consumidores financieros de las entidades supervisadas acceder a mejor información sobre tasas de interés, comisiones, gastos y otros relacionados con la contratación de servicios financieros que conlleve a una mejor toma de decisiones sobre una base más informada.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 65%;" aria-valuenow="65"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:65%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 11 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="mb-4 wow slideInDown" data-wow-delay = "1s" src="assets/images/module/11.svg" width="70%">
                        </div>
                        <div class="col-lg-10 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Asegurar</strong> la prestación de servicios financieros con atención de calidad.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 72%;" aria-valuenow="72"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:72%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 12 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li>
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <img class="wow slideInDown" data-wow-delay="1s" src="assets/images/module/12.svg" width="60%">
                        </div>
                        <div class="col-lg-10 text-left wow fadeInUp list_icon" data-wow-delay = "1.4s">
                            <img src="assets/images/module/right-icon.svg">
                            <p class="text-justify"><strong class="color-1">Preservar</strong> la estabilidad, solvencia y eficiencia del Sistema Financiero.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 79%;" aria-valuenow="79"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:79%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 13 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header text-left">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-1">Sistema Financiero</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-1">El Rol de la ASFI</h2>
                        </li>
                        <li>
                            <h2 class="color-1">Alcance de la Normativa</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Alcance de la Normativa</h2>
                            <div class="video wow bounceInUp" data-wow-delay = "1s">
                                <video class="video_screen" id="normativa_video" controls="false" controlslist="nodownload">
                                    <source src="https://ecofuturo.s3.amazonaws.com/videos/00_ECOFUTURO_ALCANCE_DE_LA_NORMATIVA.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-1 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay = "2s" (click)="stopSecuence()">ANTERIOR</a>
                        <a *ngIf="this.finalModule == false" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s" data-toggle="modal" data-target=".module-completed" (click)="stopSecuence()">FINALIZAR</a>
                        <a *ngIf="this.finalModule == true" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s"  (click)="goToHome()">IR AL INICIO</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 100%;" aria-valuenow="100"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next" (click)="activeWow()"></div>
    <div class="swiper-button-prev" (click)="activeWow()"></div> -->
</swiper>

<app-footer></app-footer>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/module/module-completed.svg">
                            <h2 class="color-1 mt-2">COMPLETASTE EL MÓDULO 1</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-1">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/intermedio/modulo-2" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-1">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-1">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
