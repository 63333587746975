import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { AppComponent } from 'src/app/app.component';
import { ClientService } from '../../services/client.service';
import { Client} from '../../models/client.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verificar-email',
  templateUrl: './verificar-email.component.html',
})
export class VerificarEmailComponent implements OnInit {

  constructor( private router: Router,private wowService: NgwWowService, private clientService: ClientService ) { }

  ngOnInit(): void {
    var urlTokenPosition = (window.location.href).indexOf("token=") + 6;
    var token = (window.location.href).substring(urlTokenPosition).toString();
    this.clientService.verificarEmail( token ).subscribe(
      data => {
        if( data['data']['verify'] == true ) {
          alert( data['message'] );
          this.router.navigateByUrl('/inicio', { state: [true]});
        } 
      }, 
      err => {
        alert( err.error['message'] );
        this.router.navigateByUrl('/inicio');
        return;
        // location.reload();
      }

    );
  }

}
