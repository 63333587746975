<app-header></app-header>
<section class="section py-5 page_forms">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <h2 class="color-1">Datos Personales</h2>

                <p>Los datos personales solicitados a continuación, serán utilizados por el Banco únicamente para fines de la campaña y del sorteo correspondiente.</p>

                <form
                novalidate
                autocomplete="off"
                [formGroup]="registerForm"
                (ngSubmit)="completarRegistroUsuario()"
                >
                    <div class="form-group">
                        <label for="inputAddress">Nombre Completo</label>
                        <input type="text" class="form-control" id="inputAddress" (keypress)="keyPressAlphabetic($event)" formControlName="nameComplete" placeholder="Escribe tu nombre completo" maxlength="60">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Género</label>
                            <select id="inputState" class="form-control" formControlName="gender">
                                <option selected value="">Selecciona tu género</option>
                                <option>Masculino</option>
                                <option>Femenino</option>
                                <option>Otro</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Fecha de Nacimiento</label>
                            <input id="birth-date" type="date" min="1899-01-01" placeholder="dd/mm/yyyy" class="form-control datechk" formControlName="birthdate" (change)="calcularEdad()">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Edad <small class="color-4">- Cálculo automático</small></label>
                            <!-- <input type="number" class="form-control" id="inputEmail4" placeholder="Cálculo automático" > -->
                            <p id="text-birthday" class="form-control input-autocompete-bg"></p>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Departamento <small class="color-4">- En el que vive actualmente</small></label>
                            <select formControlName="department" id="inputState" class="form-control">
                                <option selected value="">Selecciona tu departamento</option>
                                <option>Chuquisaca</option>
                                <option>La Paz</option>
                                <option>Cochabamba</option>
                                <option>Oruro</option>
                                <option>Potosí</option>
                                <option>Tarija</option>
                                <option>Santa Cruz</option>
                                <option>Beni</option>
                                <option>Pando</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">                        
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Ciudad o Localidad <small class="color-4">- En la que vive actualmente</small></label>
                            <input type="text" formControlName="city" class="form-control" id="inputPassword4" (keypress)="keyPressAlphabetic($event)" placeholder="Escribe tu ciudad" maxlength="40">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputAddress">Dirección</label>
                            <input type="text" formControlName="address" class="form-control" id="inputAddress" placeholder="Escribe tu dirección de domicilio" maxlength="50">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Nº Celular</label>
                            <input type="text" onKeyPress="if(this.value.length==8) return false;" formControlName="cellPhone" class="form-control" placeholder="Escribe tu número de celular" (keypress)="keyPressNumeric($event)">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Correo Electrónico</label>
                            <input type="email" class="form-control input-autocompete-bg" id="inputEmail4" placeholder="Escribe tu correo electrónico" value="{{ this.client.email }}" disabled>
                        </div>
                    </div>

                    <div class="form-row">                        
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Nº Teléfono Fijo <small class="color-4">(Opcional)</small></label>
                            <input id="phone" onKeyPress="if(this.value.length==7) return false;" type="text" class="form-control" placeholder="Escribe tu número de teléfono fijo" (keypress)="keyPressNumeric($event)">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputState">Nivel de Educación</label>
                            <select formControlName="educationLevel" id="inputState" class="form-control">
                                <option selected value="">Selecciona tu nivel de educación</option>
                                <option>Preescolar</option>
                                <option>Primaria</option>
                                <option>Secundaria</option>
                                <!-- <option>Bachillerato</option>
                                <option>Universitario</option> -->
                                <option>Superior</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="inputState">¿Cuentas con algún tipo de discapacidad?</label>
                        <br>
                        <div class="form-check form-check-inline">
                            <input (change)="show = true" ng-model="show" class="form-check-input" type="radio" formControlName="disability_condition" value="si" id="inlineRadio1">
                            <label class="form-check-label" for="inlineRadio1">Si</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="show = false" class="form-check-input" type="radio" formControlName="disability_condition" value="no" id="inlineRadio2">
                            <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                    </div>

                    <div class="form-row disability-options" *ngIf="show" id="divshow">
                        <div class="form-group col-md-12">
                            <label for="inputState">Tipo de discapacidad <small class="color-4">(Puedes seleccionar varias opciones)</small></label>
                            <div class="form-check">
                                <input name="disability" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Física">
                                <label class="form-check-label color-text" for="inlineCheckbox1">Física</label>
                            </div>
                            <div class="form-check">
                                <input name="disability" class="form-check-input" type="checkbox" id="inlineCheckbox2" value="Psíquica o mental">
                                <label class="form-check-label color-text" for="inlineCheckbox2">Psíquica o mental</label>
                            </div>
                            <div class="form-check">
                                <input name="disability" class="form-check-input" type="checkbox" id="inlineCheckbox3" value="Intelectual">
                                <label class="form-check-label color-text" for="inlineCheckbox3">Intelectual</label>
                            </div>
                            <div class="form-check">
                                <input name="disability" class="form-check-input" type="checkbox" id="inlineCheckbox4" value="Sensorial">
                                <label class="form-check-label color-text" for="inlineCheckbox4">Sensorial</label>
                            </div>
                        </div>
                    </div>

                    <!-- <h2 class="color-1 mt-4">Datos persona de referencia</h2>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Nombre Completo</label>
                            <input type="text" formControlName="referenceName" class="form-control" id="inputEmail4" placeholder="Escribe el nombre de una persona de referencia" required>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Nº Celular o Teléfono Fijo</label>
                            <input type="number" formControlName="referencePhone" class="form-control" id="referencePhone" placeholder="Escribe el número de teléfono de la persona" required>
                        </div>
                    </div> -->

                    <!-- <p class="text-danger" *ngIf="campoNoValido('email')">El email es obligatorio.</p> -->
                    <p class="text-danger" *ngIf="campoNoValido('nameComplete')">El nombre completo es obligatorio.</p>
                    <p class="text-danger" *ngIf="campoNoValido('gender')">El género es obligatorio.</p>
                    <p class="text-danger" *ngIf="campoNoValido('birthdate')">La fecha de nacimiento es obligatoria.</p>
                    <p class="text-danger" *ngIf="campoNoValido('department')">El nombre del departamento es obligatorio.</p>
                    <p class="text-danger" *ngIf="campoNoValido('city')">El nombre de la ciudad o localidad es obligatorio.</p>
                    <p class="text-danger" *ngIf="campoNoValido('address')">La dirección es obligatoria.</p>
                    <p class="text-danger" *ngIf="campoNoValido('cellPhone')">El campo N° Celular es obligatorio</p>
                    <p class="text-danger" *ngIf="campoNoValido('educationLevel')">El nivel de educación es obligatorio.</p>
                    <!-- <p class="text-danger" *ngIf="campoNoValido('referenceName')">El nombre de referencia es obligatorio.</p> -->
                    <p class="text-danger" *ngIf="campoNoValido('disability_condition')">Debe especificar si tiene algun tipo de discapacidad.</p>

                    <p class="text-danger" *ngIf="registerForm.get('nameComplete').hasError('minlength')">Mínimo 5 caracteres para el nombre completo</p>
                    <p class="text-danger" *ngIf="registerForm.get('city').hasError('minlength')">Mínimo 3 caracteres para la ciudad o localidad</p>
                    <p class="text-danger" *ngIf="registerForm.get('cellPhone').hasError('minlength')">Mínimo 8 caracteres para el N° de celular</p>
                    <!-- <p class="text-danger" *ngIf="campoNoValido('referencePhone')">El Nº de celular o teléfono fijo de referencia es obligatorio.</p> -->

                    <p class="mt-4">Al guardar mis datos, acepto y confirmo haber leído las Bases Legales de la promoción empresarial <a class="color-1" href="./assets/pdf/bases-legales.pdf" target="_blank"><strong>(Haz click aquí)</strong></a>.</p>

                    <button type="submit" class="btn main_btn bg-1 text-white" id="btn__submit_register_complete">Guardar mis datos</button>
                    <button style="display:none;" id="register-success" data-toggle="modal" data-target=".register-completed">Registro completado</button>
                </form>

                <h5 class="color-1 mt-5">Condiciones y Restricciones</h5>
                <p>¿Se puede crear otro usuario con el mismo Documento de Identificación? <strong class="color-3">NO.</strong></p>
                <p>¿Pueden participar sólo personas que tengan nacionalidad o residencia boliviana? <strong class="color-3">SÍ.</strong></p>
                <p>¿Sólo pueden participar personas mayores de 18 años? <strong class="color-3"> Todas las personas, sin importar la edad, pueden realizar el curso. Sin embargo, sólo las personas mayores de 18 años pueden participar del sorteo de los premios.</strong></p>
            </div>

            <div class="col-lg-4">
                <img class="banner_register" src="assets/images/register-banner.svg">
            </div>
        </div>
    </div>
</section>

<section class="container-fluid affter_footer">
    <div class="row justify-content-center align-items-center py-3">
        <div class="col-lg-auto inline-block text-lg-left text-center">
            <img src="assets/images/elements/aj.png">
            <p class="aj-legend"><small>Actividad Autorizada y Fiscalizada por la Autoridad de Juegos.<br>CLICK Financiero, promoción válida del 28 de octubre de 2021 al 07 de febrero de 2022.</small></p>
        </div>

        <div class="col-lg-auto col-12 text-center">
            <!-- <a class="btn main_btn bg-3 text-white my-2" data-toggle="modal" data-target="#termsConditions">Ver bases legales</a> -->
            <a class="btn main_btn bg-3 text-white my-2" href="./assets/pdf/bases-legales.pdf" target="_blank">Ver bases legales</a>
        </div>

        <div class="col-lg-auto text-center">
            <h6 class="color-3">Síguenos en las redes</h6>
            <ul class="social_buttons mb-0">
                <li><a href="https://www.facebook.com/BancoEcofuturo/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/bancoecofuturo/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UC2doer5UJozi4Rx89nEM_0g" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                <li><a href="https://www.linkedin.com/company/banco-ecofuturo/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
        </div>
    </div>
</section>

<app-footer></app-footer>

<!-- REGISTER COMPLETED-->
<div class="modal fade register-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡REGISTRO COMPLETADO!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-modal">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 mb-4" src="assets/images/icons/register-completed.svg" width="40%"><br><br>
                            <!-- <a class="btn bg-3 text-white px-5 mt-3" data-dismiss="modal" routerLink="/inicio"><strong>Ir al Inicio</strong></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- TERMS & CONDITIONS-->
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <div class="modal-body">
                <div class="row p-5">
                    <div class="col">
                        <h3 class="color-1 text-center mt-2 mb-3">Bases Legales</h3>
                        <ul>
                            <li><p>Si el contenido es puntual, breve y no tuviera demasiados elementos gráficos se propone el actual popup.</p></li>
                            <li><p>Si el contenido abarca mucho texto o de alguna manera es extenso y tuviera otros elementos gráficos se recomiendo que esta vista este en una página y no un popup.</p></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus qui ratione, minima aliquam voluptatem nihil! Dolores, necessitatibus! Obcaecati rerum atque veniam adipisci sequi, aliquam tenetur odit facilis facere. Eligendi, impedit magnam. Commodi, earum aliquid asperiores velit animi esse sint ad nostrum quisquam repellat quasi, veniam cumque neque error, odit eaque!</p>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas eaque, reprehenderit unde reiciendis officiis quisquam minus a ducimus doloribus incidunt dicta officia eligendi voluptatum similique deserunt expedita molestiae neque fugiat voluptates ipsam minima ea consequuntur. Accusantium eius sapiente magnam dicta ratione distinctio maxime quidem! Repellat maiores sunt dolor dolorem numquam! Numquam, ducimus! Ipsa, iste, necessitatibus officiis reiciendis ex praesentium est dolores ratione vitae vel adipisci, dolore animi. Quibusdam repellat, quod molestias obcaecati, quos tempore voluptas aperiam, doloribus ullam ipsum reprehenderit!</p>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium et sit impedit dolor inventore molestiae illo quibusdam neque vitae tenetur ipsa at hic facere voluptatum, laboriosam aliquid a? Explicabo blanditiis amet quo libero aliquid iste cum eos facere corporis, laborum nisi earum, dolores aliquam omnis, magnam aut soluta possimus adipisci?</p>
                    </div>
                    <div class="col-12 text-center">
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
