<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">¿Cómo podemos ahorrar?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">¿Cómo podemos ahorrar?</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">Conociendo ya los conceptos importantes en cuanto al Ahorro aprendidos en los módulos anteriores y habiendo elaborado un presupuesto básico para conocer la cantidad de dinero que puede destinarse al ahorro de manera mensual para el cumplimiento de nuestras metas u objetivos, es importante abordar las formas de ahorro que existen, y así elegir la más adecuada para cada uno.</p>
                      <h4 class="color-4 pb-1 wow bounceInDown" data-wow-delay="1.5s">Conoce las diferentes formas de ahorrar</h4>
                      <div class="row align-items-center justify-content-center mb-4">
                        <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1.5s">
                              <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter4/1.png">
                              <p class="mb-0 bg-4 rounded-2 text-white px-2 m-auto">Bajo el Colchón</p>
                            </div>
                        </div>
                        <div class="col-4">
                          <div class="wow bounceInDown" data-wow-delay = "1.5s">
                            <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter4/2.png">
                            <p class="mb-0 bg-4 rounded-2 text-white px-2 m-auto">En alcancías</p>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="wow bounceInDown" data-wow-delay = "1.5s">
                            <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter4/3.jpg">
                            <p class="mb-0 bg-4 rounded-2 text-white px-2 m-auto">En el Banco</p>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-4 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Ahorrar bajo el colchón</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Ahorrar bajo el colchón</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">Muchas personas tienen temor a cualquier otra forma de ahorro, por lo que optan por poner el dinero físicamente bajo el colchón u otro espacio del hogar.</p>
                      <div class="row align-items-start justify-content-center mb-4">
                        <div class="col-4">
                          <div class="wow bounceInDown" data-wow-delay = "1.5s">
                              <h4 class="color-4 pb-5">BENEFICIOS</h4>
                              <p class="mb-0 p-grid">Las personas sienten tranquilidad de tener su dinero cerca.</p>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1.5s">
                              <img class="max-w-xxl d-block m-auto" src="assets/images/initial/chapter4/pros-con-1.png">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1.5s">
                            <h4 class="color-4 pb-5">RIESGOS</h4>
                            <p class="mb-0 p-grid">Puede ser robado o perderse.</p>
                            <p class="mb-0 p-grid">También puede generar preocupación de tener todos los ahorros en un lugar totalmente accesible.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">

                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 14.28%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:14.78%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Ahorrar en una alcancía</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Ahorrar en una alcancía</h2>
                      <div class="row align-items-start justify-content-center mb-4">
                        <div class="col-4">
                          <div class="wow bounceInDown" data-wow-delay = "1s">
                              <h4 class="color-4 pb-5">BENEFICIOS</h4>
                              <p class="mb-0 p-grid">Es un buen método para que los niños aprendan sobre el valor del dinero y cómo ahorrar.</p>
                              <p class="mb-0 p-grid">Podemos ahorrar por tipo de moneda, por ejemplo, monedas de Bs. 5; por el lapso de un año como un ahorro adicional.</p>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1s">
                              <img class="max-w-xxl d-block m-auto" src="assets/images/initial/chapter4/pros-con-2.jpg">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1s">
                            <h4 class="color-4 pb-5">RIESGOS</h4>
                            <p class="mb-0 p-grid">Es un método de ahorro limitante.</p>
                            <p class="mb-0 p-grid">Igualmente corre el riesgo de que sea robado o que se pierda.</p>
                            <p class="mb-0 p-grid">Es probable que se retire dinero de la alcancía sin respetar que es únicamente para ahorro.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 28.56%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:29.06%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Ahorrar en una entidad financiera</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Ahorrar en una entidad financiera</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">Muchas personas desconocen sobre los beneficios y cómo funciona el ahorro en las entidades financieras.</p>
                      <div class="row align-items-start justify-content-center mb-4">
                        <div class="col-4">
                          <div class="wow bounceInDown" data-wow-delay = "1.5s">
                              <h4 class="color-4 pb-5">BENEFICIOS</h4>
                              <p class="mb-0 p-grid">Se gana un interés por ahorrar en el Banco.</p>
                              <p class="mb-0 p-grid">El dinero se encuentra seguro y cuenta con diferentes filtros de seguridad que proveen las entidades financieras.</p>
                              <p class="mb-0 p-grid">Puedes manejar tus ahorros a conveniencia desde tu teléfono.</p>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1.5s">
                              <img class="max-w-xxl d-block m-auto" src="assets/images/initial/chapter4/pros-con-3.jpg">
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown" data-wow-delay = "1.5s">
                            <h4 class="color-4 pb-5">RIESGOS</h4>
                            <p class="mb-0 p-grid">No debes brindar tus datos de banca electrónica o contraseñas de tus tarjetas para evitar que personas maliciosas roben tu dinero. Las entidades financieras proveen medidas de seguridad para que los clientes y usuarios se sientan tranquilos de ahorrar en ellas.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 42.84%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:43.34%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">¿Cómo puedo ahorrar en una entidad financiera?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">¿Cómo puedo ahorrar en una entidad financiera?</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">Es recomendable ahorrar tu dinero en una entidad financiera supervisada por la ASFI para que tu dinero se encuentre seguro. Existen 3 tipos de productos de ahorro:</p>
                      <img data-wow-delay = "1.5s" class="max-w-xl d-block m-auto wow bounceInDown" data-wow-delay="1.5s" src="assets/images/initial/chapter4/merged-1.png">
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 57.12%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:57.62%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 6 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Caja de Ahorros</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row content_center">
                      <div class="col-12">
                        <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Caja de Ahorros</h2>
                        <p class="wow bounceInDown text-justify" data-wow-delay="1s">Una caja de ahorros se define como un producto de captación, que permite al denominado ahorrista, realizar depósitos y retiros de dinero y cuyos saldos generen intereses diarios, mismos que se capitalizan mensualmente.</p>
                        <div class="row align-items-start justify-content-center mb-4">
                            <div class="col-6">
                                <div class="wow bounceInDown" data-wow-delay = "1.5s">
                                    <h4 class="color-4">Beneficios</h4>
                                    <p class="mb-0 p-grid"> Accede a una Tarjeta de Débito</p>
                                    <p class="mb-0 p-grid"> No tiene límites de retiros ni depósitos</p>
                                    <p class="mb-0 p-grid"> Libre disponibilidad de efectivo a través de cajeros automáticos y en agencias a nivel nacional</p>
                                    <p class="mb-0 p-grid"> Uso de la Tarjeta de Débito en puntos POS</p>
                                    <p class="mb-0 p-grid"> Acceso a la Banca Electrónica para consultas, extractos u operaciones</p>
                                    <p class="mb-0 p-grid"> Acceso a compras por internet con la Tarjeta de Débito</p>
                                    <p class="p-grid">Acceso al estado de la cuenta en tiempo real a través de la aplicación o banca por internet</p>
                                    <h4 class="color-4">Requisitos</h4>
                                    <p class="mb-0 p-grid"> Carnet de Identidad vigente (u otro documento a requerimiento del Banco)</p>
                                </div>
                            </div>
                            <div class="col-6">
                              <div class="wow bounceInDown img-container image-xl m-auto" data-wow-delay = "2s">
                                <img src="assets/images/initial/chapter4/9.jpg">
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 71.4%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:71.9%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 7 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Cuenta Corriente</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Cuenta Corriente</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">La cuenta corriente es un servicio otorgado por una entidad de intermediación financiera autorizada, que se formaliza mediante un contrato, en virtud del cual se faculta a una persona natural o jurídica a depositar cantidades de dinero que puede ir retirando en cualquier momento. Es un tipo de cuenta que no genera intereses y su manejo implica la emisión de cheques para el retiro de fondos.</p>
                      <div class="row">
                          <div class="col-6 wow bounceInDown" data-wow-delay = "1.5s">
                            <h4 class="color-4">Beneficios</h4>
                            <p class="mb-0 p-grid">Acceso a una Tarjeta de Débito para transacciones diarias</p>
                            <p class="mb-0 p-grid">Acceso a servicios de la plataforma transaccional ECONET</p>
                            <p class="mb-0 p-grid">No implica cobros por mantenimiento de cuenta</p>
                            <p class="p-grid">Evita el movimiento de efectivo físico</p>
                            <h4 class="color-4">Requisitos</h4>
                            <p class="mb-0 p-grid">Fotocopia de Carnet de Identidad vigente (u otro documento a requerimiento del Banco)</p>
                          </div>
                          <div class="col-6 wow bounceInDown img-container image-xl m-auto" data-wow-delay = "2s">
                            <img src="assets/images/initial/chapter4/10.jpg">
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 85.68%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:86.18%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 8 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Productos de ahorro</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Depósito a Plazo Fijo</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center">
                    <div class="col-12">
                      <h2 class="color-4 pb-5 wow bounceIn" data-wow-delay=".5s">Depósito a Plazo Fijo</h2>
                      <p class="wow bounceInDown text-justify" data-wow-delay="1s">Es la entrega o depósito de dinero bajo la modalidad de plazo fijo, en una entidad supervisada, lo que debe documentarse mediante la expedición de un DPF físico o su representación a través de su anotación en cuenta, cumpliendo requisitos, términos y condiciones establecidas.</p>
                      <div class="row align-items-start justify-content-center mb-4">
                          <div class="col-8">
                              <div class="wow bounceInDown" data-wow-delay = "1.5s">
                                  <h4 class="color-4">Beneficios</h4>
                                  <p class="mb-0 p-grid">El pago de los intereses se realiza al término del tiempo pactado.</p>
                                  <p class="mb-0 p-grid">Se tiene la seguridad de que tu dinero será administrado por una institución confiable</p>
                                  <p class="mb-0 p-grid">Acceso a diferentes opciones para el pago de los intereses</p>
                                  <p class="p-grid">Disponibilidad de diferentes plazos que permiten mejor remuneración</p>
                                  <h4 class="color-4">Requisitos</h4>
                                  <p class="mb-0 p-grid">Fotocopia de Carnet de Identidad vigente (u otro documento a requerimiento del Banco)</p>
                                  <p class="mb-0 p-grid">Formulario de Solicitud de Constitución de DPF</p>
                                  <p class="mb-0 p-grid">Inscripción en el Padrón Nacional de Contribuyentes Biométrico, cuando corresponda.</p>
                              </div>
                          </div>
                          <div class="col-4">
                            <div class="wow bounceInDown img-container image-xl type-90 m-auto" data-wow-delay = "2s">
                              <img class="max-w-xxl d-block m-auto" src="assets/images/initial/chapter4/11.png">
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-4 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s" (click)="activeWow()">ANTERIOR</a>
                        <a *ngIf="this.finalModule == false" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                        <a *ngIf="this.finalModule == true" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE DÉBITO</h4>
                        <p>Es un IEP que se le otorga al cliente, de forma gratuita, cuando obtiene una cuenta de ahorro o una cuenta corriente. Con ella se pueden realizar pagos, retiros en efectivo, rescate de cuotas y consultar los movimientos de la cuenta bancaria. El dinero es debitado del saldo de la cuenta asociada. </p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE CRÉDITO</h4>
                        <p>Es un IEP que dispone el cliente al cual el Banco ha otorgado un límite de crédito en respuesta a su solicitud. A través de esta tarjeta se pueden realizar compras o extraer efectivo hasta el límite aprobado. A diferencia de la tarjeta de débito, en vez de debitarse de la cuenta, se utiliza un monto del dinero prestado, mismo que se debe pagar de forma periódica con intereses.  Por esta razón, antes de conceder una tarjeta de crédito, el banco estudia la viabilidad del cliente, asegurándose de que sea solvente.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-4">TARJETA PREPAGADA</h4>
                        <p>Es un IEP que dispone de un monto de dinero almacenado, pagado previamente a su emisor. Con ella se realizan pagos hasta el valor del importe cargado.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-3-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">BANCA ELECTRÓNICA</h4>
                        <p>Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de un dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <p>La afiliación al servicio es gratuita y difiere de entidad a entidad. Consulta con el banco de tu preferencia para averiguar los requisitos.</p>
                        <p>Se pueden realizar distintas acciones:</p>
                        <ul>
                            <li>Consultar Saldos y movimientos</li>
                            <li>Transferencias entre cuentas propias, a cuentas de terceros o interbancarias, es decir a otros bancos.</li>
                            <li>Pago de Préstamos</li>
                            <li>Pago de servicios</li>
                            <li>Solicitar ayuda</li>
                            <li>Información sobre los Puntos de Atención</li>
                        </ul>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/cajero.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">CAJERO AUTOMÁTICO:</h4>
                        <p>Un Cajero Automático es un ordenador, una máquina, empleada por una entidad financiera que permite realizar operaciones financieras, entre las que destacan el ingreso o retiro de dinero en efectivo, sin la necesidad de la presencia de un empleado de un banco.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-5 col-12">
                        <img src="assets/images/module-4/step-5-3.svg" width="80%">
                    </div>
                    <div class="col-lg-7 col-12 text-left">
                        <h3 class="color-4">ÓRDENES ELECTRÓNICAS DE TRANSFERENCIA DE FONDOS</h3>
                    </div>
                    <div class="col-lg-11 col-12">
                        <h4 class="color-4">BANCA MÓVIL:</h4>
                        <p class="text-justify">Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <h4 class="color-4">ECONET:</h4>
                        <p class="text-justify">EcoNet, es una plataforma que proveé un servicio digital a los clientes, a través de una aplicación o de la Página Web del Banco, con la cual pueden realizar operaciones bancarias de forma ágil, sencilla y segura.</p>
                        <p class="text-justify">EcoNet, además de las transacciones generales previamente mencionadas, tiene disponible el P2P (elemento innovador del Banco a través del cual se pueden hacer transferencias entre cuentas utilizando únicamente el número del teléfono móvil del receptor) y finalmente, SIMPLE (que permite realizar operaciones bancarias a través del uso de un código QR).</p>
                        <div class="center text-center mt-3">
                            <a class="btn bg-3 text-white px-4" href="https://www.bancoecofuturo.com.bo/sitio/canales-contenido/69" target="_blank"><strong>HAZ CLICK AQUÍ</strong></a>
                            <p class="mt-1">Si requieres más información sobre EcoNet.</p>
                        </div>
                        <a class="btn bg-4 text-white px-5 mt-4 text-right" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-7 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide7modal" tabindex="-1" role="dialog" aria-labelledby="slide7-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">Billetera Móvil:</h4>
                        <p>El servicio de Billetera Móvil para transferir recursos desde un celular es prestado por diferentes empresas de telecomunicaciones, como ser Entel, etc. Este aspecto debe ser consultado con el Banco que ofrezca el servicio de Billetera Móvil.</p>
                        <p>Se trata de un servicio cómodo, ágil y rápido, que no requiere un saldo mínimo de apertura, cuenta con un costo de las transacciones accesible y en algunos casos gratuito y está disponible para cualquier persona.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/icons/initial/4.png">
                            <h2 class="color-4 mt-2">COMPLETASTE EL MÓDULO 4</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module-4/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-4">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/inicial" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-4">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-4">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
