import { Component, OnInit } from '@angular/core';
import { Client } from '../models/client.model';
import { ClientService } from '../services/client.service';
import { Router } from '@angular/router';
import { forkJoin, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

declare function sidebarAdmin();

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public usuario:Client;
  public client = new Client( '', '', '');
  public isAuth: boolean = false;
  public contentUser = {};
  public classCurrentModule = '';
  constructor(private clientService: ClientService, private router: Router,) {
    sidebarAdmin();
  }

  ngOnInit(): void {
    var now = new Date();
    if( now.getTime() > Date.parse( localStorage.getItem('date_expiration') ) && localStorage.getItem('date_expiration') != null ) {
      const requests = [
        this.clientService.refrescarToken(),
        // this.clientService.obtenerUsuario()
      ];
      from(requests).pipe(    
        concatMap((request) => request.pipe())
      ).subscribe((data) => {
          this.clientService.obtenerUsuario().subscribe(
            res => {
              if( res == false ) {
                alert( 'Existen errores al procesar su solicitud.' );
                this.router.navigateByUrl('/inicio');
                return;
              }
              this.contentUser = res['data'];
              if( this.contentUser['role'] == 'User' ) {
                this.router.navigateByUrl('/inicio');
                return
              }
              this.client.address = this.contentUser['address'];
              this.client.age = this.contentUser['age'];
              this.client.cellPhone = this.contentUser['cellPhone'];
              this.client.ci = this.contentUser['ci'];
              this.client.city = this.contentUser['city'];
              this.client.completeRegister = this.contentUser['completeRegister'];
              this.client.currentModule = this.contentUser['currentModule'];
              this.client.disability = this.contentUser['disability'];
              this.client.educationLevel = this.contentUser['educationLevel'];
              this.client.email = this.contentUser['email'];
              this.client.gender = this.contentUser['gender'];
              this.client.nombre = this.contentUser['nameComplete'];
              this.client.nameComplete = this.contentUser['nameComplete'];
              this.client.phone = this.contentUser['phone'];
              this.client.role = this.contentUser['role'];        
              this.isAuth = true;
            }
          );
      });
    } else {
      if( localStorage.getItem('email') != null ) {
        this.clientService.obtenerUsuario().subscribe(
          data => {
            if( data == false ) {
              this.router.navigateByUrl('/inicio');
              return;
            }
            this.contentUser = data['data'];
            this.isAuth = true;
            if( this.contentUser['role'] == 'User' ) {
              this.router.navigateByUrl('/inicio');
              return
            }
            this.client.address = this.contentUser['address'];
            this.client.age = this.contentUser['age'];
            this.client.cellPhone = this.contentUser['cellPhone'];
            this.client.ci = this.contentUser['ci'];
            this.client.city = this.contentUser['city'];
            this.client.completeRegister = this.contentUser['completeRegister'];
            this.client.currentModule = this.contentUser['currentModule'];
            this.client.disability = this.contentUser['disability'];
            this.client.educationLevel = this.contentUser['educationLevel'];
            this.client.email = this.contentUser['email'];
            this.client.gender = this.contentUser['gender'];
            this.client.nombre = this.contentUser['nameComplete'];
            this.client.nameComplete = this.contentUser['nameComplete'];
            this.client.phone = this.contentUser['phone'];
            this.client.role = this.contentUser['role'];
          },
          err => {
            this.router.navigateByUrl('/inicio');
            // this.contentUser = JSON.parse(err.error).message;
          }
        );
      }
    }
  }

  logout() {
    localStorage.removeItem( 'email' );
    localStorage.removeItem( 'date_expiration' );
    localStorage.removeItem( 'token' );
    this.router.navigateByUrl('inicio');
    // location.reload(); 
  }

}
