<app-header></app-header>

<swiper [config]="config">
	<div class="swiper-wrapper">
		<!-- SLIDE 1 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
					<h2 class="color-3">Créditos</h2>
				</div>

				<div class="container module_body">
					<div class="row content_center">
						<div class="col-12">
							<h2 class="color-3 pb-5 wow fadeInDown">¿Qué es un Crédito?</h2>
							<p class="wow bounceIn text-justify" data-wow-delay="1.5">El Crédito es una operación
								financiera, en la que una entidad financiera realiza un préstamo de una cantidad de
								dinero determinada a una persona (deudor), con el compromiso de que esta persona
								devuelva (en una o varias cuotas de pago), junto con un interés, en un tiempo o plazo
								definido, de acuerdo con las condiciones establecidas para dicho préstamo. Dichas
								condiciones se encuentran plasmadas en el contrato del Crédito.</p>
							<a class="btn bg-3 text-white py-2 px-4 swiper_next wow fadeIn mb-4" data-wow-delay="1s"
								(click)="activeWow()"><strong>JUGAR ROMPECABEZAS</strong></a>
						</div>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 4%;" aria-valuenow="2"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 2 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
					<h2 class="color-3">Créditos</h2>
				</div>

				<div class="module_body px-xl-5 px-sm-2">
					<h2 class="color-3 pt-5 wow fadeInDown">¿Qué es un Crédito?</h2>
					<div class="container">
						<div class="row justify-content-center content_center text-center" style="height: 55vh;">
							<div class="col-xl-8 col-lg-8 wow fadeIn" data-wow-delay="1s">

								<app-puzzle></app-puzzle>

							</div>
							<div class="col-xl-4 col-lg-4 text-left wow fadeIn" data-wow-delay="2s">
								<ul style="line-height: 1em;">
									<li>
										<p class="px-0 text-justify"><small>Resuelve el siguiente Juego Rompecabezas
												para avanzar a la siguiente lección.</small></p>
									</li>
									<li>
										<p class="px-0 text-justify"><small>Intercambia las piezas haciendo click sobre
												ellas.</small></p>
									</li>
									<li>
										<p class="px-0 text-justify"><small>Comienza por las esquinas: las piezas
												quedarán pegadas al marco si se colocan en el lugar correcto.</small>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 15%;" aria-valuenow="15"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 3 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
					<h2 class="color-3">Créditos</h2>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center">
						<div class="col-12">
							<img class="wow zoomIn mb-4" data-wow-delay="1s" src="assets/images/module-3/step-2.svg"
								width="55%">
							<h2 class="color-3 pb-4 wow zoomIn">¿Por qué solicitar un Crédito?</h2>
							<p class="text-justify wow fadeInUp" data-wow-delay="1s">Un Crédito es una opción para
								aquellas personas que no cuentan con el dinero necesario en ese momento y lo requieren
								para cubrir un gastos o invertir en su actividad económica, por ejemplo: compra de
								mercadería.</p>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 30%;" aria-valuenow="30"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 4 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
					<h2 class="color-3">Créditos</h2>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center">
						<div class="col-12">
							<img class="wow zoomIn" data-wow-delay="1s" src="assets/images/module-3/step-3.svg"
								width="55%">
							<h2 class="color-3 pb-4 wow zoomIn">¿Cuáles son los beneficios de sacar un Crédito?</h2>
							<ul class="module_list">
								<li class="wow fadeIn" data-wow-delay="1.4s">
									<h5 class="gray-h5"><strong>Acceso Inmediato</strong></h5>
									<p>El crédito puede ayudarte en una emergencia costosa o inesperada
										y darte la flexibilidad de pagar poco a poco.</p>
								</li>
								<li class="wow fadeIn" data-wow-delay="1.8s">
									<h5 class="gray-h5"><strong>Un préstamo de acuerdo a tus posibilidades</strong></h5>
									<p>El banco te prestará dinero de acuerdo a tus necesidades y tu
										capacidad de pago, de manera que puedas cubrir la cuota mensual y no te sobre
										endeudes.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 45.84%;"
								aria-valuenow="45.84" aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 5 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header">
					<ol class="justify-content-start">
						<li class="light-text">
							<h2 class="color-3">Créditos</h2>
						</li>
						<li>
							<h2 class="color-3">Tipos de Crédito</h2>
						</li>
					</ol>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3  wow zoomIn">Tipos de Crédito</h2>
							<p class="pb-4 wow fadeInUp" data-wow-delay="1s">Puedes solicitar distintos tipos de
								créditos de acuerdo, a las necesidades, objetivos y metas que tengas por cumplir.</p>

							<div class="row mb-5">
								<div class="col-lg-auto col-6 py-2 wow fadeIn" data-wow-delay="1s">
									<a class="btn bg-1 text-white py-2 px-2 mx-2" data-toggle="modal"
										data-target="#slide1modal" (click)="dismissAlert()"><strong>Crédito
											Empresarial</strong></a>
									<div class="message-bubble wow pulse" data-wow-iteration="infinite"
										*ngIf="dismissOne">
										<p>¡Haz click aquí!</p>
									</div>
								</div>
								<div class="col-lg-auto col-6 py-2 wow fadeIn" data-wow-delay="1.2s">
									<a class="btn bg-2 text-white py-2 px-2 mx-2" data-toggle="modal"
										data-target="#slide2modal" (click)="dismissAlert2()"><strong>Crédito
											PYME</strong></a>
									<div class="message-bubble wow pulse" data-wow-iteration="infinite"
										*ngIf="dismissTwo">
										<p>¡Haz click aquí!</p>
									</div>
								</div>
								<div class="col-lg-auto col-6 py-2 wow fadeIn" data-wow-delay="1.4s">
									<a class="btn bg-3 text-white py-2 px-2 mx-2" data-toggle="modal"
										data-target="#slide3modal"
										(click)="dismissAlert3()"><strong>Microcrédito</strong></a>
									<div class="message-bubble wow pulse" data-wow-iteration="infinite"
										*ngIf="dismissThree">
										<p>¡Haz click aquí!</p>
									</div>
								</div>
								<div class="col-lg-auto col-6 py-2 wow fadeIn" data-wow-delay="1.6s">
									<a class="btn bg-4 text-white py-2 px-2 mx-2" data-toggle="modal"
										data-target="#slide4modal" (click)="dismissAlert4()"><strong>Crédito de
											Vivienda</strong></a>
									<div class="message-bubble wow pulse" data-wow-iteration="infinite"
										*ngIf="dismissFour">
										<p>¡Haz click aquí!</p>
									</div>
								</div>
								<div class="col-lg-auto col-6 py-2 wow fadeIn" data-wow-delay="1.8s">
									<a class="btn bg-5 text-white py-2 px-2 mx-2" data-toggle="modal"
										data-target="#slide5modal" (click)="dismissAlert5()"><strong>Crédito de
											Consumo</strong></a>
									<div class="message-bubble wow pulse" data-wow-iteration="infinite"
										*ngIf="dismissFive">
										<p>¡Haz click aquí!</p>
									</div>
								</div>
							</div>

							<h3 class="color-3 pb-2 wow fadeInUp" data-wow-delay="1.4s"><span
									class="bank-name color-3">Banco <small class="versalitas">PYME</small>
									Ecofuturo</span> ofrece los siguientes Créditos</h3>
							<ol class="horizontal_list">
								<li class="sub_list wow slideInRight" data-wow-delay="1.8s">
									<div class="item-list color-3">
										<span>1</span>
										<span>Crédito</span>
									</div>
									<ul class="sublist">
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-negocio-contenido/44"
												target="_blank">Eco Disponible</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-negocio-contenido/41"
												target="_blank">Eco Productivo</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-negocio-contenido/42"
												target="_blank">Eco Agropecuario</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/31"
												target="_blank">Eco Vivienda de Interés Social</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/35"
												target="_blank">Eco Consumo</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-negocio-contenido/40"
												target="_blank">Eco Indiviual</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/29"
												target="_blank">Eco Vivienda</a></li>
										<li><a href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/30"
												target="_blank">Eco Hogar</a></li>
									</ul>
								</li>
								<li class="color-1 wow slideInRight" data-wow-delay="2s">
									<div class="item-list">
										<span>2</span>
										<span><a class="color-1"
												href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/38"
												target="_blank">Tarjeta de Crédito</a></span>
									</div>
								</li>
								<li class="color-2 wow slideInRight" data-wow-delay="2.2s">
									<div class="item-list">
										<span>3</span>
										<span><a class="color-2"
												href="https://www.bancoecofuturo.com.bo/sitio/credito-para-mi-contenido/37"
												target="_blank">Boleta de Garantía y Garantía a Primer
												Requerimiento</a></span>
									</div>
								</li>
							</ol>
						</div>
						<div class="col-lg-12">
							<p class="text-ultimate-added mt-0 p-0 text-center">Conoce sobre las Garantías no
								Convencionales haciendo <a class="color-3" href="https://youtu.be/zfcnTBLsqEg"
									target="_blank">Click aquí</a>.</p>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-auto">
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay="2s"
							(click)="activeWow()">SIGUIENTE</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 60.12%;"
								aria-valuenow="60.12" aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 6 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header">
					<ol class="justify-content-start">
						<li class="light-text">
							<h2 class="color-3">Créditos</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Tipos de crédito</h2>
						</li>
						<li>
							<h2 class="color-3">Garante</h2>
						</li>
					</ol>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center">
						<div class="col-12">
							<h2 class="color-3 pb-4 wow zoomIn">¿Qué significa ser Garante? </h2>
							<div class="video wow bounceInUp" data-wow-delay="1s">
								<video class="video_screen" id="normativa_video" controls="false"
									controlslist="nodownload">
									<source
										src="https://ecofuturo.s3.us-east-1.amazonaws.com/videos/00_ECOFUTURO_QUE%20ES%20SER%20GARANTE%20ACTUALIZADO%20FINAL.mp4"
										type="video/mp4">
								</video>
							</div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 75.4%;" aria-valuenow="75.4"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 7 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header">
					<ol class="justify-content-start">
						<li class="light-text">
							<h2 class="color-3">Créditos</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Tipos de crédito</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Garante</h2>
						</li>
						<li>
							<h2 class="color-3">Recomendaciones</h2>
						</li>
					</ol>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center">
						<div class="col-12">
							<h2 class="color-3 pb-4 wow zoomIn">Recomendaciones</h2>
							<div class="row">
								<div class="col-xl-4 col-lg-5 col-md-5 col-sm-12 img-size-resp">
									<img class="wow wow wobble" data-wow-duration="1.5s" data-wow-iteration="infinite"
										data-wow-delay="1s" src="assets/images/module-3/step-6.svg" alt="">
								</div>
								<div class="col-xl-8 col-lg-7 col-md-7 col-sm-12">
									<br>
									<ul class="module_list">
										<li class="text-justify wow slideInRight" data-wow-delay="1.4s">Ten claro qué
											quieres hacer con el crédito y cuál es el momento indicado para solicitarlo.
											Recuerda que desde el momento que te entreguen el dinero, corren los
											intereses.</li>
										<li class="text-justify wow slideInRight" data-wow-delay="1.8s">Debes preparar
											un presupuesto de forma mensual. Toma en cuenta tus ingresos y gastos, así
											sabrás si con el saldo podrás cubrir las cuotas del crédito.</li>
										<li class="text-justify wow slideInRight" data-wow-delay="2.2s">Finalmente,
											antes de adquirir el crédito, haz todas las preguntas necesarias a tu
											oficial de crédito y no asumas compromisos sin antes leer y entender lo que
											estás firmando.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 85.68%;"
								aria-valuenow="85.68" aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 8 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header">
					<ol class="justify-content-start">
						<li class="light-text">
							<h2 class="color-3">Créditos</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Tipos de Crédito</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Garante</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Recomendaciones</h2>
						</li>
						<li>
							<h2 class="color-3">Diferimiento de Créditos</h2>
						</li>
					</ol>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center align-items-start">
						<div class="col-lg-12">
							<h2 class="color-3 py-4 wow zoomIn">Diferimiento de Créditos</h2>
						</div>
						<div class="col-lg-4">
							<img class="w-100 wow zoomIn" data-wow-delay="1s" src="assets/images/module-3/step-7.svg"
								alt="">
						</div>
						<div class="col-lg-8">
							<ul class="module_list mb-4">
								<li class="wow slideInRight text-justify" data-wow-delay="1.4s">
									<h5>Diferimiento: </h5> Aplazamiento/ postergación.
								</li>
								<li class="wow slideInRight text-justify" data-wow-delay="1.8s">
									<h5 class="m-0">Diferimiento de pago: </h5>
									El diferimiento de pago es un apoyo financiero que
										contempla la suspensión de pago de las cuotas (capital e interés) de un crédito, en
										un periodo de tiempo.
									<div class="mt-3"></div>
									El diferimiento es una suspensión temporal de cuota(s)
										por efecto de la Ley 1294. El diferimiento no es una condonación o eliminación del
										capital, intereses o cargos (seguros), si no que estos importes se pagarán al final
										del plan de pagos, en un plazo similar al diferimiento o suspensión temporal.
										Aclarar que el pago de los intereses diferidos, no aplica el término anatocismo
										(cobro de interés sobre interés), debido a que sólo se cobran los intereses que no
										se cancelaron en el periodo de diferimiento.
								</li>
							</ul>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 93%;" aria-valuenow="100"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 9 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header">
					<ol class="justify-content-start">
						<li class="light-text">
							<h2 class="color-3">Créditos</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Tipos de Crédito</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Garante</h2>
						</li>
						<li class="light-text">
							<h2 class="color-3">Recomendaciones</h2>
						</li>
						<li>
							<h2 class="color-3">Diferimiento de Créditos</h2>
						</li>
					</ol>
				</div>

				<div class="container module_body">
					<div class="row content_center vh-resize-2 text-center">
						<div class="col-12">
							<br>
							<h2 class="color-3 pb-4 wow zoomIn">Diferimiento de Créditos</h2>
							<!-- <div class="video type-2">
								<img class="wow zoomIn" data-wow-delay="1s" src="assets/images/module-3/step-7.svg"
									alt="">
							</div> -->
							<p class="text-justify wow fadeIn" data-wow-delay="2s">Debido a la emergencia
								sanitaria generada por el COVID-19 y con el objetivo de recuperar la economía de las
								familias bolivianas y reimpulsar la economía del país, se promulgó la Ley N° 1294
								Excepcional de Diferimiento de Pago de Créditos y Reducción Temporal de Pago de
								Servicios Básicos al Sistema Financiero, estableciendo que las entidades de
								intermediación financiera que operan en Bolivia debieron realizar el diferimiento
								automático del pago de las amortizaciones de crédito hasta el 31 de diciembre de 2020.
							</p>
							<h3 class="text-justify color-3 wow zoomIn subtitle px-3 mt-4">Refinanciamiento y
								Reprogramación de Créditos con cuotas que fueron diferidas</h3>
							<p class="text-justify wow fadeIn" data-wow-delay="2.2s">En enero de 2021, ASFI
								emitió la nueva normativa relacionada al tratamiento de refinanciamientos y/o
								reprogramaciones de aquellos créditos que fueron diferidos durante la gestión 2020</p>
							<p class="text-center mt-4 wow fadeIn" data-wow-delay="2.4s">Para obtener mayor
								información ingresa a:</p>
							<a class="text-center p-small wow fadeIn color-3" data-wow-delay="2.6s"
								href="https://www.youtube.com/watch?v=15VRSgFC7gc&feature=emb_logo"
								target="_blank">DIFERIMIENTO, REPROGRAMACION Y REFINANCIAMIENTO</a>
							<!-- <p class="text-justify p-small wow fadeIn" data-wow-delay="2.2s">La regularización de las cuotas diferidas no afectará la tasa de interés, ni la ejecución de sanciones y penalizaciones por mora.</p> -->
							<!-- <p class="text-justify p-small wow fadeIn" data-wow-delay="2.4s">Todas las personas que tengan un crédito podrán contactar a su entidad financiera u oficial de créditos para establecer su plan de pagos y revisar sus opciones</p> -->
							<!-- <p class="text-justify p-small wow fadeIn" data-wow-delay="2.6s">El diferimiento es una SUSPENSIÓN TEMPORAL del pago de una o más cuotas, no se trata de una condonación o eliminación de las mismas. El capital diferido genera intereses en el marco del contrato pactado con el cliente. Para el caso de intereses se aclara que no existe anatocismo (cobro de interés sobre interés).</p> -->
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-4" style="z-index: 2;">
						<a class="btn bg-3 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a *ngIf="this.finalModule == false"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
						<a *ngIf="this.finalModule == true"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							(click)="goToHome()">IR AL INICIO</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
							<div class="progress-bar bg-3" role="progressbar" style="width: 100%;" aria-valuenow="100"
								aria-valuemin="0" aria-valuemax="100">
								<img class="icon_progress" src="assets/images/module-3/pencil.svg">
							</div>
						</div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>
	</div>
	<!-- <div class="swiper-pagination"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-1">Crédito Empresarial:</h4>
						<p class="text-justify">Crédito cuyo objeto es financiar actividades de producción,
							comercialización o servicios de empresas de gran tamaño.</p>
						<a class="btn bg-1 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-2">Crédito PYME:</h4>
						<p class="text-justify">Crédito otorgado a pequeñas y medianas empresas, con el objeto de
							financiar actividades de producción, comercialización o servicios.</p>
						<a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-3" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-3">Microcrédito:</h4>
						<p class="text-justify">Crédito otorgado a una persona natural o jurídica, o a un grupo de
							prestatarios, con el objeto de financiar actividades económicas de pequeña escala, cuya
							fuente principal de pago la constituye el producto de las ventas e ingresos generados por
							dichas actividades.</p>
						<a class="btn bg-3 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-4" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-4">Crédito de Vivienda:</h4>
						<p class="text-justify">Crédito otorgado a una persona natural, destinado a la adquisición de un
							terreno, compra o construcción de una vivienda, o para la ampliación, refacción y/o
							mejoramiento de una vivienda.</p>
						<a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-5" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-5">Crédito de Consumo:</h4>
						<p class="text-justify">Crédito concedido a una persona natural, con el objeto de financiar la
							adquisición de bienes de consumo o el pago de servicios, cuya fuente principal de pago es el
							salario de la persona o ingresos provenientes de actividades independientes, adecuadamente
							verificados.</p>
						<a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
	aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title text-white">¡FELICIDADES!</h1>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body bg-module-completed">
				<div class="container-fluid center">
					<div class="row justify-content-center mb-4">
						<div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
							<img class="img-1 w-75" src="assets/images/module-3/module-completed.svg">
							<h2 class="color-3 mt-2">COMPLETASTE EL MÓDULO 3</h2>
						</div>
					</div>
					<div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
						<div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
							<img class="img-2" src="assets/images/module-3/quiz.svg">
						</div>
						<div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
							<h4 class="color-3">Completa el QUIZ
                <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                <a routerLink="/intermedio/modulo-4" data-dismiss="modal">aquí</a>
              </h4>
							<!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
						</div>
						<!-- <div class="col-9 text-center mt-2">
							<p><small>No te olvides que por cada <strong class="color-3">módulo que termines,</strong>
									obtienes un cupón más, es decir, <strong class="color-3">una oportunidad más de
										ganar.</strong></small></p>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
