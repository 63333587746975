import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Chapter1InitialComponent } from './chapters/initial/chapter1/chapter1Initial.component';

import { Quiz1Component } from './quiz/quiz1/quiz1.component';
import { Quiz2Component } from './quiz/quiz2/quiz2.component';
import { Quiz3Component } from './quiz/quiz3/quiz3.component';
import { Quiz4Component } from './quiz/quiz4/quiz4.component';
import { Quiz5Component } from './quiz/quiz5/quiz5.component';

import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { PuzzleComponent } from './games/puzzle/puzzle.component';
import { CardsComponent } from './games/cards/cards.component';
import { AdminRoutingModule } from './admin/admin.routing';
import { PagesRoutingModule } from './pages/pages.routing';

// import { Chapter1Guard } from './guards/chapter1.guard';
import { VerificarEmailComponent } from './pages/verificar-email/verificar-email.component';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { HomeInitialComponent } from './chapters/initial/home-initial/home-initial.component';
import { Chapter2InitialComponent } from './chapters/initial/chapter2/chapter2Initial.component';
import { Chapter3InitialComponent } from './chapters/initial/chapter3/chapter3Initial.component';
import { Chapter4InitialComponent } from './chapters/initial/chapter4/chapter4Initial.component';
import { Chapter5InitialComponent } from './chapters/initial/chapter5/chapter5Initial.component';
import { HomeIntermediateComponent } from './chapters/intermediate/home-intermediate/home-intermediate.component';
import { Chapter1IntermediateComponent } from './chapters/intermediate/chapter1/chapter1intermediate.component';
import { Chapter2IntermediateComponent } from './chapters/intermediate/chapter2/chapter2intermediate.component';
import { Chapter3IntermediateComponent } from './chapters/intermediate/chapter3/chapter3intermediate.component';
import { Chapter4IntermediateComponent } from './chapters/intermediate/chapter4/chapter4intermediate.component';
import { Chapter5IntermediateComponent } from './chapters/intermediate/chapter5/chapter5intermediate.component';
import { HomeAdvancedComponent } from './chapters/advanced/home-advanced/home-advanced.component';
import { Chapter1AdvancedComponent } from './chapters/advanced/chapter1/chapter1Advanced.component';
import { Chapter2AdvancedComponent } from './chapters/advanced/chapter2/chapter2Advanced.component';
import { Chapter3AdvancedComponent } from './chapters/advanced/chapter3/chapter3Advanced.component';
import { Chapter4AdvancedComponent } from './chapters/advanced/chapter4/chapter4Advanced.component';
import { Chapter5AdvancedComponent } from './chapters/advanced/chapter5/chapter5Advanced.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'registro', component: RegisterComponent },
  { path: 'password', component: ResetPasswordComponent },
  { path: 'puzzle', component: PuzzleComponent },
  { path: 'card', component: CardsComponent },
  {
    path: 'inicial',
    children: [
      { path: '', component: HomeInitialComponent },
      { path: 'modulo-1',
        children: [
          { path: '',  component: Chapter1InitialComponent },
          { path: 'quiz', component: Quiz1Component }
        ]
      },
      { path: 'modulo-2',
        children: [
          { path: '',  component: Chapter2InitialComponent },
          { path: 'quiz', component: Quiz2Component }
        ]
      },
      { path: 'modulo-3',
        children: [
          { path: '',  component: Chapter3InitialComponent },
          { path: 'quiz', component: Quiz3Component }
        ]
      },
      { path: 'modulo-4',
        children: [
          { path: '',  component: Chapter4InitialComponent },
          { path: 'quiz', component: Quiz4Component }
        ]
      },
      { path: 'modulo-5',
        children: [
          { path: '',  component: Chapter5InitialComponent },
          { path: 'quiz', component: Quiz5Component }
        ]
      },
    ]
  },
  {
    path: 'intermedio',
    children: [
      { path: '', component: HomeIntermediateComponent },
      { path: 'modulo-1',
        children: [
          { path: '',  component: Chapter1IntermediateComponent },
          { path: 'quiz', component: Quiz1Component }
        ]
      },
      { path: 'modulo-2',
        children: [
          { path: '',  component: Chapter2IntermediateComponent },
          { path: 'quiz', component: Quiz2Component }
        ]
      },
      { path: 'modulo-3',
        children: [
          { path: '',  component: Chapter3IntermediateComponent },
          { path: 'quiz', component: Quiz3Component }
        ]
      },
      { path: 'modulo-4',
        children: [
          { path: '',  component: Chapter4IntermediateComponent },
          { path: 'quiz', component: Quiz4Component }
        ]
      },
      { path: 'modulo-5',
        children: [
          { path: '',  component: Chapter5IntermediateComponent },
          { path: 'quiz', component: Quiz5Component }
        ]
      },
    ]
  },
  {
    path: 'avanzado',
    children: [
      { path: '', component: HomeAdvancedComponent },
      { path: 'modulo-1',
        children: [
          { path: '',  component: Chapter1AdvancedComponent },
          { path: 'quiz', component: Quiz1Component }
        ]
      },
      { path: 'modulo-2',
        children: [
          { path: '',  component: Chapter2AdvancedComponent },
          { path: 'quiz', component: Quiz2Component }
        ]
      },
      { path: 'modulo-3',
        children: [
          { path: '',  component: Chapter3AdvancedComponent },
          { path: 'quiz', component: Quiz3Component }
        ]
      },
      { path: 'modulo-4',
        children: [
          { path: '',  component: Chapter4AdvancedComponent },
          { path: 'quiz', component: Quiz4Component }
        ]
      },
      { path: 'modulo-5',
        children: [
          { path: '',  component: Chapter5AdvancedComponent },
          { path: 'quiz', component: Quiz5Component }
        ]
      },
    ]
  },
  // {
  //   path: 'modulo-1',
  //   children: [
  //     { path: '', component: Chapter1Component },
  //     { path: 'quiz', component: Quiz1Component },
  //   ]
  // },
  // {
  //   path: 'modulo-2',
  //   children: [
  //     { path: '', component: Chapter2Component },
  //     { path: 'quiz', component: Quiz2Component },
  //   ]
  // },
  // {
  //   path: 'modulo-3',
  //   children: [
  //     { path: '', component: Chapter3Component },
  //     { path: 'quiz', component: Quiz3Component },
  //   ]
  // },

  // {
  //   path: 'modulo-4',
  //   children: [
  //     { path: '', component: Chapter4Component },
  //     { path: 'quiz', component: Quiz4Component },
  //   ]
  // },

  // {
  //   path: 'modulo-5',
  //   children: [
  //     { path: '', component: Chapter5Component, },
  //     { path: 'quiz', component: Quiz5Component },
  //   ]
  // },

  { path: 'verificar-email', component: VerificarEmailComponent },

  { path: '**', pathMatch: 'full', component: NopagefoundComponent }

  // { path: 'modulo-2', component: Chapter2Component },
  // { path: 'modulo-3', component: Chapter3Component },
  // { path: 'modulo-4', component: Chapter4Component },
  // { path: 'modulo-5', component: Chapter5Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' }),
    // AdminRoutingModule,
    PagesRoutingModule,
    CommonModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
