<app-header></app-header>

<swiper [config]="config">
	<div class="swiper-wrapper">
		<!-- SLIDE 1 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">¿Qué significa ahorrar?</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center">
						<div class="col-12">
							<h2 class="color-3 pb-5 wow bounceIn" data-wow-delay=".5s">¿Qué significa ahorrar?</h2>
							<p class="wow bounceInDown text-justify" data-wow-delay="1s">Tras revisar los conceptos generales del presupuesto y aprender que a través del mismo  puedes conocer el total de ingresos que percibes mensualmente, así como los gastos que se realizan cada mes y el estimado de dinero que sale hacia diferentes destinos. Pero sobre todo, conoceremos el monto sobrante que puede apartarse y guardarse para ser ahorrado.</p>
							<p class="wow bounceInDown text-justify" data-wow-delay="1s">Elaborar un presupuesto adecuado te permitirá administrar mejor tu dinero e implantar la cultura de ahorro en tu día a día.</p>
              <div class="row align-items-center justify-content-center mb-4">
                <div class="col-4">
                    <div class="wow bounceInDown" data-wow-delay = "1.5s">
                      <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter3/1.webp">
                      <p class="mb-0 bg-3 rounded-2 text-white px-2 m-auto">1. Elaborar Presupuesto</p>
                    </div>
                </div>
                <div class="col-4">
                  <div class="wow bounceInDown" data-wow-delay = "1.5s">
                    <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter3/2.webp">
                    <p class="mb-0 bg-3 rounded-2 text-white px-2 m-auto">2. Ahorrar dinero sobrante</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="wow bounceInDown" data-wow-delay = "1.5s">
                    <img class="max-w-l d-block m-auto" src="assets/images/initial/chapter3/3.webp">
                    <p class="mb-0 bg-3 rounded-2 text-white px-2 m-auto">3. Cumplir metas</p>
                  </div>
                </div>
            </div>
						</div>
					</div>
				</div>

        <div class="row justify-content-center mt-4">
          <div class="col-lg-4">
              <a class="btn bg-3 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s"
                  (click)="activeWow()">SIGUIENTE</a>
          </div>
        </div>

				<div class="row module_progress align-items-center">
					<div class="col">
            <div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 2 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">El Ahorro</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center">
              <div class="col-12">
                  <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">El Ahorro</h2>
              </div>
              <div class="col-12">
                <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1s">
                  <p>Cuando percibimos un ingreso monetario, existen tres opciones: Gastarlo, invertirlo o ahorrarlo.</p>
                </div>
                <div class="video wow bounceInUp" data-wow-delay = "1.5s">
                  <video class="video_screen" id="sample_video" controls="false" controlslist="nodownload">
                      <source src="assets/images/initial/chapter3/ECOFUTURO-EL AHORRO-cambio 2022.mp4" type="video/mp4">
                  </video>
                </div>
              </div>
          </div>
        </div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 20%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:20.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 3 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">El Ahorro</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row justify-content-center content_center text-center">
              <div class="col-xl-11 col-12">
                  <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay="1s">El Ahorro</h2>
                  <div class="row mb-4">
                    <div class="col-4 px-4">
                        <div class="wow bounceInDown align-items-center justify-content-center text-justify" data-wow-delay = "1.5s">
                          <div class="rounded_decoration">
                            <div class="label">Gastar</div>
                            <img class="max-w-l" src="assets/images/initial/chapter3/4.jpg">
                          </div>
                            <p class="mb-0">Cuando predomina el gasto, no existe beneficio financiero, pues el dinero se utiliza y puede que se acabe.</p>
                        </div>
                    </div>
                    <div class="col-4 px-4">
                      <div class="wow bounceInDown align-items-center justify-content-center text-left" data-wow-delay = "1.5s">
                        <div class="rounded_decoration">
                          <div class="label">Invertir</div>
                          <img class="max-w-l" src="assets/images/initial/chapter3/5.png">
                        </div>
                        <p class="mb-0">Al invertirlo, es posible que percibas un beneficio monetario o no.</p>
                      </div>
                    </div>
                    <div class="col-4 px-4">
                      <div class="wow bounceInDown align-items-center justify-content-center text-left" data-wow-delay = "1.5s">
                        <div class="rounded_decoration">
                          <div class="label">Ahorrar</div>
                          <img class="max-w-l" src="assets/images/initial/chapter3/6.jpg">
                        </div>
                        <p class="mb-0">Al ahorrarlo, no hay manera de perder, al contrario, siempre se gana.</p>
                      </div>
                    </div>
                </div>
              </div>
          </div>
      </div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 40%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:40.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 4 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">¿Qué son los gastos silenciosos?</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center">
              <div class="col-12">
                  <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué son los gastos silenciosos?</h2>
                  <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = "1s">Existen cierto tipo de gastos que pueden considerarse como silenciosos o inofensivos al ser mínimos, pero que en realidad pueden resultar perjudiciales para las finanzas personales, siendo importante la capacidad de identificarlos para tener una mejor salud financiera.</p>
                  <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = "1s">Se los conoce mejor bajo el término de “Gasto Hormiga”, “Gastos Fantasma” y “Gasto Vampiro”, a continuación; sus definiciones.</p>
                  <div class="content-select-card">
                      <div class="row align-items-end justify-content-center">
                          <div class="col-lg-4 col-sm-6 col-12">
                              <div class="type-card wow fadeIn" data-wow-delay="1.5s">
                                  <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert()" class="rounded_decoration">
                                    <div class="label">Hormiga</div>
                                    <img class="max-w-l" src="assets/images/initial/chapter3/7.jpg">
                                  </a>
                                  <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                      <p>¡Haz click aquí!</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                              <div class="type-card wow fadeIn" data-wow-delay="1.7s">
                                  <a data-toggle="modal" data-target="#slide2modal" (click)="dismissAlert2()" class="rounded_decoration">
                                    <div class="label">Fantasma</div>
                                    <img class="max-w-l" src="assets/images/initial/chapter3/8.webp">
                                  </a>
                                  <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                      <p>¡Haz click aquí!</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-sm-6 col-12">
                              <div class="type-card wow fadeIn" data-wow-delay="1.9s">
                                  <a data-toggle="modal" data-target="#slide3modal" (click)="dismissAlert3()" class="rounded_decoration">
                                    <div class="label">Vampiro</div>
                                    <img class="max-w-l" src="assets/images/initial/chapter3/9.png">
                                  </a>
                                  <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissThree">
                                      <p>¡Haz click aquí!</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 60%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:60.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 5 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">¿Qué es la Cultura de Ahorro?</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center align-items-start">
              <div class="col-12">
                <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué es la Cultura de Ahorro?</h2>
                <h3 class="color-3 pb-4 wow bounceInUp text-left" data-wow-delay = "1s">Cultura de Ahorro</h3>
                <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = "1s">Es el hábito de separar y reservar una parte del dinero que se recibe o se tiene guardado para hacer una base para lograr diferentes metas u objetivos económicos. Implica una serie de tips o pasos para que se pueda consolidar como un hábito personal y financiero.</p>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left mb-4" data-wow-delay = "1.5s">
                    <div class="img-container">
                      <img src="assets/images/initial/chapter3/10.png">
                    </div>
                    <p class="mb-0">Inicia con tomar conciencia de la necesidad de ahorrar para cumplir objetivos o construir un colchón.</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left mb-4" data-wow-delay = "1.5s">
                    <div class="img-container">
                      <img src="assets/images/initial/chapter3/11.webp">
                    </div>
                    <p class="mb-0">Tener una meta de ahorro ayudará a definir con mayor claridad un camino para lograrlo en un tiempo determinado, presionando al cumplimiento.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left mb-4" data-wow-delay = "1.5s">
                    <div class="img-container">
                      <img src="assets/images/initial/chapter3/12.png">
                    </div>
                    <p class="mb-0">Se recomienda hacer un análisis de ingresos y egresos, para aplicar cambios en los hábitos de consumo y uso de recursos, generando impacto positivo en las finanzas personales.</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1.5s">
                    <div class="img-container">
                      <img src="assets/images/initial/chapter3/13.png">
                    </div>
                    <p class="mb-0">Reconocer los gastos hormiga, ayudará a reducirlos y evitarlos como medida de cuidado del dinero.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1.5s">
                    <div class="img-container">
                      <img src="assets/images/initial/chapter3/14.webp">
                    </div>
                    <p class="mb-0">Destinar un monto determinado al ahorro que se envíe antes de los gastos mensuales, en lugar de enviar un sobrante de dinero al ahorro luego de los gastos.</p>
                  </div>
                </div>
              </div>
          </div>
        </div>

				<div class="row justify-content-center">
					<div class="col-lg-auto">
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay="2s"
							(click)="activeWow()">SIGUIENTE</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 80%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:80.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 6 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Conceptos generales del ahorro</h2>
            </li>
            <li>
                <h2 class="color-3">¿Qué pasa si no tengo una cultura de ahorro?</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center align-items-start">
              <div class="col-12">
                <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué pasa si no tengo una cultura de ahorro?</h2>
                <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = ".5s">Cuando no se tiene la costumbre de ahorrar, la persona no tendrá libertad financiera e incurrirá en un sobre endeudamiento porque sus ingresos no cubren las necesidades u obligaciones, terminando en conformarse un círculo vicioso.</p>
              </div>
              <div class="row wow bounceInDown" style="width: 100%;" data-wow-delay = "1s">
                <div class="col-6">
                  <h3 class="color-3 pb-4 text-left">¿Qué nos impide ahorrar?</h3>
                </div>
                <div class="col-6">
                  <h3 class="color-3 pb-4 text-left">¿Qué podemos hacer para mejorar nuestro ahorro?</h3>
                </div>
              </div>
              <div class="row wow bounceInDown" style="width: 100%;" data-wow-delay = "1s">
                <div class="col-6">
                  <div class="text-left mb-4">
                    <p class="mb-0 p-grid">Muchos incentivos de compra en las redes sociales</p>
                    <p class="mb-0 p-grid">Falta de autocontrol</p>
                    <p class="mb-0 p-grid">Falta de hábito</p>
                    <p class="mb-0 p-grid">Falta de educación financiera</p>
                    <p class="mb-0 p-grid">Falta de confianza en el Sistema Financiero</p>
                    <p class="mb-0 p-grid">Compras Innecesarias</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-left mb-4">
                    <p class="mb-0 p-grid">Pagar los gastos prioritarios al inicio de cada mes</p>
                    <p class="mb-0 p-grid">Aplazar los gastos</p>
                    <p class="mb-0 p-grid">Controlar la salida de dinero de forma diaria</p>
                    <p class="mb-0 p-grid">Reducir gastos hormiga, fantasma o vampiro</p>
                    <p class="mb-0 p-grid">Dividir entre 12 los gastos no mensuales</p>
                    <p class="mb-0 p-grid">Revisar todas las deudas pendientes</p>
                  </div>
                </div>
              </div>
              <div class="row wow bounceInDown" style="width: 100%;" data-wow-delay = "1.5s">
                <div class="col-6">
                  <div class="img-container image-l m-auto">
                    <img class="d-block m-auto" src="assets/images/initial/chapter3/15.png">
                  </div>
                </div>
                <div class="col-6">
                  <div class="img-container image-l m-auto">
                    <img class="d-block m-auto" src="assets/images/initial/chapter3/16.webp">
                  </div>
                </div>
              </div>
          </div>
        </div>

				<div class="row justify-content-center">
					<div class="col-lg-4" style="z-index: 2;">
						<a class="btn bg-3 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a *ngIf="this.finalModule == false"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
						<a *ngIf="this.finalModule == true"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							(click)="goToHome()">IR AL INICIO</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>
	</div>
	<!-- <div class="swiper-pagination"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-3">Definición:</h4>
						<p class="text-justify">Las compras no planificadas que se realizan en el día a día y no parecen ser importantes ni de  gran magnitud.</p>
						<h4 class="color-3">Ejemplo:</h4>
						<p class="text-justify">El sándwich de media mañana, comer fuera de casa por impulso, un paquete de chicles, un refresco, etc.</p>
						<a class="btn bg-3 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-3">Definición:</h4>
						<p class="text-justify">Son gastos en los que incurrimos sin darnos cuenta y afectan al presupuesto.</p>
						<h4 class="color-3">Ejemplo:</h4>
						<p class="text-justify">Suscripciones a más de 1 plataforma para escuchar música, pagos de aplicaciones del celular o incluso plataformas de series y películas.</p>
						<a class="btn bg-3 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-3" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-3">Definición:</h4>
						<p class="text-justify">Son aquellos gastos que, a pesar de ser fijos, no se detectan fácilmente porque no están considerados en el presupuesto y suelen tener un impacto mayor 	en comparación con los anteriores.</p>
						<h4 class="color-3">Ejemplo:</h4>
						<p class="text-justify">Televisión por cable, teléfono fijo, fugas de gas o agua, etc.</p>
						<a class="btn bg-3 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-4" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-4">Crédito de Vivienda:</h4>
						<p class="text-justify">Crédito otorgado a una persona natural, destinado a la adquisición de un
							terreno, compra o construcción de una vivienda, o para la ampliación, refacción y/o
							mejoramiento de una vivienda.</p>
						<a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-5" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="color-5">Crédito de Consumo:</h4>
						<p class="text-justify">Crédito concedido a una persona natural, con el objeto de financiar la
							adquisición de bienes de consumo o el pago de servicios, cuya fuente principal de pago es el
							salario de la persona o ingresos provenientes de actividades independientes, adecuadamente
							verificados.</p>
						<a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
	aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title text-white">¡FELICIDADES!</h1>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body bg-module-completed">
				<div class="container-fluid center">
					<div class="row justify-content-center mb-4">
						<div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
							<img class="img-1 w-75" src="assets/images/icons/initial/3.png">
							<h2 class="color-3 mt-2">COMPLETASTE EL MÓDULO 3</h2>
						</div>
					</div>
					<div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
						<div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
							<img class="img-2" src="assets/images/module-3/quiz.svg">
						</div>
						<div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
							<h4 class="color-3">Completa el QUIZ
                <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                <a routerLink="/inicial/modulo-4" data-dismiss="modal">aquí</a>
              </h4>
							<!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
						</div>
						<!-- <div class="col-9 text-center mt-2">
							<p><small>No te olvides que por cada <strong class="color-3">módulo que termines,</strong>
									obtienes un cupón más, es decir, <strong class="color-3">una oportunidad más de
										ganar.</strong></small></p>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
