import { Component, OnInit } from '@angular/core';

import { SwiperOptions } from 'swiper';
import { NgwWowService } from 'ngx-wow';
import { Router } from '@angular/router';
declare function stopVideo();

@Component({
  selector: 'app-chapter1intermediate',
  templateUrl: './chapter1intermediate.component.html',
  styleUrls: ['./chapter1intermediate.component.scss']
})
export class Chapter1IntermediateComponent implements OnInit {
  public finalModule;
  config: SwiperOptions = {
    mousewheel: false,
    cssMode: false,
    allowTouchMove: false,
    autoHeight: true,
    slidesPerView: 1,
    initialSlide: ( typeof history.state[0] != 'undefined' ) ? 12 : 0,
    effect: 'coverflow',
    navigation: {
      nextEl: '.swiper_next',
      prevEl: '.swiper_prev'
    },
  };
  constructor( private wowService: NgwWowService, private router: Router) {
    this.activeWow();
    // this.wowService.init();
  }

  // back;

  ngOnInit(): void {
    window.scroll(0,0);
    if( localStorage.getItem('email') == null ) {
      this.router.navigateByUrl('/inicio');
    }
    this.finalModule = localStorage.getItem('finish-1') == 'final' ? true : false;
  }

  getBrowserInfo() {
    var ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
  }

  activeWow() {
    var browserInfo = this.getBrowserInfo();
    if( browserInfo != "Chrome 4" && browserInfo != "Safari 15" && browserInfo != "Safari 14" && browserInfo != "Safari 13" && browserInfo != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
      stopVideo();
    }
  }

  stopSecuence() {
    stopVideo();
  }

  goToHome() {
    this.router.navigateByUrl('/inicio');
  }
}
