<app-header></app-header>
<section class="section pt-2 pb-0">
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-4 text-center">
            <a (click)="volverAtras()" class="btn main_btn bg-3 text-white wow bounceIn px-4">Volver al Módulo 3</a>
        </div>
    </div>
</section>
<section class="section py-5">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-8 form_box">
                <div class="chances text-center">
                    <h1>3/5</h1>
                    <p><small>QUIZ</small></p>
                </div>
                <h1 class="color-3 text-center">Módulo Tres</h1>
                <!-- <p>Completa este QUIZ para obtener un cupón para el sorteo y recién continuar con el siguiente módulo.</p> -->
                <p class="py-3 text-justify">Pon a prueba tu aprendizaje y responde las preguntas del siguiente QUIZ o prueba. Al concluir cada módulo, hay un QUIZ y por cada uno de ellos que concluyas satisfactoriamente obtienes un cupón para participar del sorteo de premios que realizará el Banco a la conclusión de la campaña o promoción empresarial.</p>

                <h4 class="color-3 mt-4">Selecciona las respuestas correctas:</h4>

                <form class="quiz_form" [formGroup]="quiz3Form" (ngSubmit)="completarQuiz3(quiz3Form.value)">
                    <div *ngFor="let question of questions; let i = index">
                        <h5 class="color-3 my-4">{{ i + 1 }}. {{ question.questionEvalute }}</h5>
                        <p class="text-justify font-italic">{{ question.questionDetail }}</p>
                        <div *ngIf="question.fieldType == 'RADIO_BUTTON'">
                            <div class="form-check mb-2">
                                <div class="form-check mb-2" style="display: flex;" *ngFor="let answer of question.answers; let j = index">
                                    <input id="answer-{{ answer.idAnswer }}" class="form-check-input" formControlName="question{{ i + 1 }}" type="radio" value="{{ answer.idAnswer }}">
                                    <label class="form-check-label" for="answer-{{ answer.idAnswer }}">{{ answer.detailAnswer }}</label>
                                    <div id="check-answer-{{ answer.idAnswer }}"></div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="question.fieldType == 'CHECK_BOX'">
                            <div class="form-check mb-2" style="display: flex;" *ngFor="let answer of question.answers; let j = index">
                                <input id="answer-{{ answer.idAnswer }}" class="form-check-input" formControlName="question{{ i + 1 }}" type="radio" value="{{ answer.idAnswer }}">
                                <div id="check-answer-{{ answer.idAnswer }}"></div>
                                <label class="form-check-label" for="answer-{{ answer.idAnswer }}">{{ answer.detailAnswer }}</label>
                            </div>
                        </div>
                    </div>
                    <div id="empty-quiz" style="display:none;">
                        <p class="color-error pt-2 validator"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Por favor responda a todas las preguntas.</p>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <button *ngIf="this.modulefinished == false" type="submit" class="btn main_btn bg-3 text-white">Enviar mis respuestas</button>
                        <a *ngIf="this.modulefinished == true" routerLink="/inicio" class="btn main_btn bg-2 text-white">Ir al inicio</a>
                    </div>

                </form>
                <button id="btn-quiz-3" [hidden]="true" data-toggle="modal" data-target=".module-completed" class="btn main_btn bg-3 text-white">Click</button>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>

<!-- CONGRATULATIONS QUIZ-->
<div id="modal-quiz-3" class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button (click)="redirectHome()" type="button" class="close" data-dismiss="modal" aria-label="Close" id="modal-close-3">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 mb-4 w-60" src="assets/images/module-3/quiz-completed.svg">
                            <h2 class="color-1 mt-2">COMPLETASTE EL QUIZ CON ÉXITO</h2>
                            <p>El cupón para participar del sorteo ha sido enviado automáticamente a tu correo. ¡Verifícalo!</p>
                            <!-- <a class="btn bg-1 text-white px-5 mt-3" data-dismiss="modal" routerLink="/inicio"><strong>Ir al Inicio</strong></a> -->
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <!-- <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/elements/mail.svg">
                        </div> -->
                        <div class="col-lg-10 col-md-7 col-sm-7 col-12 text-center">
                            <!-- <h5 class="color-1">Revisa tu correo para obtener tu código de participación</h5> -->
                            <p><small>No te olvides que por cada módulo que termines, obtienes un cupón más, es decir, una oportunidad más de ganar.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-circles></app-circles> -->

<app-certificate [nombreCompleto]="this.client.nameComplete"></app-certificate>