<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li>
                            <h2 class="color-4">Instrumentos Electrónicos de Pago</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center justify-content-center">
                        <div class="col-lg-10">
                            <h2 class="color-4 pb-4 wow fadeIn">¿Qué es un Instrumento Electrónico de Pago (IEP)?</h2>
                        </div>
                        <div class="col-lg-4">
                            <img class="wow zoomIn" data-wow-delay="0.5s" src="assets/images/module-4/1.svg" alt="">
                        </div>
                        <div class="col-lg-8 text-left wow fadeIn" data-wow-delay="1s">
                            <p>1) Tarjetas Electrónicas</p>
                            <p>2) Órdenes Electrónicas de Transferencia de Fondos (OETF)</p>
                            <p>3) Billetera Móvil</p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-4 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 2.667%;" aria-valuenow="2.667"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">Instrumentos Electrónicos de Pago</h2>
                        </li>
                        <li>
                            <h2 class="color-4">Tarjetas Electrónicas</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-4 wow fadeIn">1) Tarjetas Electrónicas</h2>
                            <br>
                            <div class="content-select-card">
                                <div class="row" style="justify-content: center;">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1s">
                                            <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert()">
                                                <img src="assets/images/module-4/step-2-1.svg" alt="">
                                            </a>
                                            <ul class="module_list type_2 wow fadeIn" >
                                                <li>
                                                    <h5>TARJETA DE DÉBITO</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.2s">
                                            <a data-toggle="modal" data-target="#slide2modal" (click)="dismissAlert2()">
                                                <img src="assets/images/module-4/step-2-2.svg" alt="">
                                            </a>
                                            <ul class="module_list type_2" >
                                                <li>
                                                    <h5>TARJETA DE CRÉDITO</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.4s">
                                            <a data-toggle="modal" data-target="#slide3modal" (click)="dismissAlert3()">
                                                <img  src="assets/images/module-4/step-2-3.svg" alt="">
                                            </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h5>TARJETA PREPAGADA</h5>
                                                </li>
                                            </ul>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissThree">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 15.334%;" aria-valuenow="15.334"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">Instrumentos Electrónicos de Pago</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Tarjetas Electrónicas</h2>
                        </li>
                        <li>
                            <h2 class="color-4">OETF</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-2 wow fadeIn">2) Órdenes Electrónicas<br>de Transferencia de Fondos (OETF)</h2>
                            <p class="text-justify wow fadeInUp" data-wow-delay="1s">Son instrucciones mediante las cuales se asigna y/o transfiere fondos a un beneficiario usando distintos medios como ser: Banca Electrónica y Cajeros Automáticos principalmente.</p>
                        </div>
                        <div class="col-12">
                            <div class="row content-mb-ff">
                                <div class="col-lg-6 col-md-8 col wow slideInRight" data-wow-delay="1.4s">
                                    <div class="type-card type_2">
                                        <a style="display: contents" data-toggle="modal" data-target="#slide4modal" (click)="dismissAlert4()">
                                            <img class="w-25" src="assets/images/module-4/step-3-1.svg" alt="">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5>BANCA ELECTRÓNICA</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" style="position: unset; top: 0; left: 0;" data-wow-iteration="infinite" *ngIf="dismissFour">
                                        <p>¡Haz click aquí!</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-8 col wow slideInRight" data-wow-delay="1.8s">
                                    <div class="type-card type_2">
                                        <a style="display: contents" data-toggle="modal" data-target="#slide5modal" (click)="dismissAlert5()">
                                            <img class="w-25" src="assets/images/module-4/cajero.svg" alt="">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5>CAJERO AUTOMÁTICO</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" style="position: unset; top: 0; left: 0;" data-wow-iteration="infinite" *ngIf="dismissFive">
                                        <p>¡Haz click aquí!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 35.001%;" aria-valuenow="35.001"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">Instrumentos Electrónicos de Pago</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Tarjetas Electrónicas</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">OETF</h2>
                        </li>
                        <li>
                            <h2 class="color-4">Billetera Móvil</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-2 wow fadeIn">3) Billetera Móvil</h2>
                            <p class="text-justify wow fadeInUp" data-wow-delay="1s">Es un servicio otorgado a través de una cuenta de pago ligada a una línea celular, medio por el cual el consumidor financiero puede efectuar transacciones financieras, de forma ágil y segura a cualquier hora, los 365 días del año. Permite realizar una carga de dinero electrónico, enviar dinero, efectivizar (convertir dinero electrónico a dinero tangible), consultar saldo y solicitar mini extracto.</p>
                        </div>
                        <div class="col-12">
                            <div class="row content-mb-ff justify-content-center">
                                <div class="col-lg-6 col-md-8 col-12 wow slideInRight" data-wow-delay="1.4s">
                                    <div class="type-card type_2">
                                        <a style="display: contents" data-toggle="modal" data-target="#slide7modal" (click)="dismissAlert6()">
                                            <img class="w-25" src="assets/images/module-4/2.svg" alt="">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5>BILLETERA MÓVIL</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" style="position: unset; top: 0; left: 0;" data-wow-iteration="infinite" *ngIf="dismissSix">
                                        <p>¡Haz click aquí!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 50.001%;" aria-valuenow="50.001"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">Instrumentos Electrónicos de Pago</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Tarjetas Electrónicas</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">OETF</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Billetera Móvil</h2>
                        </li>
                        <li>
                            <h2 class="color-4">Beneficios</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-4 wow fadeIn">¿Cuáles son los beneficios de utilizar<br>un Instrumento Electrónico de Pago?</h2>
                            <div class="video wow bounceInUp" data-wow-delay = "1s">
                                <video class="video_screen" id="normativa_video" controls="false" controlslist="nodownload">
                                    <source src="https://ecofuturo.s3.us-east-1.amazonaws.com/videos/00_ECOFUTURO_BENEFICIOS%20INSTRUMENTOS%20DE%20PAGO%20V2.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 70.668%;" aria-valuenow="70.668"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 6 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">Tarjetas Electrónicas</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">OETF</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Billetera Móvil</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Beneficios</h2>
                        </li>
                        <li>
                            <h2 class="color-4">Instrumentos del Banco</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-4 wow fadeIn">Instrumentos Electrónicos de Pago<br>que ofrece el Banco</h2>
                            <!-- <p class="wow fadeInUp" data-wow-delay="1s">Banco <span class="versalitas">pyme</span> Ecofuturo pone a tu disposición los siguientes instrumentos electrónicos de pago:</p> -->
                        </div>
                        <div class="col-12">
                            <div class="row" style="justify-content: center;">
                                <div class="col-lg-4 col-md-6">
                                    <div class="type-card type_3 wow slideInRight" data-wow-delay="1.2s">
                                        <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert7()">
                                            <img src="assets/images/module-4/step-5-1.svg" alt="" width="100%">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5>TARJETA DE DÉBITO</h5>
                                            </li>
                                        </ul>
                                        <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissSeven">
                                            <p>¡Haz click aquí!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="type-card type_3 wow slideInRight" data-wow-delay="1.4s">
                                        <a (click)="dismissAlert8()">
                                            <img src="assets/images/module-4/step-5-2.svg" alt="" width="100%">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5>TARJETA DE CRÉDITO</h5>
                                            </li>
                                        </ul>
                                        <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissEight">
                                            <p>¡Haz click aquí!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="type-card type_3 wow slideInRight" data-wow-delay="1.6s">
                                        <a data-toggle="modal" data-target="#slide6modal" (click)="dismissAlert9()">
                                            <img src="assets/images/module-4/step-5-3.svg" alt="" width="100%">
                                        </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h5 class="except_h5">OETF<small>ÓRDENES ELECTRÓNICAS DE TRANSFERENCIA DE FONDOS</small></h5>
                                            </li>
                                        </ul>
                                        <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissNine">
                                            <p>¡Haz click aquí!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 86.335%;" aria-valuenow="86.335"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 7 -->
        <div class="swiper-slide">
            <section class="module">

                <div class="px-5 py-3 module_header">
                    <ol class="justify-content-start">
                        <li class="light-text">
                            <h2 class="color-4">...</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">OETF</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Beneficios</h2>
                        </li>
                        <li class="light-text">
                            <h2 class="color-4">Instrumentos del Banco</h2>
                        </li>
                        <li>
                            <h2 class="color-4">Seguridad</h2>
                        </li>
                    </ol>
                </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-4 pb-4 wow fadeIn">Medidas de seguridad<br>para Instrumentos Electrónicos de Pago</h2>
                            <div class="video wow bounceInUp" data-wow-delay = "1s">
                                <video class="video_screen" id="normativa_video" controls="false" controlslist="nodownload">
                                    <source src="https://ecofuturo.s3.us-east-1.amazonaws.com/videos/00_ECOFUTURO_MEDIDAS%20DE%20SEGURIDAD_MEDIOS%20DE%20PAGO%20FINAL%2021%20DIC.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-4 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s" (click)="activeWow()">ANTERIOR</a>
                        <a *ngIf="this.finalModule == false" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" data-toggle="modal" data-target=".module-completed" (click)="activeWow()">FINALIZAR</a>
                        <a *ngIf="this.finalModule == true" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-4" role="progressbar" style="width: 100%;" aria-valuenow="100"
                            aria-valuemin="0" aria-valuemax="100">
                                <img class="icon_progress" src="assets/images/module-4/flechas.svg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE DÉBITO</h4>
                        <p>Es un IEP que se le otorga al cliente, de forma gratuita, cuando obtiene una cuenta de ahorro o una cuenta corriente. Con ella se pueden realizar pagos, retiros en efectivo, rescate de cuotas y consultar los movimientos de la cuenta bancaria. El dinero es debitado del saldo de la cuenta asociada. </p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE CRÉDITO</h4>
                        <p>Es un IEP que dispone el cliente al cual el Banco ha otorgado un límite de crédito en respuesta a su solicitud. A través de esta tarjeta se pueden realizar compras o extraer efectivo hasta el límite aprobado. A diferencia de la tarjeta de débito, en vez de debitarse de la cuenta, se utiliza un monto del dinero prestado, mismo que se debe pagar de forma periódica con intereses.  Por esta razón, antes de conceder una tarjeta de crédito, el banco estudia la viabilidad del cliente, asegurándose de que sea solvente.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-4">TARJETA PREPAGADA</h4>
                        <p>Es un IEP que dispone de un monto de dinero almacenado, pagado previamente a su emisor. Con ella se realizan pagos hasta el valor del importe cargado.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-3-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">BANCA ELECTRÓNICA</h4>
                        <p>Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de un dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <p>La afiliación al servicio es gratuita y difiere de entidad a entidad. Consulta con el banco de tu preferencia para averiguar los requisitos.</p>
                        <p>Se pueden realizar distintas acciones:</p>
                        <ul>
                            <li>Consultar Saldos y movimientos</li>
                            <li>Transferencias entre cuentas propias, a cuentas de terceros o interbancarias, es decir a otros bancos.</li>
                            <li>Pago de Préstamos</li>
                            <li>Pago de servicios</li>
                            <li>Solicitar ayuda</li>
                            <li>Información sobre los Puntos de Atención</li>
                        </ul>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/cajero.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">CAJERO AUTOMÁTICO:</h4>
                        <p>Un Cajero Automático es un ordenador, una máquina, empleada por una entidad financiera que permite realizar operaciones financieras, entre las que destacan el ingreso o retiro de dinero en efectivo, sin la necesidad de la presencia de un empleado de un banco.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-5 col-12">
                        <img src="assets/images/module-4/step-5-3.svg" width="80%">
                    </div>
                    <div class="col-lg-7 col-12 text-left">
                        <h3 class="color-4">ÓRDENES ELECTRÓNICAS DE TRANSFERENCIA DE FONDOS</h3>
                    </div>
                    <div class="col-lg-11 col-12">
                        <h4 class="color-4">BANCA MÓVIL:</h4>
                        <p class="text-justify">Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <h4 class="color-4">ECONET:</h4>
                        <p class="text-justify">EcoNet, es una plataforma que proveé un servicio digital a los clientes, a través de una aplicación o de la Página Web del Banco, con la cual pueden realizar operaciones bancarias de forma ágil, sencilla y segura.</p>
                        <p class="text-justify">EcoNet, además de las transacciones generales previamente mencionadas, tiene disponible el P2P (elemento innovador del Banco a través del cual se pueden hacer transferencias entre cuentas utilizando únicamente el número del teléfono móvil del receptor) y finalmente, SIMPLE (que permite realizar operaciones bancarias a través del uso de un código QR).</p>
                        <div class="center text-center mt-3">
                            <a class="btn bg-3 text-white px-4" href="https://www.bancoecofuturo.com.bo/sitio/canales-contenido/69" target="_blank"><strong>HAZ CLICK AQUÍ</strong></a>
                            <p class="mt-1">Si requieres más información sobre EcoNet.</p>
                        </div>
                        <a class="btn bg-4 text-white px-5 mt-4 text-right" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-7 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide7modal" tabindex="-1" role="dialog" aria-labelledby="slide7-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">Billetera Móvil:</h4>
                        <p>El servicio de Billetera Móvil para transferir recursos desde un celular es prestado por diferentes empresas de telecomunicaciones, como ser Entel, etc. Este aspecto debe ser consultado con el Banco que ofrezca el servicio de Billetera Móvil.</p>
                        <p>Se trata de un servicio cómodo, ágil y rápido, que no requiere un saldo mínimo de apertura, cuenta con un costo de las transacciones accesible y en algunos casos gratuito y está disponible para cualquier persona.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/module-4/module-completed.svg">
                            <h2 class="color-4 mt-2">COMPLETASTE EL MÓDULO 4</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module-4/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-4">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/intermedio/modulo-5" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-4">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-4">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
