<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
      <!-- SLIDE 1 -->
      <div class="swiper-slide">
        <section class="module">

            <div class="px-5 py-3 module_header">
              <ol class="justify-content-start">
                <li>
                    <h2 class="color-5">Puntos de Atención Financiera</h2>
                </li>
              </ol>
            </div>

            <div class="">
                <div class="module_body row content_center vh-resize text-center">
                    <div class="col-12">
                        <h2 class="color-5 mt-4 pb-4 wow fadeIn">Puntos de Atención Financiera</h2>
                        <div class="row justify-content-center">
                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                                <img class="w-100 wow fadeIn" data-wow-delay="1s" src="assets/images/module-5/banco.svg">
                                <a class="btn bg-5 text-white mt-3 py-2 px-4 wow fadeIn" data-wow-delay="2s" href="https://www.bancoecofuturo.com.bo/sitio/canales-puntos-de-atencion" target="_blank"><strong>HAZ CLICK AQUÍ</strong></a>
                                <p class="mt-3 wow fadeIn" data-wow-delay="2s">Para ver los PAF´s que tiene <br><span class="bank-name">Banco <small class="versalitas">PYME</small> Ecofuturo</span> </p>
                            </div>
                            <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12">
                                <p class="text-justify wow fadeInUp" data-wow-delay="1.2s">Según la Recopilación de la Normativa de la ASFI, un PAF o Punto de Atención Financiera es un espacio físico habilitado por una entidad supervisada, que cuenta con las condiciones necesarias para realizar operaciones de intermediación financiera o servicios financieros complementarios, según corresponda.</p>
                                <p class="text-justify wow fadeInUp" data-wow-delay="1.4s">Existen 11 tipos de PAF para que una entidad supervisada realice sus operaciones o preste servicios:</p>
                                <div class="row justify-content-end">
                                  <div class="col-lg-4 col-12 text-left wow slideInRight" data-wow-delay="1.8s">
                                    <p class="mb-1"><strong class="color-5 ">1)</strong> Agencia Fija</p>
                                    <p class="mb-1"><strong class="color-5 ">2)</strong> Agencia Móvil</p>
                                    <p class="mb-1"><strong class="color-5 ">3)</strong> Cajero Automático</p>
                                    <p class="mb-1"><strong class="color-5 ">4)</strong> Local Compartido</p>
                                    <p class="mb-1"><strong class="color-5 ">5)</strong> Oficina Central</p>
                                    <p class="mb-1"><strong class="color-5 ">6)</strong> Oficina Externa</p>
                                  </div>
                                  <div class="col-lg-6 col-12 text-left wow slideInRight" data-wow-delay="2.2s">
                                    <p class="mb-1"><strong class="color-5">7)</strong> Oficina Ferial</p>
                                    <p class="mb-1"><strong class="color-5">8)</strong> Punto Corresponsal Financiero</p>
                                    <p class="mb-1"><strong class="color-5">9)</strong> Punto Corresponsal No Financiero</p>
                                    <p class="mb-1"><strong class="color-5">10)</strong> Sucursal</p>
                                    <p class="mb-1"><strong class="color-5">11)</strong> Ventanilla de Cobranza</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <!-- <div class="col-12">
                            <span class="p-small color-3 wow fadeIn" data-wow-delay="1.6s"><small>Para ver los Puntos de Atención (PAF´s) que tiene Banco PyME Ecofuturo a nivel nacional, ingresa al siguiente link:<br><a class="color-3" href="https://www.bancoecofuturo.com.bo/sitio/canales-puntos-de-atencion">:https://www.bancoecofuturo.com.bo/sitio/canales-puntos-de-atencion</a></small></span>
                          </div> -->
                          <div class="col-12">

                          </div>
                </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-4" style="z-index: 2;">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar bg-5" role="progressbar" style="width: 3%;" aria-valuenow="2"
                            aria-valuemin="0" aria-valuemax="100">
                            <img class="icon_progress" src="assets/images/module-5/map-marker.svg">
                        </div>
                    </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg">
                </div>
            </div>
            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 2 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header">
            <ol class="justify-content-start">
              <li>
                  <h2 class="color-5">Puntos de Atención Financiera</h2>
              </li>
            </ol>
          </div>

            <div class="container module_body">
                <div class="row content_center vh-resize-2 text-center">
                    <div class="col-12">
                        <br>
                        <h2 class="color-5 pb-2 wow fadeIn">Explicamos a continuación los más relevantes</h2>
                        <div class="content-select-card">
                            <div class="row justify-content-center">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="1s">
                                    <div class="type-card">
                                      <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert()">
                                        <img src="assets/images/module-5/step-2-1-1.svg" alt="">
                                      </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h6>Agencia Fija (AFs)</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                      <p>¡Haz click aquí!</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="1.2s">
                                    <div class="type-card">
                                      <a data-toggle="modal" data-target="#slide3modal" (click)="dismissAlert2()">
                                        <img src="assets/images/module-5/step-2-1-2.svg" alt="">
                                      </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h6>Cajero Automático (CA)</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                      <p>¡Haz click aquí!</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="1.4s">
                                    <div class="type-card">
                                      <a data-toggle="modal" data-target="#slide4modal" (click)="dismissAlert3()">
                                        <img src="assets/images/module-5/step-2-1-3.svg" alt="">
                                      </a>
                                        <ul class="module_list type_2">
                                            <li>
                                                <h6>Oficina Central (OC)</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissThree">
                                      <p>¡Haz click aquí!</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="1.6s">
                                  <div class="type-card">
                                    <a data-toggle="modal" data-target="#slide5modal" (click)="dismissAlert4()">
                                      <img src="assets/images/module-5/step-2-1-4.svg" alt="">
                                    </a>
                                      <ul class="module_list type_2">
                                          <li>
                                              <h6>Oficina Externa (OE)</h6>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissFour">
                                    <p>¡Haz click aquí!</p>
                                  </div>
                                </div>
                                <div class="col-xl-10 col-12">
                                  <div class="row">
                                    <div class="col-xl-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="1.8s">
                                        <div class="type-card">
                                          <a data-toggle="modal" data-target="#slide2modal" (click)="dismissAlert5()">
                                            <img src="assets/images/module-5/step-2-2-1.svg" alt="">
                                          </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h6>Agencia Móvil (AM)</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissFive">
                                          <p>¡Haz click aquí!</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="2s">
                                        <div class="type-card">
                                          <a data-toggle="modal" data-target="#slide6modal" (click)="dismissAlert6()">
                                            <img src="assets/images/module-5/step-2-2-2.svg" alt="">
                                          </a>
                                            <ul class="module_list type_2">
                                                <li>
                                                    <h6>Sucursal (SU)</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissSix">
                                          <p>¡Haz click aquí!</p>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="2.2s">
                                      <div class="type-card">
                                        <a data-toggle="modal" data-target="#slide7modal" (click)="dismissAlert7()">
                                          <img src="assets/images/module-5/step-2-2-3.svg" alt="">
                                        </a>
                                        <ul class="module_list type_2">
                                          <li>
                                            <h6>Ventanilla de Cobranza (VC)</h6>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissSeven">
                                        <p>¡Haz click aquí!</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="col-12">
                                  <a class="btn bg-5 text-white mt-4 py-2 px-4 wow fadeIn" data-wow-delay="1s" href="https://servdmzw.asfi.gob.bo/circular/Textos/L01T03.pdf" target="_blank"><strong>HAZ CLICK AQUÍ</strong></a>
                                  <p class="mt-2 px-0 wow fadeIn" data-wow-delay="1s">para conocer la definición de los demás PAF´s</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center" style="margin-top: 3.5% !important;">
              <div class="col-lg-4">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                  <a class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s" (click)="activeWow()">SIGUIENTE</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar bg-5" role="progressbar" style="width: 25%;" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100">
                            <img class="icon_progress" src="assets/images/module-5/map-marker.svg">
                        </div>
                    </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 3 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header">
            <ol class="justify-content-start">
              <li class="light-text">
                <h2 class="color-5">Puntos de Atención Financiera</h2>
              </li>
              <li>
                <h2 class="color-5">Uso del Cajero Automático</h2>
              </li>
            </ol>
          </div>

          <div class="container module_body">
            <div class="row align-items-center content_center vh-resize text-center">
              <div class="col-12">
                <h2 class="color-5 py-3 wow fadeIn">Uso del Cajero Automático</h2>
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6 col-12">
                    <img class="wow wobble w-75" data-wow-iteration="infinite" data-wow-duration="3s"  data-wow-delay="1s" src="assets/images/module-5/cajero.svg" alt="">
                  </div>
                  <div class="col-lg-8 col-md-6 col-12">
                    <br>
                    <p class="text-justify wow slideInRight" data-wow-delay="1.5s">Un cajero automático es un ordenador, una máquina, empleada por una entidad financiera que permite realizar operaciones financieras, entre las que destacan el ingreso o retiro de dinero en efectivo, sin la necesidad de la presencia de un empleado de un Banco.</p>
                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-6 col-12 left-align pointer wow fadeIn" data-wow-delay="1.8s" data-toggle="modal" data-target="#slide8modal" (click)="viewbubble1()">
                        <ul class="module_list type_2">
                          <li>
                              <h6>Requieres de tu tarjeta</h6>
                          </li>
                        </ul>
                        <i class="fa fa-hand-pointer-o help_pointer wow pulse" aria-hidden="true" data-wow-iteration="infinite" *ngIf="bubble1"></i>
                      </div>

                      <div class="col-lg-6 col-md-12 col-sm-6 col-12 left-align pointer wow fadeIn" data-wow-delay="2s" data-toggle="modal" data-target="#slide11modal" (click)="viewbubble2()">
                        <ul class="module_list type_2">
                          <li>
                              <h6>Costo de transacción en el exterior</h6>
                          </li>
                        </ul>
                        <i class="fa fa-hand-pointer-o help_pointer wow pulse" aria-hidden="true" data-wow-iteration="infinite" *ngIf="bubble2"></i>
                      </div>

                      <div class="col-lg-6 col-md-12 col-sm-6 col-12 left-align pointer wow fadeIn" data-wow-delay="2.2s" data-toggle="modal" data-target="#slide10modal" (click)="viewbubble3()">
                        <ul class="module_list type_2">
                          <li>
                              <h6>Límite de retiro diario</h6>
                          </li>
                        </ul>
                        <i class="fa fa-hand-pointer-o help_pointer wow pulse" aria-hidden="true" data-wow-iteration="infinite" *ngIf="bubble3"></i>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-6 col-12 left-align pointer wow fadeIn" data-wow-delay="2.4s" data-toggle="modal" data-target="#slide9modal" (click)="viewbubble4()">
                        <ul class="module_list type_2">
                          <li>
                              <h6>Servicio 24/7</h6>
                          </li>
                        </ul>
                        <i class="fa fa-hand-pointer-o help_pointer wow pulse" aria-hidden="true" data-wow-iteration="infinite" *ngIf="bubble4"></i>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-6 col-12 left-align pointer wow fadeIn" data-wow-delay="2.6s" data-toggle="modal" data-target="#slide12modal" (click)="viewbubble5()">
                        <ul class="module_list type_2">
                          <li>
                              <h6>Transacciones disponibles</h6>
                          </li>
                        </ul>
                        <i class="fa fa-hand-pointer-o help_pointer wow pulse" aria-hidden="true" data-wow-iteration="infinite" *ngIf="bubble5"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-controls ngClass="chapter5"></app-controls>

          <div class="row module_progress align-items-center">
            <div class="col">
              <div class="progress">
                <div class="progress-bar bg-5" role="progressbar" style="width: 50%;" aria-valuenow="50"
                aria-valuemin="0" aria-valuemax="100">
                  <img class="icon_progress" src="assets/images/module-5/map-marker.svg">
                </div>
              </div>
            </div>
            <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
              <img src="assets/images/module-5/map.svg" class="doc-icon">
            </div>
          </div>

          <div class="content-elements">
            <app-circles></app-circles>
          </div>

        </section>
      </div>

      <!-- SLIDE 4 -->
      <div class="swiper-slide">
        <section class="module">

            <div class="px-5 py-3 module_header">
                <ol class="justify-content-start">
                    <li class="light-text">
                        <h2 class="color-5">Puntos de Atención Financiera</h2>
                    </li>
                    <li>
                        <h2 class="color-5">Uso del Cajero Automático</h2>
                    </li>
                </ol>
            </div>

            <div class="container module_body">
                <div class="row content_center vh-resize text-center">
                    <div class="col-12">
                        <h2 class="color-5 pb-4 wow fadeIn">¿Cómo retirar dinero del Cajero Automático?</h2>
                        <div class="video wow bounceInUp" data-wow-delay = "1s">
							<video class="video_screen" id="normativa_video" controls="false" controlslist="nodownload">
                <!-- <source src="https://ecofuturo.s3.us-east-1.amazonaws.com/videos/00_ECOFUTURO_USO%20DE%20CAJEROS%20AUTOMATICOS%20ACTUALIZADO%20FINAL.mp4" type="video/mp4"> -->
                <source src="https://ecofuturo.s3.amazonaws.com/videos/00_ECOFUTURO_USO%20DE%20CAJEROS%20AUTOMATICOS%20ACTUALIZADO%2031%20dic.mp4" type="video/mp4">
							</video>
                      </div>
                    </div>
                </div>
            </div>

            <app-controls ngClass="chapter5"></app-controls>

            <div class="row module_progress align-items-center">
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar bg-5" role="progressbar" style="width: 75%;" aria-valuenow="75"
                        aria-valuemin="0" aria-valuemax="100">
                            <img class="icon_progress" src="assets/images/module-5/map-marker.svg">
                        </div>
                    </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 5 -->
      <div class="swiper-slide">
        <section class="module">

            <div class="px-5 py-3 module_header">
                <ol class="justify-content-start">
                    <li class="light-text">
                      <h2 class="color-5">Puntos de Atención Financiera</h2>
                    </li>
                    <li class="light-text">
                      <h2 class="color-5">Uso del Cajero Automático</h2>
                    </li>
                    <li>
                      <h2 class="color-5">Recomendaciones</h2>
                    </li>
                </ol>
            </div>

            <div class="container module_body">
                <div class="row content_center vh-resize-3 text-center">
                    <div class="col-12">
                        <h2 class="color-5 pb-4 wow fadeIn">Recomendaciones</h2>
                        <div class="row">
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="0.6s">
                            <img src="assets/images/module-5/step-5-1.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>Puedes cambiar el PIN de la tarjeta</strong></p>
                                <span class="p-small text-justify">que inicialmente te otorgaron, por un número de tu conveniencia. Esta modificación puedes realizarla en el mismo cajero.</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="0.8s">
                            <img src="assets/images/module-5/step-5-2.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>No recibas la ayuda de extraños</strong></p>
                                <span class="p-small text-justify">para operar el cajero automático. Si requieres colaboración busca a alguien de tu confianza o solicítale ayuda al personal del Banco.</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="1s">
                            <img src="assets/images/module-5/step-5-3.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>Recuerda tu contraseña antes de entrar</strong></p>
                                <span class="p-small text-justify">al tercer intento fallido el cajero retendrá tu tarjeta. Éste es un mecanismo de seguridad en caso de robo o extravío de la tarjeta.</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="1.2s">
                            <img src="assets/images/module-5/step-5-4.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>Evita usar cajeros que no tengan la cabina</strong></p>
                                <span class="p-small text-justify">o no cuenten con una puerta, estos elementos te ayudan a resguardar tu privacidad.</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="1.4s">
                            <img src="assets/images/module-5/step-5-5.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>Al ingresar revisa bien el cajero</strong></p>
                                <span class="p-small text-justify">no debe haber obstrucciones ni objetos extraños en el dispensador de efectivo, teclado o la ranura para insertar la tarjeta.</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-lg-6 col-12 tart-content wow bounceIn" data-wow-delay="1.6s">
                            <img src="assets/images/module-5/step-5-6.svg" alt="">
                            <ul class="module_list type_3">
                              <li>
                                <p><strong>Vigila a tu alrededor</strong></p>
                                <span class="p-small text-justify">Finalmente, antes de entrar al cajero asegúrate que no haya nadie sospechoso cerca tuyo.</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                    </div>
                    <div class="col-12">
                      <div class="max-content-inp">
                        <p class="color-3 wow fadeIn">
                          <strong>
                            *Si te robaron o extraviaste tu tarjeta, llama a la línea gratuita del Banco para bloquearla. Podrás recuperar la tarjeta en cualquier agencia del país por un bajo costo (consulta el mismo llamando a la línea gratuita del Banco)
                          </strong>
                        </p>
                      </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-4">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s" (click)="activeWow()">ANTERIOR</a>
                  <a *ngIf="this.finalModule == false" class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                  <a *ngIf="this.finalModule == true" class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar bg-5" role="progressbar" style="width: 100%;" aria-valuenow="100"
                        aria-valuemin="0" aria-valuemax="100">
                            <img class="icon_progress" src="assets/images/module-5/map-marker.svg">
                        </div>
                    </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body py-4">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-4 col-6 text-center">
                      <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-1.svg" width="80%">
                  </div>
                  <div class="col-lg-8 col-12 text-justify">
                      <h4 class="color-5">Agencia Fija (AF):</h4>
                      <p>Está ubicada en un local fijo y depende funcionalmente de una sucursal o directamente de su oficina central, en este último caso debe constituirse en un centro de información contable independiente. En la agencia fija se pueden realizar todas las operaciones y servicios autorizados a la entidad supervisada. Dentro de este tipo de punto de atención financiera también se consideran las agencias fijas de las Empresas de Servicios Financieros Complementarios que pueden o no prestar servicios al público, de acuerdo con la naturaleza de sus operaciones y servicios permitidos.</p>
                      <audio class="mb-4" controls="controls" controlslist="nodownload">
                        <source src="assets/audio/agencia-fija.mp3" type="audio/ogg" />
                        <source src="assets/audio/agencia-fija.mp3" type="audio/mpeg" />
                      Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                      </audio>
                      <a id="stop-audio-btn" class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Agencia Móvil (AM):</h4>
                        <p>Realiza sus operaciones o presta sus servicios, al interior de un vehículo blindado estableciendo una ruta que incluye uno o varios municipios de un departamento y que funcionalmente depende de una sucursal o directamente de su oficina central, en este último caso debe constituirse en un centro de información contable independiente. La agencia móvil puede realizar todas las operaciones y servicios autorizados a la entidad supervisada.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/agencia-movil.mp3" type="audio/ogg" />
                          <source src="assets/audio/agencia-movil.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Cajero Automático (CA):</h4>
                        <p>Permite a los clientes y/o usuarios de servicios financieros, mediante la operación de una máquina, realizar retiros y/o depósitos de efectivo, consultas de movimientos y saldos, rescate de cuotas, transferencias entre cuentas propias y a cuentas de terceros, carga y efectivización de billetera móvil y/o pagos de servicios, mediante el uso de tarjetas de débito, tarjetas de crédito, tarjetas prepagadas o un dispositivo móvil.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/cajero-automatico.mp3" type="audio/ogg" />
                          <source src="assets/audio/cajero-automatico.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Oficina Central (OC):</h4>
                        <p>Constituida como el domicilio legal de una entidad supervisada, que puede realizar cualquiera de las operaciones y servicios autorizados a la misma, en la que se podrá o no atender al público. Consolida contablemente todas las operaciones de la entidad supervisada.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/oficina-central.mp3" type="audio/ogg" />
                          <source src="assets/audio/oficina-central.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-4.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Oficina Externa (OE):</h4>
                        <p>Ubicada en entidades públicas, empresas o negocios privados, con el objeto de:</p>
                        <ul style="list-style: lower-roman;">
                          <li>Prestar servicios de depósitos y retiros de cuentas.</li>
                          <li>Recibir pagos de créditos</li>
                          <li>Pagar a funcionarios públicos</li>
                          <li>Comprar y vender monedas extranjeras</li>
                          <li>Pagar rentas y bonos</li>
                          <li>Prestar servicios de cobranza a clientes y/o usuarios por cuenta de instituciones públicas o empresas privadas</li>
                          <li>Realizar la carga y efectivización del dinero electrónico almacenado en la billetera móvil</li>
                        </ul>
                        <p>La oficina externa podrá ubicarse en instalaciones de una entidad privada de carácter financiero, sólo si pertenece al mismo conglomerado financiero. En municipios con nivel bajo o nulo de bancarización, podrá otorgar créditos y abrir cuentas en caja de ahorro o cuentas de pago.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/oficina-externa.mp3" type="audio/ogg" />
                          <source src="assets/audio/oficina-externa.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Sucursal (SU):</h4>
                        <p>Depende directamente de la oficina central y se constituye en un centro de información contable independiente, que debe consolidar la información contable de los demás puntos de atención financiera del departamento en el que se encuentra instalada. Puede realizar todas las operaciones y servicios permitidos a la entidad.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/sucursal.mp3" type="audio/ogg" />
                          <source src="assets/audio/sucursal.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-7 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide7modal" tabindex="-1" role="dialog" aria-labelledby="slide7-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Ventanilla de Cobranza (VC):</h4>
                        <p>Instalada al interior de entidades públicas o empresas privadas, con el único objeto de prestar servicios de cobranza por cuenta de instituciones públicas o empresas privadas.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/ventanilla.mp3" type="audio/ogg" />
                          <source src="assets/audio/ventanilla.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-8 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide8modal" tabindex="-1" role="dialog" aria-labelledby="slide8-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Requieres de tu tarjeta de débito o crédito para realizar una transacción. Asimismo, debes conocer tu pin o contraseña. Esta información es personal y se encuentra en el sobre en el que el Banco te entrega tu respectiva tarjeta</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-9 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide9modal" tabindex="-1" role="dialog" aria-labelledby="slide9-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Puedes acceder al cajero automático las 24 horas del día, 7 días a la semana.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-10 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide10modal" tabindex="-1" role="dialog" aria-labelledby="slide10-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Los cajeros tienen un límite diario de retiro de dinero por razones de seguridad.  Tómalo en cuenta por si necesitas utilizar más dinero del que puedes retirar.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-11 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide11modal" tabindex="-1" role="dialog" aria-labelledby="slide11-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>El uso de cajeros automáticos fuera del territorio nacional, tiene un costo de transacción. Verifica con tu entidad de intermediación financiera las limitaciones, requisitos y costos para el uso de tus tarjetas en el exterior.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-12 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide12modal" tabindex="-1" role="dialog" aria-labelledby="slide12-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Transacciones disponibles en cajeros automáticos Dependiendo de la entidad financiera, los cajeros automáticos presentan, generalmente, las siguientes opciones de transacción:</p>
                      <ul>
                        <li>Retiro de fondos</li>
                        <li>Depósito de efectivo</li>
                        <li>Transferencias entre cuentas</li>
                        <li>Consulta de saldos</li>
                        <li>Consulta de los últimos movimientos</li>
                        <li>Cambio de PIN</li>
                      </ul>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h1 class="modal-title text-white">¡FELICIDADES!</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body bg-module-completed">
              <div class="container-fluid center">
                  <div class="row justify-content-center mb-4">
                      <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                          <img class="img-1 w-75" src="assets/images/module-5/module-completed.svg">
                          <h2 class="color-5 mt-2">COMPLETASTE EL MÓDULO 5</h2>
                      </div>
                  </div>
                  <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                      <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                          <img class="img-2" src="assets/images/module-5/quiz.svg">
                      </div>
                      <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                          <h4 class="color-5">Completa el QUIZ
                            <!-- <a aria-hidden="true" routerLink="quiz" data-dismiss="modal">aquí</a> -->
                            <a routerLink="/intermedio" data-dismiss="modal">aquí</a>
                          </h4>
                          <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                      </div>
                      <!-- <div class="col-9 text-center mt-2">
                          <p><small>No te olvides que por cada <strong class="color-5">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-5">una oportunidad más de ganar.</strong></small></p>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
