import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { Chapter1InitialComponent } from './chapters/initial/chapter1/chapter1Initial.component';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgwWowModule } from 'ngx-wow';
import { Quiz1Component } from './quiz/quiz1/quiz1.component';
import { Quiz2Component } from './quiz/quiz2/quiz2.component';
import { Quiz3Component } from './quiz/quiz3/quiz3.component';
import { Quiz4Component } from './quiz/quiz4/quiz4.component';
import { Quiz5Component } from './quiz/quiz5/quiz5.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PuzzleComponent } from './games/puzzle/puzzle.component';
import { CardsComponent } from './games/cards/cards.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesComponent } from './pages/pages.component';
import { CommonModule } from "@angular/common";
import { VerificarEmailComponent } from './pages/verificar-email/verificar-email.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ControlsComponent } from './components/controls/controls.component';
import { CirclesComponent } from './components/circles/circles.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HomeInitialComponent } from './chapters/initial/home-initial/home-initial.component';
import { Chapter2InitialComponent } from './chapters/initial/chapter2/chapter2Initial.component';
import { Chapter3InitialComponent } from './chapters/initial/chapter3/chapter3Initial.component';
import { Chapter4InitialComponent } from './chapters/initial/chapter4/chapter4Initial.component';
import { Chapter5InitialComponent } from './chapters/initial/chapter5/chapter5Initial.component';
import { HomeIntermediateComponent } from './chapters/intermediate/home-intermediate/home-intermediate.component';
import { Chapter1IntermediateComponent } from './chapters/intermediate/chapter1/chapter1intermediate.component';
import { Chapter2IntermediateComponent } from './chapters/intermediate/chapter2/chapter2intermediate.component';
import { Chapter3IntermediateComponent } from './chapters/intermediate/chapter3/chapter3intermediate.component';
import { Chapter4IntermediateComponent } from './chapters/intermediate/chapter4/chapter4intermediate.component';
import { Chapter5IntermediateComponent } from './chapters/intermediate/chapter5/chapter5intermediate.component';
import { HomeAdvancedComponent } from './chapters/advanced/home-advanced/home-advanced.component';
import { Chapter1AdvancedComponent } from './chapters/advanced/chapter1/chapter1Advanced.component';
import { Chapter2AdvancedComponent } from './chapters/advanced/chapter2/chapter2Advanced.component';
import { Chapter3AdvancedComponent } from './chapters/advanced/chapter3/chapter3Advanced.component';
import { Chapter4AdvancedComponent } from './chapters/advanced/chapter4/chapter4Advanced.component';
import { Chapter5AdvancedComponent } from './chapters/advanced/chapter5/chapter5Advanced.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    HomeInitialComponent,
    HomeAdvancedComponent,
    HomeIntermediateComponent,
    RegisterComponent,
    Chapter1InitialComponent,
    Chapter2InitialComponent,
    Chapter3InitialComponent,
    Chapter4InitialComponent,
    Chapter5InitialComponent,
    Chapter1IntermediateComponent,
    Chapter2IntermediateComponent,
    Chapter3IntermediateComponent,
    Chapter4IntermediateComponent,
    Chapter5IntermediateComponent,
    Chapter1AdvancedComponent,
    Chapter2AdvancedComponent,
    Chapter3AdvancedComponent,
    Chapter4AdvancedComponent,
    Chapter5AdvancedComponent,
    Quiz1Component,
    Quiz2Component,
    Quiz3Component,
    Quiz4Component,
    Quiz5Component,
    NopagefoundComponent,
    FooterComponent,
    PuzzleComponent,
    CardsComponent,
    DashboardComponent,
    AdminComponent,
    PagesComponent,
    VerificarEmailComponent,
    ResetPasswordComponent,
    ControlsComponent,
    CirclesComponent,
    CertificateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    NgwWowModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxCaptchaModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
