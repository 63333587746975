<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">Introducción a las finanzas familiares</h2>
                </li>
                <li>
                    <h2 class="color-1">¿Qué son las finanzas familiares?</h2>
                </li>
              </ol>
            </div>

                <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué son las finanzas familiares?</h2>
                          <div class="wow bounceInDown" data-wow-delay="1s">
                            <p class="text-justify mb-0">Refieren a la gestión de utilización de los ingresos y gastos que una unidad familiar posee para mantener sana su economía. </p>
                            <div class="row align-items-center justify-content-center" style="padding: 2rem 0;">
                                <div class="col-4 px-4">
                                    <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                        <p class="mb-0 text-left">Ingresos de la familia</p>
                                        <div class="img-container">
                                          <img src="assets/images/initial/chapter1/1.jpg">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 px-4">
                                  <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                    <p class="mb-0">Acciones que deben presupuestar, gastar o ahorrar</p>
                                    <div class="img-container">
                                      <img src="assets/images/initial/chapter1/2.jpg">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 px-4">
                                  <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                    <p class="mb-0">Posibles eventualidades significativas </p>
                                    <div class="img-container">
                                      <img src="assets/images/initial/chapter1/3.jpg">
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div class="wow bounceInDown" data-wow-delay="1.5s">
                            <p class="text-justify mb-0">Como tal, las finanzas familiares se centran en la gestión de los recursos que poseen y es importante tomar una serie de actitudes para contribuir a que exista una mejor relación con el dinero, por ejemplo:</p>
                            <div class="row align-items-center justify-content-center" style="padding: 2rem 0;">
                              <div class="col-4 px-4">
                                  <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                      <p class="mb-0">Tener un plan de gastos mensuales</p>
                                      <div class="img-container">
                                        <img src="assets/images/initial/chapter1/4.jpg">
                                      </div>
                                  </div>
                              </div>
                              <div class="col-4 px-4">
                                <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <p class="mb-0">Definir tareas y  responsabilidades  financieras</p>
                                  <div class="img-container">
                                    <img src="assets/images/initial/chapter1/5.jpg">
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 px-4">
                                <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <p class="mb-0">Separar gastos personales de los del hogar</p>
                                  <div class="img-container">
                                    <img src="assets/images/initial/chapter1/6.jpg">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="wow bounceInDown" data-wow-delay="2s">
                            <p class="text-justify mb-0">Y para lograr ello, es importante tomar en cuenta los siguientes  pasos como consejos para  guiar el camino hacia la mejor gestión de recursos y finanzas familiares:</p>
                            <div class="row align-items-center justify-content-center" style="padding: 2rem 0;">
                              <div class="col-3 px-4">
                                  <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                      <p class="mb-0">Fijar metas</p>
                                  </div>
                              </div>
                              <div class="col-3 px-4">
                                <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <p class="mb-0">Elaborar plan de acción</p>
                                </div>
                              </div>
                              <div class="col-3 px-4">
                                <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <p class="mb-0">Generar un presupuesto</p>
                                </div>
                              </div>
                              <div class="col-3 px-4">
                                <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <p class="mb-0">Evalúa los resultados</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="js-stop btn bg-1 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="3s" (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-1">Introducción a las finanzas familiares</h2>
                  </li>
                  <li>
                      <h2 class="color-1">¿Qué son las finanzas familiares?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué son las finanzas familiares?</h2>
                            <p class="text-justify wow bounceInDown" data-wow-delay = "1s">Algunas acciones importantes que aportan a las metas establecidas implica, por ejemplo:</p>
                            <ul class="module_list wow bounceInDown" data-wow-delay = "1.5s">
                              <li>
                                <p class="text-justify mb-0">Buscar generar una cultura de ahorro en lugar de gasto.</p>
                              </li>
                              <li>
                                <p class="text-justify mb-0">Evita las compras innecesarias.</p>
                              </li>
                              <li>
                                <p class="text-justify mb-0">Es posible aplazar los gastos.</p>
                              </li>
                              <li>
                                <p class="text-justify mb-0">Controlar la salida de dinero de manera exhaustiva.</p>
                              </li>
                              <li>
                                <p class="text-justify mb-0">Revisar las deudas.</p>
                              </li>
                              <li>
                                <p class="text-justify mb-0">Identificar y reducir los gastos silenciosos.</p>
                              </li>
                            </ul>
                            <p class="text-justify wow bounceInDown" data-wow-delay = "2s">La cultura de ahorro es de gran importancia debido a que nos ayuda a trazar objetivos y a ganar disciplina, orden y constancia en el cumplimiento de los mismos.</p>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 25%;" aria-valuenow="7"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:25.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-1">Introducción a las finanzas familiares</h2>
                  </li>
                  <li>
                      <h2 class="color-1">¿Qué es un Presupuesto?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué es un Presupuesto?</h2>
                        </div>
                        <div class="col-12">
                          <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1s">
                            <p>El presupuesto es una herramienta que nos permite saber cuáles son sus ingresos y gastos en un tiempo determinado. Se debe elaborar entre todos los integrantes de la familia para que los conceptos involucrados sean aprendidos conjuntamente y puedan conocer los montos de dinero que pueden emplearse en el cumplimiento de objetivos o metas.</p>
                          </div>
                          <div class="video wow bounceInDown" data-wow-delay = "1.5s">
                            <video class="video_screen" id="sample_video" controls="false" controlslist="nodownload">
                                <source src="assets/images/initial/chapter1/PRESUPUESTO-JUN-2022.mp4" type="video/mp4">
                            </video>
                          </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 50%;" aria-valuenow="15"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-1">Introducción a las finanzas familiares</h2>
                  </li>
                  <li>
                      <h2 class="color-1">¿Qué es el Ahorro?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row content_center text-center justify-content-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué es el Ahorro?</h2>
                        </div>
                        <div class="col-12">
                          <div class="wow bounceInDown d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1s">
                            <p>Ahorrar significa reservar parte de los ingresos actuales que se perciben para diferentes eventualidades como cumplimiento de metas, objetivos o para asegurar el futuro.</p>
                          </div>
                          <div class="video wow bounceInDown" data-wow-delay = "1.5s">
                            <video class="video_screen" id="sample_video" controls="false" controlslist="nodownload">
                                <source src="assets/images/initial/chapter1/ECOFUTURO-AHORRO-cambio-2022.mp4" type="video/mp4">
                            </video>
                          </div>
                        </div>
                    </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 75%;" aria-valuenow="22"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:75.5%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-1">Introducción a las finanzas familiares</h2>
                  </li>
                  <li>
                      <h2 class="color-1">¿Qué es la cultura de ahorro?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-12">
                            <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué es la cultura de ahorro?</h2>
                        </div>
                        <div class="col-5 wow bounceIn" data-wow-delay = "1s">
                          <p>Es la costumbre que tiene una persona de ahorrar, guardar, o no gastar una parte de los ingresos que percibe en base a un plan para lograr algún objetivo o colchón financiero.</p>
                          <p>Tener la costumbre de ahorrar implica contar con un importe de dinero que servirá en el futuro para utilizar ante cualquier contingencia u objetivo.</p>
                          <p>No ahorrar lleva a no tener libertad financiera y eso a pedir dinero prestado ya que los ingresos no cubren las necesidades u obligaciones, llevando finalmente a un posible sobreendeudamiento.</p>
                        </div>
                        <div class="col-7 wow bounceInDown" data-wow-delay = "1.5s">
                          <div class="video" style="position: relative; width: auto; height: 455px;">
                            <video class="video_screen" id="sample_video" controls="false" controlslist="nodownload" style="position: absolute; inset: 0 0 0 0; max-width: 100%; height: auto;">
                                <source src="assets/images/initial/chapter1/ECOFUTURO-AHORRO-cambio-2022.mp4" type="video/mp4">
                            </video>
                          </div>
                        </div>
                    </div>
                </div>

                <!-- <app-controls ngClass="chapter1"></app-controls> -->
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                      <a class="btn bg-1 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay = "2s" (click)="stopSecuence()">ANTERIOR</a>
                      <a *ngIf="this.finalModule == false" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s" data-toggle="modal" data-target=".module-completed" (click)="stopSecuence()">FINALIZAR</a>
                      <a *ngIf="this.finalModule == true" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s"  (click)="goToHome()">IR AL INICIO</a>
                  </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 100%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next" (click)="activeWow()"></div>
    <div class="swiper-button-prev" (click)="activeWow()"></div> -->
</swiper>

<app-footer></app-footer>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <!-- <img class="img-1 w-75" src="assets/images/module/module-completed.svg"> -->
                            <img class="img-1 w-75" src="assets/images/icons/initial/1.png">
                            <h2 class="color-1 mt-2">COMPLETASTE EL MÓDULO 1</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-1">Completa el QUIZ
                              <a routerLink="/inicial/modulo-2" data-dismiss="modal">aquí</a>
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-1">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-1">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
