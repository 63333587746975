<section class="card-game">
    <div class="todo">
        <div class="memoria">
            <div class="carta" id="1">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="2">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="3">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="4">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
            <div class="carta" id="5">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="6">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="7">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="8">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
            <div class="carta" id="9">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="10">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="11">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="12">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
            <div class="carta" id="13">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="14">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="15">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
            <div class="carta" id="16">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
            <div class="carta" id="17">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
            <div class="carta" id="18">
                <div class="inner-wrap">
                    <div class="frente"></div>
                    <div class="atras"></div>
                </div>
            </div>
    
        </div>
        <div class="contador d-flex">
            <p>Intentos</p>
            <p class="counter ml-3">0</p>
        </div>
    </div>
</section>