<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Aspectos a evaluar para la otorgación de créditos</h2>
                  </li>
                  <li>
                      <h2 class="color-2">¿Qué evalúa el Banco?</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row content_center text-center">
                        <div class="col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay = ".5s">¿Qué evalúa el Banco?</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay = "1s">Al momento de analizar la otorgación de un crédito, el Banco evalúa varios aspectos en cuanto al solicitante para comprobar si es viable o no aprobar un préstamo de dinero al mismo, y hoy te presentamos a detalle qué aspectos evalúan las entidades financieras.</p>
                            <p class="text-justify wow bounceInUp mb-5" data-wow-delay = "1s">El principal método de evaluación a utilizar para la otorgación de créditos viene a ser el análisis cualitativo y cuantitativo, a través del cual; la entidad puede conocer si el cliente o futuro deudor cuenta con las características necesarias para que le sea otorgado un crédito.</p>
                            <div class="content-select-card">
                                <div class="row align-items-end justify-content-center">
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.2s">
                                            <a data-toggle="modal" data-target="#slide1modal" (click)="dismissAlert()">
                                              <ul class="module_list type_2 d-flex justify-content-center">
                                                  <li class="mb-0">
                                                      <h5>Evaluación Cualitativa</h5>
                                                  </li>
                                              </ul>
                                            </a>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissOne">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="type-card wow fadeIn" data-wow-delay="1.6s">
                                            <a data-toggle="modal" data-target="#slide2modal" (click)="dismissAlert2()">
                                                <ul class="module_list type_2 d-flex justify-content-center">
                                                    <li class="mb-0">
                                                        <h5>Evaluación Cuantitativa</h5>
                                                    </li>
                                                </ul>
                                            </a>
                                            <div class="message-bubble wow pulse" data-wow-iteration="infinite" *ngIf="dismissTwo">
                                                <p>¡Haz click aquí!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="col-lg-4">
                        <a class="btn bg-2 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s"
                            (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 3%;" aria-valuenow="3"
                              aria-valuemin="0" aria-valuemax="100">
                              <img class="icon_progress" src="assets/images/module-2/progress-icon.svg">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite" >
                      <img src="assets/images/module-2/pork-icon.svg">
                  </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Aspectos a evaluar para la otorgación de créditos</h2>
                  </li>
                  <li>
                      <h2 class="color-2">Evaluación Cualitativa</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                    <div class="row justify-content-center content_center text-center">
                        <div class="col-xl-11 col-12">
                            <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">Evaluación Cualitativa</h2>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1s">Las entidades financieras suelen revisar y verificar los antecedentes crediticios a través de las Centrales de Riesgo y de los burós de Información para conocer historiales crediticios de los posibles futuros deudores.</p>
                            <p class="text-justify wow bounceInUp" data-wow-delay="1s">Igualmente realizan la verificación del domicilio, de las actividades laborales o lugar de trabajo de la persona en cuestión.</p>
                        </div>
                        <div class="col-xl-11 col-12 wow bounceInUp" data-wow-delay="1.5s">
                            <h4 class="color-2 text-left">Carácter y comportamiento del cliente</h4>
                            <p class="text-justify">Se busca inicialmente revisar la información por si el solicitante obtuvo créditos con anterioridad para revisar cómo fue su comportamiento de pago de esos créditos o si un crédito le fue denegado anteriormente y el motivo. Se consulta a las centrales de Riesgo para conocer la situación del cliente como tal en el Sistema Financiero.</p>
                        </div>
                        <div class="col-xl-11 col-12 wow bounceInUp" data-wow-delay="1.5s">
                            <h4 class="color-2 text-left">Realidad Familiar</h4>
                            <p class="text-justify">Se evalúa la carga familiar, la estabilidad de los ingresos, los gastos familiares como pago de servicios básicos, alimentación, etc., cuotas de otros créditos en caso de que se posean o el pago de alquiler en caso de no poseer vivienda propia.</p>
                        </div>
                        <div class="col-xl-11 col-12 wow bounceInUp" data-wow-delay="1.5s">
                            <h4 class="color-2 text-left">Gestión del Negocio</h4>
                            <p class="text-justify">Se busca conocer si las cuentas contables o de ingresos y gastos del negocio son registrados de forma correcta y ordenada, la cantidad de empleados si posee, modalidad de trabajo, tiempo del negocio dentro del mercado, el cumplimiento de obligaciones, pago de impuestos, honorarios, servicios básicos, etc.</p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-auto">
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                        <a class="btn bg-2 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay = "2s" (click)="activeDemo()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                  <div class="col">
                    <div class="progress">
                      <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 50%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                      <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.5%" src="assets/images/module-2/progress-icon.svg">
                    </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img src="assets/images/module-2/pork-icon.svg">
                  </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-2">Aspectos a evaluar para la otorgación de créditos</h2>
                  </li>
                  <li>
                      <h2 class="color-2">Evaluación Cuantitativa</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row justify-content-center content_center text-center">
                      <div class="col-12">
                          <h2 class="color-2 pb-4 wow bounceIn" data-wow-delay=".5s">Evaluación Cuantitativa</h2>
                          <p class="text-justify wow bounceInUp" data-wow-delay="1s">En la evaluación cuantitativa se analizará varios índices de medición que permitan conocer si el posible futuro deudor posee de manera tangible la capacidad de pago que implicaría la obligación a contraer. Se encuentran los siguientes índices:</p>
                      </div>
                      <div class="wow bounceInUp" data-wow-delay="1.5s">
                        <div class="col-12" >
                            <h4 class="color-2 text-left">Capacidad de pago</h4>
                            <p class="text-justify">Se determina si los ingresos que genera el cliente a través de sus actividades evaluadas son suficientes para cubrir los compromisos actuales más el nuevo préstamo. Inicialmente se evalúan los flujos de ingresos del cliente y se busca determinar si el usuario contará en el futuro con los recursos suficientes para reembolsar la deuda adquirida.</p>
                        </div>
                        <div class="col-12">
                            <h4 class="color-2 text-left">Capacidad de Endeudamiento o Capital</h4>
                            <p class="text-justify">El capital corresponde a los recursos que el cliente ha invertido en su empresa o negocio. El capital o patrimonio es una resta del valor de venta de sus activos (por ejemplo: tu carro, casa, ahorros en cuentas de banco, certificados de depósito) menos todo lo que debes o pasivos (por ejemplo: tus tarjetas de crédito, préstamos, etc.).</p>
                        </div>
                        <div class="col-12">
                            <h4 class="color-2 text-left">Garantías</h4>
                            <p class="text-justify">Las garantías respaldan el compromiso de pago, en caso de no poder cumplir se ejecuta la misma como pago de lo adeudado. En ocasiones la misma necesita reforzarse para reducir el riesgo asumido por la institución y mejorar así la probabilidad de aprobación. Por esta razón, a veces se solicita un garante. De ese modo, no correrá un gran riesgo de perder, por ejemplo, su vivienda. Dentro de la Institución, se encuentran las garantías hipotecarias de Inmuebles, Vehículos, Maquinaria, Personal, Prendaria, Documentos en Custodia, Quirografarias.</p>
                        </div>
                      </div>
                  </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-4" style="z-index: 2;">
                    <a class="btn bg-2 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s"
                        (click)="activeWow()">ANTERIOR</a>
                    <a *ngIf="this.finalModule == false" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
                        data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                    <a *ngIf="this.finalModule == true" class="btn bg-2 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
                </div>
              </div>

              <div class="row module_progress align-items-center">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar bg-2 wow fadeInLeft" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                    <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-2/progress-icon.svg">
                  </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-2/pork-icon.svg">
                </div>
              </div>

              <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12">
                        <h4 class="color-2">Evaluación Cualitativa:</h4>
                        <p class="text-justify">En este tipo de evaluación se analizará las cualidades o características del posible deudor, se refiere a la voluntad de pago que tiene la persona.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-12">
                        <h4 class="color-2">Evaluación Cuantitativa</h4>
                        <p class="text-justify">En este tipo de evaluación se analizará índices de medición que permitan conocer si el posible deudor cuenta tangiblemente con la capacidad de pago de la obligación a contraer.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/3.svg" width="50%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-2">Invertir:</h4>
                        <p class="text-justify">Emplear una cantidad de dinero en un proyecto, actividad o negocio con el objetivo de obtener
                            un beneficio, ganancia adicional o ingreso.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/6.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CAJA DE AHORRO:</h4>
                        <p>Es un tipo de cuenta bancaria donde el cliente deposita su dinero para que el banco lo guarde y le pague intereses periódicamente por la suma depositada.</p>
                        <p>El acceso a los fondos es inmediato por lo que se conoce como cuenta vista. Cuando el cliente desea realizar movimientos de dinero (depósitos o retiros) muy altos, se solicita el llenado de un formulario en el marco de lo dispuesto por la Unidad de Investigación Financiera (UIF).</p>
                        <p>Al abrir una caja de ahorro, el cliente puede recibir una tarjeta de débito y/o su usuario y claves de acceso a la banca digital para administrar y disponer de su dinero los los 7 días de la semana y las 24 horas del día.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/7.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">DEPÓSITO A PLAZO FIJO:</h4>
                        <p>Es un producto financiero que consiste en la entrega de una cantidad de dinero a una entidad bancaria, por un lapso previamente definido, a cambio de un interés cuya tasa y periodicidad de pago es pactada inicialmente.</p>
                        <p>A diferencia, de las cuentas de ahorro, si la persona necesita retirar su dinero antes del plazo acordado, tendrá una penalidad, que generalmente consiste en la pérdida total o parcial de los intereses ganados. Es un instrumento de inversión.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-2/8.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-2">CUENTA CORRIENTE:</h4>
                        <p>Es un contrato bancario donde el titular deposita su dinero y puede retirarlo el momento que deseé, ya sea parcial o totalmente, característica por la cual también es una cuenta vista. Se diferencia, principalmente, porque su manejo contempla la emisión de cheques para el retiro de fondos. Sin embargo, este último también puede ejecutarse a través de la ventanilla en agencias, cajeros automáticos y/o canales digitales.</p>
                        <p>Este tipo de cuenta se utiliza generalmente para pagar a clientes, proveedores, personal, etc.</p>
                        <p>Finalmente, la cuenta corriente generalmente no paga ningún tipo de interés al usuario por tratarse de un producto transaccional.</p>
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/icons/advanced/2.png">
                            <h2 class="color-2 mt-2">COMPLETASTE EL MÓDULO 2</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module-2/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-2">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/avanzado/modulo-3" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-2">módulo que termines,</strong>
                                    obtienes un cupón más, es decir, <strong class="color-2">una oportunidad más de
                                        ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
