import { Component, OnInit } from '@angular/core';

import { SwiperOptions } from 'swiper';
import { NgwWowService } from 'ngx-wow';
import { Router } from '@angular/router';
import TypeIt from 'typeit';

declare function playVideo();
declare function stopVideo();

@Component({
  selector: 'app-chapter2intermediate',
  templateUrl: './chapter2intermediate.component.html',
  styleUrls: ['./chapter2intermediate.component.scss']
})
export class Chapter2IntermediateComponent implements OnInit {
  public finalModule;
  constructor(private wowService: NgwWowService, private router: Router) {
    this.activeWow();
  }

  getBrowserInfo() {
    var ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
  }

  dismissOne=  true;
  dismissTwo=  false;
  dismissThree=  false;
  expensesTotal= false;
  incomesTotal= false;
  hideBtn1= true;
  hideBtn2= true;
  hideBtn3= true;
  hideBtn4= true;
  hideBtn5= false;
  hideBtn6= true;
  incomeList= false;
  balance= false;
  title1 = false;
  title2 = false;
  title3 = false;

  config: SwiperOptions = {
    mousewheel: false,
    cssMode: false,
    allowTouchMove: false,
    autoHeight: true,
    slidesPerView: 1,
    initialSlide: ( typeof history.state[0] != 'undefined' ) ? 7 : 0,
    effect: 'coverflow',
    navigation: {
      nextEl: '.swiper_next',
      prevEl: '.swiper_prev'
    },
  };

  items = [
    {
      icon: 'assets/images/module-2/icon-1.svg',
      size: '60',
      title: 'Aumentar tu patrimonio',
      description: 'Incrementar tu patrimonio de forma mensual.',
    },
    {
      icon: 'assets/images/module-2/icon-2.svg',
      size: '46',
      title: 'Mejorar tu calidad de vida y cumplir tus metas y/u objetivos',
      description: 'Podrás destinar este dinero para alcanzar tus sueños y proyectos además de cumplir tus objetivos familiares o personales.',
    },
    {
      icon: 'assets/images/module-2/icon-3.svg',
      title: 'Afrontar problemas',
      size: '46',
      description: 'En caso de fuerza mayor, tener un fondo de emergencia te permitirá atender los imprevistos o emergencias sin ningún problema.',
    },
    {
      icon: 'assets/images/module-2/icon-4.svg',
      size: '50',
      title: 'Tranquilidad financiera',
      description: 'Contar con los recursos para imprevistos, emprendimientos o gustos, sin tener que endeudarte.',
    },
    {
      icon: 'assets/images/module-2/icon-5.svg',
      title: 'Cumplir tus sueños',
      size: '68',
      description: 'Podrás destinar este dinero en sueños u objetivos personales o familiares.',
    },
    {
      icon: 'assets/images/module-2/icon-6.svg',
      size: '80',
      title: 'Invertir',
      description: 'Con el dinero que ahorras es posible incrementar tu capital, invirtiendo en algún bien o emprender en un negocio propio.',
    },
    {
      icon: 'assets/images/module-2/icon-7.svg',
      title: 'Educar:',
      size: '60',
      description: 'Podrás fomentar una cultura del ahorro en tu familia y en tu entorno.',
    },
  ];

  ngOnInit(): void {
    window.scroll(0,0);
    if( localStorage.getItem('email') == null ) {
      this.router.navigateByUrl('/inicio');
    }
    this.finalModule = localStorage.getItem('finish-2') == 'final' ? true : false;
    if ( this.finalModule == false ) {
      localStorage.setItem('module', '2' );
    }
  }
  dismissAlert(){
    this.dismissOne = false;
    this.dismissTwo = true;
    this.dismissThree = false;
  }
  dismissAlert2(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = true;
  }
  dismissAlert3(){
    this.dismissOne = false;
    this.dismissTwo = false;
    this.dismissThree = false;
  }
  activeWow() {
    var browserInfo = this.getBrowserInfo();
    if( browserInfo != "Chrome 4" && browserInfo != "Safari 15" && browserInfo != "Safari 14" && browserInfo != "Safari 13" && browserInfo != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
    this.dismissOne = true;
    this.dismissTwo = false;
    this.dismissThree = false;
  }
  calculateIncomes() {
    this.hideBtn1 = false;
    this.incomesTotal = true;
    new TypeIt(".incomes_total", {
      strings: ["7.290 Bs."],
      speed: 50,
      waitUntilVisible: true,
      cursor: false
    }).go();
  }
  calculateExpenses() {
    this.hideBtn2 = false;
    this.expensesTotal = true;
    this.hideBtn5 = true;
    this.title3 = false
    new TypeIt(".expenses_total", {
      strings: ["7.290 Bs."],
      speed: 50,
      waitUntilVisible: true,
      cursor: false
    }).go();
  }
  activeDemo() {
    var restricBrowsers = ["Safari 15", "Safari 14", "Safari 13", "Safari 12"];
    if( this.getBrowserInfo() != "Safari 15" && this.getBrowserInfo() != "Safari 14" && this.getBrowserInfo() != "Safari 13" && this.getBrowserInfo() != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
    this.viewIncomeList();
  }
  playVideo() {
    var restricBrowsers = ["Safari 15", "Safari 14", "Safari 13", "Safari 12"];
    if( this.getBrowserInfo() != "Safari 15" && this.getBrowserInfo() != "Safari 14" && this.getBrowserInfo() != "Safari 13" && this.getBrowserInfo() != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
    playVideo();
  }
  stopVideo() {
    var restricBrowsers = ["Safari 15", "Safari 14", "Safari 13", "Safari 12"];
    if( this.getBrowserInfo() != "Safari 15" && this.getBrowserInfo() != "Safari 14" && this.getBrowserInfo() != "Safari 13" && this.getBrowserInfo() != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
    }
    stopVideo();
  }
  viewIncomeList() {
    this.hideBtn3 = false;
    new TypeIt(".main-title", {
      startDelay: 3000,
      strings: ["Mi Presupuesto"],
      speed: 60,
      waitUntilVisible: false,
      cursor: false,
    }).go();
    new TypeIt(".incomes-title", {
      startDelay: 5000,
      strings: ["Registro de Ingresos"],
      speed: 60,
      waitUntilVisible: false,
      cursor: false,
    }).go();
    new TypeIt(".incomes", {
      startDelay: 7000,
      strings: ["Sueldo básico -- 3.645 Bs.", "Cobro de alquileres -- 2580 Bs." , "Préstamo cobrado -- 1065 Bs."],
      speed: 60,
      waitUntilVisible: false,
      cursor: false,
    }).go();
    this.viewExpenseList();
  }
  viewExpenseList() {
    this.incomeList = true;
    this.hideBtn4 = false;
    this.title2 = true;
    new TypeIt(".expenses-title", {
      startDelay: 14000,
      strings: ["Registro de Gastos"],
      speed: 50,
      waitUntilVisible: true,
      cursor: false,
    }).go();
    new TypeIt(".expenses", {
      startDelay: 16000,
      strings: ["Canasta familiar -- 634 Bs.", "Consulta médica -- 345 Bs." , "Servicio de agua -- 80 Bs."],
      speed: 50,
      waitUntilVisible: true,
      cursor: false
    }).go();
  }
  viewOperations() {
    this.hideBtn1 = false;
    new TypeIt(".instruction-1", {
      startDelay: 5000,
      strings: ["Sumamos todos los montos de nuestra lista de Ingresos"],
      speed: 30,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".total-incomes-title", {
      startDelay: 8000,
      strings: ["Total de Ingresos:"],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".incomes_total", {
      startDelay: 10000,
      strings: ["7.290 Bs."],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".instruction-2", {
      startDelay: 12000,
      strings: ["Sumamos todos los montos de nuestra lista de Gastos"],
      speed: 30,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".total-expenses-title", {
      startDelay: 14000,
      strings: ["Total de Gastos:"],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".expenses_total", {
      startDelay: 16000,
      strings: ["1.059 Bs."],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".instruction-3", {
      startDelay: 20000,
      strings: ["Restamos el monto Total de Gastos al monto Total de Ingresos"],
      speed: 30,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".balance-title", {
      startDelay: 22000,
      strings: ["Saldo Obtenido:"],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
    new TypeIt(".balance", {
      startDelay: 24000,
      strings: ["6.231 Bs."],
      speed: 50,
      waitUntilVisible: false,
      cursor: false
    }).go();
  }
  goToHome() {
    this.router.navigateByUrl('/inicio');
  }
}
