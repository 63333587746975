<app-header></app-header>

<swiper [config]="config">
  <div class="swiper-wrapper">
      <!-- SLIDE 1 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Conozcamos el Crédito más a profundidad</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center text-center">
                    <div class="col-12">
                        <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Conozcamos el Crédito más a profundidad</h2>
                        <div class="wow bounceInDown" data-wow-delay = "1s">
                          <div class="row align-items-center justify-content-center mb-4">
                            <div class="col-8">
                                <div class="text-left">
                                  <h4 class="color-1">¿Qué es el Crédito?</h4>
                                  <p class="mb-0">Es una operación por la cual una entidad financiera pone a nuestra disposición una cantidad determinada de dinero mediante un contrato. Como contraparte, el beneficiario del crédito se compromete a devolver el monto solicitado en el tiempo o plazo definido, según las condiciones establecidas para dichos préstamos más los intereses, comisiones y otros costos asociados al crédito, si los hubiera.</p>
                                </div>
                            </div>
                            <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/1.png">
                              </div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-center mb-4">
                            <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "2s">
                                <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/2.png">
                              </div>
                            </div>
                            <div class="col-8">
                                <div class="text-left" data-wow-delay = "2s">
                                  <h4 class="color-1">¿Cuándo se requiere de un crédito?</h4>
                                  <p class="mb-0">Cuando una empresa o persona no cuenta con recursos propios disponibles para invertir, para adquirir un determinado bien de consumo, una vivienda o para financiar un servicio, puede recurrir a una entidad de intermediación financiera que, previa evaluación de la capacidad de pago otorgará el préstamo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-center mb-4">
                            <div class="col-8">
                                <div class="text-left" data-wow-delay = "2.5s">
                                  <h4 class="color-1">¿Qué entidades otorgan créditos?</h4>
                                  <p class="mb-0">Todas las Entidades de Intermediación Financiera (EIF) que se encuentren autorizadas por ASFI.</p>
                                </div>
                            </div>
                            <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "2.5s">
                                <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/3.png">
                              </div>
                            </div>
                          	</div>
                        </div>
                    </div>
                </div>
              </div>

              <div class="row justify-content-center">
                  <div class="col-lg-4">
                      <a class="js-stop btn bg-1 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="3s" (click)="activeWow()">SIGUIENTE</a>
                  </div>
              </div>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
      <!-- SLIDE 2 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Conceptos generales</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center text-center">
                  <div class="col-12">
                      <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Conceptos generales</h2>
                      <div class="wow bounceInDown" data-wow-delay = "1s">
                        <div class="row align-items-center justify-content-center mb-4">
                          <div class="col-8">
                              <div class="text-left">
                                  <h4 class="color-1">Sujeto de Crédito</h4>
                                  <p class="mb-0">Toda persona natural o jurídica es sujeto de crédito, siempre y cuando tenga una capacidad de pago que será evaluada por las entidades de intermediación financiera al momento de otorgar el crédito.</p>
                              </div>
                          </div>
                          <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/4.png">
                              </div>
                          </div>
                      </div>
                      <div class="row align-items-center justify-content-center mb-4">
                          <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/5.png">
                              </div>
                          </div>
                          <div class="col-8">
                              <div class="text-left">
                                  <h4 class="color-1">Garantía</h4>
                                  <p class="mb-0">Las garantías son una fuente alternativa de pago cuando el prestatario no cumple con la cancelación de las cuotas a favor de una entidad financiera, conforme al plazo y condiciones del contrato. </p>
                              </div>
                          </div>
                      </div>
                      <div class="row align-items-center justify-content-center mb-4">
                          <div class="col-8">
                              <div class="text-left">
                                  <h4 class="color-1">Garante</h4>
                                  <p class="mb-0">Persona natural o jurídica que, en el marco de un acuerdo contractual suscrito con una entidad supervisada, en caso de incumplimiento de pago por el deudor y/o codeudor, asume el pago de una cantidad de dinero recibido en calidad de préstamo por el deudor.</p>
                              </div>
                          </div>
                          <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/6.png">
                              </div>
                          </div>
                      </div>
                      <div class="row align-items-center justify-content-center mb-4">
                          <div class="col-4">
                              <div class="d-flex gap-2 align-items-center justify-content-center text-left">
                                  <img class="max-w-l radius-50" src="assets/images/advanced/chapter1/7.png">
                              </div>
                          </div>
                          <div class="col-8">
                              <div class="text-left">
                                  <h4 class="color-1">Contrato de Crédito</h4>
                                  <p class="mb-0">Es un documento en virtud del cual la entidad de intermediación financiera pone a disposición de su cliente, recursos para ser utilizados en un plazo determinado.</p>
                              </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                <app-controls ngClass="chapter1"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                        <div class="progress">
                            <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2s" role="progressbar" style="width: 16.66%;" aria-valuenow="7"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:17.16%" src="assets/images/elements/progress-icon.svg">
                        </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img class="icon_module" src="assets/images/module/progress-icon.svg">
                    </div>
                </div>

                <app-circles></app-circles>
          </section>
      </div>
      <!-- SLIDE 3 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Reprogramación y Refinanciamiento</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Reprogramación y Refinanciamiento</h2>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                            <h4 class="color-1">Reprogramación</h4>
                            <p class="mb-0 text-justify">Es el acuerdo, convenio o contrato en virtud del cual se modifican las principales condiciones del crédito por deterioro en la capacidad de pago del deudor, ya sea estableciendo un monto diferente o un nuevo plan de pagos por el saldo del crédito.</p>
                            <p class="mb-0 text-justify">La reprogramación debe estar instrumentada mediante un nuevo contrato o una adenda al contrato original, independientemente de que se encuentre o no amparado bajo una línea de crédito.</p>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                            <h4 class="color-1">Refinanciamiento</h4>
                            <p class="mb-0 text-justify">Es la cancelación de un crédito con un nuevo préstamo. El refinanciamiento es válido únicamente cuando la capacidad de pago del prestatario no presenta deterioro con relación a la determinada en el crédito cancelado con la nueva operación y cuando el deudor no se encuentre en mora. No se considera refinanciamiento cuando el monto otorgado se aplica a un destino y tipo de crédito diferente al establecido en la operación que se cancela.</p>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>

              <app-controls ngClass="chapter1"></app-controls>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2s" role="progressbar" style="width: 33.32%;" aria-valuenow="15"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:33.82%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
      <!-- SLIDE 4 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Garantías</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center align-items-start text-center">
                    <div class="col-12">
                        <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Garantías</h2>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">Garantías Convencionales</h4>
                        <p class="mb-0 text-justify">La garantía puede referirse a una garantía real, garantía personal y/o garantía por tecnología de otorgación de préstamos que una EIF tiene desarrollada, entre las cuales están contempladas las garantías no convencionales; para mitigar el riesgo de crédito y proteger el cumplimiento de obligaciones derivadas de un préstamo.</p>
                        <p class="mb-0 text-justify">Las garantías forman parte integrante del proceso crediticio, por lo cual la EIF debe mantener un registro actualizado de las mismas y los antecedentes necesarios que demuestren su existencia, protección y tasación, cuando corresponda. </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">Garantías No Convencionales</h4>
                        <p class="mb-0 text-justify">Se constituyen en medios alternativos para mitigar el riesgo de crédito y reforzar el sentido de responsabilidad y cumplimiento de las obligaciones financieras del prestatario.</p>
                        <p class="mb-0 text-justify">Son admitidas en la otorgación de créditos para el desarrollo del sector productivo.</p>
                        <p class="mb-0 text-justify">Forman parte de la tecnología crediticia de la entidad supervisada. </p>
                      </div>
                    </div>
                </div>
              </div>

              <app-controls ngClass="chapter1"></app-controls>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 49.98%;" aria-valuenow="22"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.48%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
      <!-- SLIDE 5 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Cultura de Pago</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center align-items-start text-center">
                    <div class="col-12">
                        <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Cultura de Pago</h2>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">¿Qué es la Cultura de Pago?</h4>
                        <p class="mb-0 text-justify">Es el hábito de responsabilidad con las deudas o pagos que se tienen. El “pago” es aquella acción que realizamos para cancelar una obligación, se basa en la entrega de un bien, servicio o activo financiero a cambio de otro bien, servicio o activo financiero.</p>
                        <p class="mb-0 text-justify">La cultura de pago es la costumbre de cumplir con las obligaciones financieras, pagando las deudas dentro de los plazos que se establecieron previamente.</p>
                        <p class="mb-0 text-justify">Al no consolidar este hábito, no se pagará con debida responsabilidad y puntualidad ninguna deuda o pago pendiente que se tenga, y puede incidir negativamente en la persona.</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">¿Qué beneficios trae una buena cultura de pago?</h4>
                        <p class="mb-0 text-justify">Implica que quiera ser atraído por cualquier otra entidad financiera, por la responsabilidad que tiene con sus pagos y porque puede convertirse en un CLIENTE CPOP (Cliente con Pleno y Oportuno Pago), lo que le permite acceder a préstamos con menores tasas de interés y a mayores plazos entre algunos beneficios.</p>
                        <div class="img-container image-xl m-auto">
                          <img class="max-w-xl m-auto d-block" src="assets/images/advanced/chapter1/8.jpg">
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <app-controls ngClass="chapter1"></app-controls>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 66.64%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:67.14%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
      <!-- SLIDE 6 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Cultura de Pago</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center align-items-start text-center">
                    <div class="col-12">
                        <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Cultura de Pago</h2>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">¿Qué beneficios trae una buena cultura de pago?</h4>
                        <p class="mb-0">Implica que quiera ser atraído por cualquier otra entidad financiera, por la responsabilidad que tiene con sus pagos y porque puede convertirse en un CLIENTE CPOP (Cliente con Pleno y Oportuno Pago), lo que le permite acceder a préstamos con menores tasas de interés y a mayores plazos entre algunos beneficios.</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left" data-wow-delay = "1s">
                        <h4 class="color-1">¿Qué pasa si no tengo una buena cultura de pago?</h4>
                        <p class="mb-0">Si un cliente no paga las cuotas de su crédito, origina que la entidad financiera tenga sus recaudos para otorgar  un nuevo crédito o, en el peor de los casos; que el cliente no pueda acceder a otros créditos, ya que esto se registra en la base de datos de la Central de Riesgos y puede evitar que sea gestionado en cualquier entidad financiera.</p>
                      </div>
                    </div>
                </div>
              </div>

              <app-controls ngClass="chapter1"></app-controls>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2s" role="progressbar" style="width: 83.3%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:83.8%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
      <!-- SLIDE 7 -->
      <div class="swiper-slide">
          <section class="module">

            <div class="px-5 py-3 module_header text-left">
              <ol class="justify-content-start">
                <li class="light-text">
                    <h2 class="color-1">El Crédito</h2>
                </li>
                <li>
                    <h2 class="color-1">Cliente CPOP</h2>
                </li>
              </ol>
            </div>

              <div class="container module_body">
                <div class="row content_center align-items-start text-center">
                    <div class="col-12">
                      <h2 class="color-1 pb-4 wow bounceIn" data-wow-delay = ".5s">Cliente CPOP</h2>
                      <p class="text-justify wow bounceInUp" data-wow-delay = "1s">El Cliente CPOP es aquel prestatario cuyo comportamiento de pago es de pleno y oportuno cumplimiento en todas sus obligaciones crediticias, es decir; que es un “buen pagador”. El Cliente CPOP tiene su Historial Crediticio de Pagos (HCP) disponible en la Central de Intermediación Crediticia (CIC).</p>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left">
                        <h4 class="color-1">¿Cuándo un cliente puede considerarse CPOP?</h4>
                        <p class="mb-0 p-grid">Su historial crediticio de pagos debe reflejar durante los últimos 60 meses: 24 reportes mensuales continuos y discontinuos.</p>
                        <p class="mb-0 p-grid">El estado de todas las operaciones crediticias debe estar registrado como “Cartera Vigente”.</p>
                        <p class="mb-0 p-grid">Durante esos 24 reportes, el retraso en el cumplimiento de sus obligaciones financieras no debe exceder los 3 días y en no más de 2 cuotas continuas o 4 discontinuas.</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="wow bounceInDown text-left">
                        <h4 class="color-1">¿Qué beneficios recibe un cliente CPOP?</h4>
                        <p class="mb-0 p-grid">Disminución de la tasa de interés hasta el 0.01% a la tasa correspondiente a cada producto.</p>
                        <p class="mb-0 p-grid">Ampliación del plazo de vigencia de la tasa de interés fija.</p>
                        <p class="mb-0 p-grid">Relación Garantía Préstamo menor a la establecida en normativa interna.</p>
                        <p class="mb-0 p-grid">Facilidad en la obtención de un nuevo crédito.</p>
                        <p class="mb-0 p-grid">Registro en la lista de la CI (Central de Intermediación Crediticia)</p>
                      </div>
                    </div>
                </div>
              </div>

              <!-- <app-controls ngClass="chapter1"></app-controls> -->
              <div class="row justify-content-center">
                <div class="col-lg-4">
                    <a class="btn bg-1 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay = "2s" (click)="stopSecuence()">ANTERIOR</a>
                    <a *ngIf="this.finalModule == false" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s" data-toggle="modal" data-target=".module-completed" (click)="stopSecuence()">FINALIZAR</a>
                    <a *ngIf="this.finalModule == true" class="btn bg-1 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s"  (click)="goToHome()">IR AL INICIO</a>
                </div>
              </div>

              <div class="row module_progress align-items-center">
                  <div class="col">
                      <div class="progress">
                          <div class="progress-bar bg-1 wow fadeInLeft" data-wow-delay = "2.1s" role="progressbar" style="width: 100%;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                          <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/elements/progress-icon.svg">
                      </div>
                  </div>
                  <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                      <img class="icon_module" src="assets/images/module/progress-icon.svg">
                  </div>
              </div>

              <app-circles></app-circles>

          </section>
      </div>
  </div>
  <!-- Add Pagination -->
  <!-- <div class="swiper-pagination"></div> -->
  <!-- Add Arrows -->
  <!-- <div class="swiper-button-next" (click)="activeWow()"></div>
  <div class="swiper-button-prev" (click)="activeWow()"></div> -->
</swiper>

<app-footer></app-footer>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/icons/advanced/1.png">
                            <h2 class="color-1 mt-2">COMPLETASTE EL MÓDULO 1</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-1">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/avanzado/modulo-2" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-1">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-1">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
