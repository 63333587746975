<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
      <!-- SLIDE 1 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header text-left">
            <ol class="justify-content-start">
              <li class="light-text">
                  <h2 class="color-5">Recomendaciones para el prestatario</h2>
              </li>
              <li>
                  <h2 class="color-5">Recomendaciones</h2>
              </li>
            </ol>
          </div>

            <div class="container module_body">
              <div class="row content_center vh-custom text-center">
                <div class="col-12">
                  <h2 class="color-5 wow bounceIn" data-wow-delay = ".5s">Recomendaciones</h2>
                  <p class="pb-4 wow bounceInDown" data-wow-delay="1s">Antes de adquirir un préstamo en alguna institución financiera, es importante mencionar las siguientes recomendaciones:</p>
                  <div class="row">
                    <div class="col-8">
                      <h4 class="color-5 text-left wow bounceInDown" data-wow-delay="1.5s">Antes de pedir un crédito…</h4>
                      <p class="wow bounceInDown mb-0" data-wow-delay="1.5s">Lo primero que debemos hacer es analizar con objetividad nuestra situación financiera. Conocer detalladamente la relación de nuestros ingresos y gastos mensuales, este ejercicio nos mostrará cuál es nuestra verdadera capacidad de endeudamiento, además, que podremos determinar si estamos incurriendo en gastos innecesarios, los mismos que podemos eliminar, mejorando así nuestra salud financiera.</p>
                    </div>
                    <div class="col-4 wow bounceInDown" data-wow-delay="1.5s">
                      <div class="img-container image-xl mr-auto">
                        <img src="assets/images/advanced/chapter5/1.jpg" class="image max-w-xl">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-4" style="z-index: 2;">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar bg-5 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/module-5/map-marker.svg">
                  </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg">
                </div>
            </div>
            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 2 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header text-left">
            <ol class="justify-content-start">
              <li class="light-text">
                  <h2 class="color-5">Recomendaciones para el prestatario</h2>
              </li>
              <li>
                  <h2 class="color-5">Recomendaciones</h2>
              </li>
            </ol>
          </div>

          <div class="container module_body">
            <div class="row content_center vh-custom text-center">
              <div class="col-12">
                <h2 class="color-5 wow bounceIn" data-wow-delay = ".5s">Recomendaciones</h2>
                <p class="pb-4 wow bounceInDown" data-wow-delay="1s">Antes de adquirir un préstamo en alguna institución financiera, es importante mencionar las siguientes recomendaciones:</p>
                <div class="row">
                  <div class="col-8">
                    <h4 class="color-5 text-left wow bounceInDown" data-wow-delay="1.5s">Solicitar el monto necesario</h4>
                    <p class="wow bounceInDown" data-wow-delay="1.5s">En muchas ocasiones nos vemos tentados a solicitar créditos por importes superiores a lo que necesitamos en realidad, si queremos obtener un crédito es importante hacernos preguntas como: ¿de verdad necesito el préstamo? Y ¿cuál es la cantidad de dinero que necesito?, así de esta forma evitaremos adquirir una deuda innecesaria que puede comprometer nuestra salud financiera a largo plazo.</p>
                  </div>
                  <div class="col-4 wow bounceInDown" data-wow-delay="1.5s">
                    <div class="img-container image-xl mr-auto">
                      <img src="assets/images/advanced/chapter5/2.jpg" class="image max-w-xl">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

            <div class="row justify-content-center" style="margin-top: 3.5% !important;">
              <div class="col-lg-4">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay = "2s" (click)="activeWow()">ANTERIOR</a>
                  <a class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay = "2s" (click)="activeWow()">SIGUIENTE</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar bg-5 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 25%;" aria-valuenow="1"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:25.5%" src="assets/images/module-5/map-marker.svg">
                  </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 3 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header text-left">
            <ol class="justify-content-start">
              <li class="light-text">
                  <h2 class="color-5">Recomendaciones para el prestatario</h2>
              </li>
              <li>
                  <h2 class="color-5">Recomendaciones</h2>
              </li>
            </ol>
          </div>

          <div class="container module_body">
            <div class="row content_center vh-custom text-center">
              <div class="col-12">
                <h2 class="color-5 wow bounceIn" data-wow-delay = ".5s">Recomendaciones</h2>
                <p class="pb-4 wow bounceInDown" data-wow-delay="1s">Antes de adquirir un préstamo en alguna institución financiera, es importante mencionar las siguientes recomendaciones:</p>
                <div class="row">
                  <div class="col-8">
                    <h4 class="color-5 text-left wow bounceInDown" data-wow-delay="1.5s">Establecer frecuencia de pago</h4>
                    <p class="wow bounceInDown mb-0" data-wow-delay="1.5s">Es importante también establecer una frecuencia del pago de las cuotas que este acorde a la frecuencia con la que recibimos nuestros ingresos y sobre todo de acuerdo con nuestra capacidad de pago. Una vez que sabemos cuánto dinero necesitamos realmente, es hora de calcular en cuánto tiempo seremos capaces de devolverlo. Si solicitamos un crédito a largo plazo, las cuotas serán más bajas, pero terminaremos pagando más por los intereses acumulados. Si, por el contrario, el crédito es a corto plazo, cancelaremos más antes la deuda y pagaremos menos intereses, pero nuestras cuotas serán considerablemente altas.</p>
                  </div>
                  <div class="col-4 wow bounceInDown" data-wow-delay="1.5s">
                    <div class="img-container image-xl mr-auto">
                      <img style="height: 100vh;" src="assets/images/advanced/chapter5/3.jpg" class="max-w-xl">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <app-controls ngClass="chapter5"></app-controls>

          <div class="row module_progress align-items-center">
            <div class="col">
              <div class="progress">
                <div class="progress-bar bg-5 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 50%;" aria-valuenow="1"
                    aria-valuemin="0" aria-valuemax="100"></div>
                <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.5%" src="assets/images/module-5/map-marker.svg">
              </div>
            </div>
            <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
              <img src="assets/images/module-5/map.svg" class="doc-icon">
            </div>
          </div>

          <div class="content-elements">
            <app-circles></app-circles>
          </div>

        </section>
      </div>

      <!-- SLIDE 4 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header text-left">
            <ol class="justify-content-start">
              <li class="light-text">
                  <h2 class="color-5">Recomendaciones para el prestatario</h2>
              </li>
              <li>
                  <h2 class="color-5">Recomendaciones</h2>
              </li>
            </ol>
          </div>

            <div class="container module_body">
              <div class="row content_center vh-custom text-center">
                <div class="col-12">
                  <h2 class="color-5 wow bounceIn" data-wow-delay = ".5s">Recomendaciones</h2>
                  <p class="pb-4 wow bounceInDown" data-wow-delay="1s">Antes de adquirir un préstamo en alguna institución financiera, es importante mencionar las siguientes recomendaciones:</p>
                  <div class="row">
                    <div class="col-8">
                      <h4 class="color-5 text-left wow bounceInDown" data-wow-delay="1.5s">Conocer las distintas opciones</h4>
                      <p class="mb-0 wow bounceInDown" data-wow-delay="1.5s">Es importante conocer las diferentes opciones que ofrecen las demás entidades financieras, lo ideal es comparar todas las opciones disponibles en el mercado, con el fin de elegir aquella que ofrezca mayores ventajas para nosotros.</p>
                    </div>
                    <div class="col-4 wow bounceInDown" data-wow-delay="1.5s">
                      <div class="img-container image-xl mr-auto">
                        <img src="assets/images/advanced/chapter5/4.jpg" class="image max-w-xl">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <app-controls ngClass="chapter5"></app-controls>

            <div class="row module_progress align-items-center">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar bg-5 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 75%;" aria-valuenow="1"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:75.5%" src="assets/images/module-5/map-marker.svg">
                  </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>

      <!-- SLIDE 5 -->
      <div class="swiper-slide">
        <section class="module">

          <div class="px-5 py-3 module_header text-left">
            <ol class="justify-content-start">
              <li class="light-text">
                  <h2 class="color-5">Recomendaciones para el prestatario</h2>
              </li>
              <li>
                  <h2 class="color-5">Recomendaciones</h2>
              </li>
            </ol>
          </div>

            <div class="container module_body">
              <div class="row content_center vh-custom text-center">
                <div class="col-12">
                  <h2 class="color-5 wow bounceIn" data-wow-delay = ".5s">Recomendaciones</h2>
                  <p class="pb-4 wow bounceInDown" data-wow-delay="1s">Antes de adquirir un préstamo en alguna institución financiera, es importante mencionar las siguientes recomendaciones:</p>
                  <div class="row">
                    <div class="col-8">
                      <h4 class="color-5 text-left wow bounceInDown" data-wow-delay="1.5s">Crea una buena cultura de pago</h4>
                      <p class="wow bounceInDown" data-wow-delay="1.5s">Es el hábito de responsabilidad con las deudas o pagos que se tienen. El “pago” es aquella acción que realizamos para cancelar una obligación, se basa en la entrega de un bien, servicio o activo financiero a cambio de otro bien, servicio o activo financiero.</p>
                      <p class="mb-0 wow bounceInDown" data-wow-delay="1.5s">Tener el hábito de una adecuada cultura de pago puede traer beneficios al cliente financiero.</p>
                    </div>
                    <div class="col-4 wow bounceInDown" data-wow-delay="1.5s">
                      <div class="img-container image-xl mr-auto">
                        <img src="assets/images/advanced/chapter5/5.png" class="image max-w-xl">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-4">
                  <a class="btn bg-5 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s" (click)="activeWow()">ANTERIOR</a>
                  <a *ngIf="this.finalModule == false" class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                  <a *ngIf="this.finalModule == true" class="btn bg-5 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
              </div>
            </div>

            <div class="row module_progress align-items-center">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar bg-5 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="1"
                        aria-valuemin="0" aria-valuemax="100"></div>
                    <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-5/map-marker.svg">
                  </div>
                </div>
                <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                    <img src="assets/images/module-5/map.svg" class="doc-icon">
                </div>
            </div>

            <div class="content-elements">
              <app-circles></app-circles>
            </div>

        </section>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body py-4">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-4 col-6 text-center">
                      <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-1.svg" width="80%">
                  </div>
                  <div class="col-lg-8 col-12 text-justify">
                      <h4 class="color-5">Agencia Fija (AF):</h4>
                      <p>Está ubicada en un local fijo y depende funcionalmente de una sucursal o directamente de su oficina central, en este último caso debe constituirse en un centro de información contable independiente. En la agencia fija se pueden realizar todas las operaciones y servicios autorizados a la entidad supervisada. Dentro de este tipo de punto de atención financiera también se consideran las agencias fijas de las Empresas de Servicios Financieros Complementarios que pueden o no prestar servicios al público, de acuerdo con la naturaleza de sus operaciones y servicios permitidos.</p>
                      <audio class="mb-4" controls="controls" controlslist="nodownload">
                        <source src="assets/audio/agencia-fija.mp3" type="audio/ogg" />
                        <source src="assets/audio/agencia-fija.mp3" type="audio/mpeg" />
                      Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                      </audio>
                      <a id="stop-audio-btn" class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Agencia Móvil (AM):</h4>
                        <p>Realiza sus operaciones o presta sus servicios, al interior de un vehículo blindado estableciendo una ruta que incluye uno o varios municipios de un departamento y que funcionalmente depende de una sucursal o directamente de su oficina central, en este último caso debe constituirse en un centro de información contable independiente. La agencia móvil puede realizar todas las operaciones y servicios autorizados a la entidad supervisada.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/agencia-movil.mp3" type="audio/ogg" />
                          <source src="assets/audio/agencia-movil.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Cajero Automático (CA):</h4>
                        <p>Permite a los clientes y/o usuarios de servicios financieros, mediante la operación de una máquina, realizar retiros y/o depósitos de efectivo, consultas de movimientos y saldos, rescate de cuotas, transferencias entre cuentas propias y a cuentas de terceros, carga y efectivización de billetera móvil y/o pagos de servicios, mediante el uso de tarjetas de débito, tarjetas de crédito, tarjetas prepagadas o un dispositivo móvil.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/cajero-automatico.mp3" type="audio/ogg" />
                          <source src="assets/audio/cajero-automatico.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Oficina Central (OC):</h4>
                        <p>Constituida como el domicilio legal de una entidad supervisada, que puede realizar cualquiera de las operaciones y servicios autorizados a la misma, en la que se podrá o no atender al público. Consolida contablemente todas las operaciones de la entidad supervisada.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/oficina-central.mp3" type="audio/ogg" />
                          <source src="assets/audio/oficina-central.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-1-4.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Oficina Externa (OE):</h4>
                        <p>Ubicada en entidades públicas, empresas o negocios privados, con el objeto de:</p>
                        <ul style="list-style: lower-roman;">
                          <li>Prestar servicios de depósitos y retiros de cuentas.</li>
                          <li>Recibir pagos de créditos</li>
                          <li>Pagar a funcionarios públicos</li>
                          <li>Comprar y vender monedas extranjeras</li>
                          <li>Pagar rentas y bonos</li>
                          <li>Prestar servicios de cobranza a clientes y/o usuarios por cuenta de instituciones públicas o empresas privadas</li>
                          <li>Realizar la carga y efectivización del dinero electrónico almacenado en la billetera móvil</li>
                        </ul>
                        <p>La oficina externa podrá ubicarse en instalaciones de una entidad privada de carácter financiero, sólo si pertenece al mismo conglomerado financiero. En municipios con nivel bajo o nulo de bancarización, podrá otorgar créditos y abrir cuentas en caja de ahorro o cuentas de pago.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/oficina-externa.mp3" type="audio/ogg" />
                          <source src="assets/audio/oficina-externa.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Sucursal (SU):</h4>
                        <p>Depende directamente de la oficina central y se constituye en un centro de información contable independiente, que debe consolidar la información contable de los demás puntos de atención financiera del departamento en el que se encuentra instalada. Puede realizar todas las operaciones y servicios permitidos a la entidad.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/sucursal.mp3" type="audio/ogg" />
                          <source src="assets/audio/sucursal.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-7 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide7modal" tabindex="-1" role="dialog" aria-labelledby="slide7-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
       </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-5/step-2-2-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-5">Ventanilla de Cobranza (VC):</h4>
                        <p>Instalada al interior de entidades públicas o empresas privadas, con el único objeto de prestar servicios de cobranza por cuenta de instituciones públicas o empresas privadas.</p>
                        <audio class="mb-4" controls="controls" controlslist="nodownload">
                          <source src="assets/audio/ventanilla.mp3" type="audio/ogg" />
                          <source src="assets/audio/ventanilla.mp3" type="audio/mpeg" />
                        Tu navegador no soporta este elemento de audio, utiliza Chrome o Firefox.
                        </audio>
                        <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-8 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide8modal" tabindex="-1" role="dialog" aria-labelledby="slide8-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Requieres de tu tarjeta de débito o crédito para realizar una transacción. Asimismo, debes conocer tu pin o contraseña. Esta información es personal y se encuentra en el sobre en el que el Banco te entrega tu respectiva tarjeta</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-9 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide9modal" tabindex="-1" role="dialog" aria-labelledby="slide9-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Puedes acceder al cajero automático las 24 horas del día, 7 días a la semana.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-10 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide10modal" tabindex="-1" role="dialog" aria-labelledby="slide10-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Los cajeros tienen un límite diario de retiro de dinero por razones de seguridad.  Tómalo en cuenta por si necesitas utilizar más dinero del que puedes retirar.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-11 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide11modal" tabindex="-1" role="dialog" aria-labelledby="slide11-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>El uso de cajeros automáticos fuera del territorio nacional, tiene un costo de transacción. Verifica con tu entidad de intermediación financiera las limitaciones, requisitos y costos para el uso de tus tarjetas en el exterior.</p>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- SLIDE-12 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide12modal" tabindex="-1" role="dialog" aria-labelledby="slide12-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
     </button>
          <div class="modal-body py-5">
              <div class="row align-items-center justify-content-center">
                  <div class="col-12 text-justify">
                      <p>Transacciones disponibles en cajeros automáticos Dependiendo de la entidad financiera, los cajeros automáticos presentan, generalmente, las siguientes opciones de transacción:</p>
                      <ul>
                        <li>Retiro de fondos</li>
                        <li>Depósito de efectivo</li>
                        <li>Transferencias entre cuentas</li>
                        <li>Consulta de saldos</li>
                        <li>Consulta de los últimos movimientos</li>
                        <li>Cambio de PIN</li>
                      </ul>
                      <a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              <h1 class="modal-title text-white">¡FELICIDADES!</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body bg-module-completed">
              <div class="container-fluid center">
                  <div class="row justify-content-center mb-4">
                      <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                          <img class="img-1 w-75" src="assets/images/icons/advanced/5.png">
                          <h2 class="color-5 mt-2">COMPLETASTE EL MÓDULO 5</h2>
                      </div>
                  </div>
                  <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                      <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                          <img class="img-2" src="assets/images/module-5/quiz.svg">
                      </div>
                      <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                          <h4 class="color-5">Completa el QUIZ
                            <!-- <a aria-hidden="true" routerLink="quiz" data-dismiss="modal">aquí</a> -->
                            <a routerLink="/avanzado" data-dismiss="modal">aquí</a>
                          </h4>
                          <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                      </div>
                      <!-- <div class="col-9 text-center mt-2">
                          <p><small>No te olvides que por cada <strong class="color-5">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-5">una oportunidad más de ganar.</strong></small></p>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
