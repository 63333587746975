<header class="header">
    <div class="mx-xl-0 mx-md-0 mx-0">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="https://www.bancoecofuturo.com.bo/" target="_blank">
                <img class="img_click" src="assets/images/ecofuturo-new-logo.png">
            </a>
            <!-- <a class="" href="#">
                <img class="ml-3 img_ecofuturo" src="assets/images/click-logo.svg" width="120px">
            </a>-->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- boton -->


            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav text-lg-auto text-center">
                    <li class="nav-item btn">
                        <a class="call_center color-3 p-0 mx-1" href="tel:800103112" target="_blank"><img src="assets/images/icons/call-center.svg"> <strong>800103112</strong></a>
                        <a class="call_center color-3 p-0 mx-1" href="https://wa.me/59172222210?text=Más%20información" target="_blank"><img src="assets/images/icons/whatsapp.svg"> <strong>72222210</strong></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="modal" data-target="#aboutClick"><strong>¿Qué es CLICK?</strong></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/inicio" ><strong>Inicio</strong></a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/registro"><strong>Regístrate</strong></a>
                    </li> -->
                    <li class="nav-item" *ngIf="this.isAuth">
                        <a class=" nav-link user_header"><strong class="color-3">{{ (this.client.email.length>26)? (this.client.email | slice:0:26)+'..':(this.client.email) }}</strong> <img src="assets/images/icons/user-icon.svg"></a>
                    </li>
                    <li class="nav-item border_left" *ngIf="!this.isAuth">
                        <a class="btn bg-3 text-white py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>Inicia sesión</strong></a>
                    </li>
                    <li class="nav-item ml-4">
                        <a *ngIf="this.isAuth" class="btn bg-3 text-white py-2 px-4" (click)="logout()"><strong>Cerrar sesión</strong></a>
                    </li>
                    <li class="nav-item ml-4">
                        <a *ngIf="this.isAuth && this.client.role == 'Admin'" class="btn bg-3 text-white py-2 px-4" routerLink="/admin"><strong>Administrar</strong></a>
                    </li>

                </ul>
                <a class="logo_click d-block text-center" data-toggle="modal" data-target="#aboutClick">
                    <img class="img_ecofuturo my-lg-0 my-5" src="assets/images/click-logo.svg" width="180px">
                </a>
            </div>
            <!-- <a (click)="encryptText()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>Encript</STRONG></a> -->
        </nav>
    </div>
</header>

<ngx-spinner
    id="spinner"
    bdColor="rgba(255,255,255)"
    size="medium"
    color="#FF5000"
    type="ball-scale-multiple"
>
    <p id="text-spinner" style="font-size: 20px; color: #FF5000">{{ this.textInitSession }}</p>
</ngx-spinner>

<!-- PRE REGISTER POP UP -->
<form
                            autocomplete="off"
                            [formGroup]="PreRegisterForm"
                            (ngSubmit)="crearUsuario()"
                            >
<div class="modal fade register-modal popup_form" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">Regístrate</h1>
                <button id="preregister-modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-4 text-center">
                            <img class="register_logo" src="assets/images/logo-click.svg">
                        </div>

                        <div class="col-md-8">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Usuario:</label>
                                    <input type="email" required formControlName="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingresa tu Correo Electrónico" maxlength="50">
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-lg-6">
                                        <label for="exampleInputPassword1">Contraseña:</label>
                                        <input type="password" required formControlName="password" class="form-control" id="exampleInputPassword1" placeholder="Crea una Contraseña" maxlength="50">
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="exampleInputPassword1">Verificación:</label>
                                        <input type="password" required formControlName="confirmPassword" class="form-control" id="exampleInputPassword1" placeholder="Repite tu Contraseña" maxlength="50">
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="exampleInputPassword1">N° de Doc. de Identificación:</label>
                                    <input type="text" required formControlName="ci" class="form-control" id="exampleInputPassword1" placeholder="N° de Doc. de Identificación:" maxlength="30" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                </div> -->
                                <div class="form-group">
                                    <label for="exampleInputPassword1">N° de Doc. de Identificación:</label>
                                    <input type="text" required formControlName="ci" class="form-control" id="exampleInputPassword1" (keypress)="keyPressNumeric($event)" placeholder="N° de Doc. de Identificación:" maxlength="30" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                </div>

                                <div class="form-row">
                                    <div class="col-lg-6">
                                        <label for="exampleInputPassword1">Complemento: <br><small class="color-4">(En caso de tener, por ejemplo: 03 o L1)</small></label>
                                        <input type="text" formControlName="ciComplement" (keypress)="keyPressAlphaNumeric($event)" class="form-control" id="exampleInputPassword1" placeholder="Complemento opcional" maxlength="2" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="exampleInputPassword1">Lugar de expedición: </label>
                                        <select formControlName="ciExpedition" id="inputState" class="form-control">
                                            <option selected value="">Selecciona una opción</option>
                                            <option>CH</option>
                                            <option>LP</option>
                                            <option>CB</option>
                                            <option>OR</option>
                                            <option>PT</option>
                                            <option>TJ</option>
                                            <option>SC</option>
                                            <option>BE</option>
                                            <option>PD</option>
                                            <option value="">Sin Lugar de Expedición</option>
                                        </select>
                                        <!-- <input type="text" required formControlName="ciExpedition" class="form-control" id="exampleInputPassword1" placeholder="Lugar de expedición" maxlength="5" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">                                     -->
                                    </div>
                                </div>
                                <div class="form-row mt-3">
                                    <ngx-recaptcha2
                                        siteKey="{{ this.siteKey }}"
                                        useGlobalDomain="false"
                                        formControlName="recaptcha">
                                    </ngx-recaptcha2>
                                </div>
                                <div class="form-group mt-3">
                                    <p class="gray-text">¿Ya tienes una cuenta? Haz click <strong class="btn-other-modal" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target=".login-modal">aquí</strong>
                                        <!-- <br>Recuperación de usuario y contraseña <i class="fa fa-question-circle" aria-hidden="true"></i> -->
                                    </p>
                                </div>
                                <div class="form-group" style="display:none;">
                                    <a class="color-3" data-toggle="modal" data-target=".terms-modal" id="aceppt_terms">Aceptar Términos y Condiciones</a>
                                </div>
                                <div>
                                    <p class="text-danger" *ngIf="campoNoValido('recaptcha')">Necesita verificar el código captcha.</p>
                                    <p class="text-danger" *ngIf="campoNoValido('password')">Mínimo 6 caracteres para la contraseña</p>
                                    <p class="text-danger" *ngIf="campoNoValido('email')">El email/usuario es obligatorio.</p>
                                    <p class="text-danger" *ngIf="contrasenasInvalidas()">Las contraseñas deben coincidir.</p>
                                    <p class="text-danger" *ngIf="campoNoValido('ci')">Mínimo 5 caracteres para el N° de Doc. de Identificación.</p>
                                </div>
                                <!-- <button type="submit" class="btn btn-outline-primary btn-form color-3">Crear Cuenta</button><br><br> -->
                                <a (click)="acceptTerms()" class="btn btn-outline-primary btn-form color-3">Crear Cuenta</a><br><br>
                                <a id="preregister-modal" data-toggle="modal" data-target=".pre-register-completed"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- TERMS AND CONDITIONS POP UP -->
<div class="modal fade terms-modal popup_form" tabindex="-1" role="dialog" aria-labelledby="loginModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title text-white">Términos y Condiciones</h3>
                <button id="btn-close-terms" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearData()">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 text-center my-lg-3">
                            <p>Confirmo que mi Número de Documento de Identificación es correcto y verificable para participar de la promoción empresarial.</p>
                        </div>
                        <button id="close_terms" type="button" class="close" data-dismiss="modal" aria-label="Close" style="display:none;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="col-md-6 text-center">
                            <a class="btn btn-outline-primary btn-form color-3" data-dismiss="modal">Rechazar</a>
                        </div>
                        <div class="col-md-6 text-center">
                            <button type="submit" class="btn btn-outline-primary btn-form text-white bg-3">Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</form>

<!-- LOGIN POP UP -->
<div id="clear-modal" class="modal fade login-modal popup_form" tabindex="-1" role="dialog" aria-labelledby="loginModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">Inicia sesión</h1>
                <button id="btn-close" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearData()">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-5 text-center">
                            <img class="login_logo" src="assets/images/logo-click.svg">
                        </div>
                        <div class="col-md-7">
                            <form
                            autocomplete="off"
                            [formGroup]="loginForm"
                            (ngSubmit)="loginTwo()"
                            >
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Usuario:</label>
                                    <input type="email" formControlName="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingresa tu Correo Electrónico" maxlength="50">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Contraseña:</label>
                                    <input type="password" formControlName="password" class="form-control" id="exampleInputPassword1" placeholder="Ingresa tu Contraseña" maxlength="50">
                                </div>
                                <div class="form-group">
                                    <p class="gray-text">
                                        ¿No tienes una cuenta? Regístrate <strong class="btn-other-modal" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target=".register-modal">aquí</strong>
                                        <br>
                                        Recuperación de usuario y contraseña <i class="fa fa-question-circle btn-other-modal" data-dismiss="modal" data-toggle="modal" data-target=".reset-password-modal" aria-hidden="true"></i>
                                    </p>
                                    <p class="gray-text"></p>
                                </div>
                                <p class="text-danger" *ngIf="campoLoginNoValido('email')">Ingrese un email válido.</p>
                                <p class="text-danger" *ngIf="campoLoginNoValido('password')">Password Requerido.</p>
                                <button type="submit" class="btn btn-outline-primary btn-form color-3 mt-2">Iniciar Sesión</button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FORGOT PASSWORD REQUEST POP UP -->
<div class="modal fade reset-password-modal popup_form" tabindex="-1" role="dialog" aria-labelledby="loginModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">Recuperación de Contraseña</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-5 text-center">
                            <img src="assets/images/logo-click.svg">
                        </div>
                        <div class="col-md-7">
                            <form [formGroup]="accountRecuperation" (ngSubmit)="recuperarCuenta(accountRecuperation.value)">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Correo Electrónico:</label>
                                    <input formControlName="emailRecuperation" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingresa tu Correo Electrónico">
                                </div>
                                <div class="form-group">
                                    <p class="color-1"><small><strong>* Se enviará un mensaje con un enlace de activación a su correo electrónico para actualizar su contraseña.</strong></small></p>
                                </div>
                                <button type="submit" class="btn btn-outline-primary btn-form color-3">Enviar Solicitud</button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- PRE-REGISTER COMPLETED-->
<div class="modal fade pre-register-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡Pre-registro Completado!</h1>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 mb-4" src="assets/images/icons/register-completed.svg" width="40%"><br><br>
                            <p>Se ha enviado un mensaje y un link a tu correo electrónico para verificarlo. Haz click en el enlace del mensaje e Inicia Sesión.</p>
                            <!-- <a class="btn bg-3 text-white px-5 mt-3" data-dismiss="modal" routerLink="/inicio"><strong>Ir al Inicio</strong></a> -->
                            <a class="btn bg-1 text-white px-5 color-3" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ABOUT CLICK-->
<div class="modal fade" id="aboutClick" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close click" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body">
                <div class="row p-2 p-md-4 p-xl-5">
                    <div class="col text-justify">
                        <div class="center text-center">
                            <img class="mb-5 w-50 center" src="assets/images/click-logo.svg">
                        </div>
                        <h3 class="color-3 text-center mt-2 mb-3">¿Qué es CLICK Financiero?</h3>
                        <p>CLICK Financiero es la plataforma virtual de Educación Financiera de Banco PYME Ecofuturo, de registro y participación gratuita, cuyo propósito es proporcionar herramientas financieras relevantes a clientes y consumidores financieros en general, de manera que puedan adquirir los conocimientos necesarios para la mejor toma de decisiones financieras. Este aspecto contribuye a su vez a la bancarización y al incremento de confianza en el Sistema Financiero Boliviano.</p>
                        <p>La plataforma, que consta de 5 módulos sobre distintos temas financieros, es interactiva y dinámica, coadyuvando a un aprendizaje fácil y eficiente.</p>
                        <h4 class="color-3">¿Cuáles son los objetivos de CLICK?</h4>
                        <ul>
                            <li><p>Informar sobre las características y condiciones principales intermediación financiera y los servicios financieros complementarios, de manera que los consumidores elijan el producto o servicio que mejor se ajuste a sus necesidades y posibilidades.</p></li>
                            <li><p>Difundir los derechos y obligaciones que tienen los consumidores financieros ante los servicios y productos ofrecidos por las entidades de intermediación financiera.</p></li>
                            <li><p>Comunicar acerca de todos los mecanismos y canales de consultas y reclamos, de forma que el consumidor financiero pueda recurrir al Banco para absolver cualquier aspecto en todo momento.</p></li>
                            <li><p>Compartir información financiera de forma clara, completa, y oportuna.</p></li>
                            <li><p>Finalmente, brindar a clientes y consumidores financieros una experiencia de educación diferente e innovadora, con el fin de que aprendan rápido y puedan tomar decisiones financieras informadas y seguras.</p></li>
                        </ul>
                        <h4 class="color-3">Estructura de la Plataforma Virtual:</h4>
                        <p>La Plataforma cuenta con 5 módulos de distintos temas financieros (1. Sistema Financiero, el Rol de la ASFI y Alcance de la Normativa, 2. Ahorro, 3. Créditos, 4. Instrumentos Electrónicos de Pago y 5. Puntos de Atención Financiera). Se debe comenzar el curso por el primer módulo y concluirlo para poder continuar con los siguientes módulos. Al finalizar cada módulo habrá un cuestionario “Quiz”, para medir los conocimientos adquiridos por el usuario en dicha sección. Si el módulo y el quiz son concluidos satisfactoriamente, se le proporcionará un cupón virtual para participar del sorteo de varios premios que realizará Banco PYME Ecofuturo. Las fechas, términos y condiciones del sorteo se encuentran en la página de inicio de la plataforma.</p>
                        <h5 class="color-1 text-center my-2">¡Aprende y gana a través de ésta increíble plataforma que hemos desarrollado para ti!</h5>
                        <div class="my-5">
                            <a class="color-2 text-center mb-2" href="https://www.asfi.gob.bo/index.php/2017-06-02-20-21-19/derechos-del-cliente-y-usuarios-financieros.html" target="_blank"><i class="fa fa-external-link-square" aria-hidden="true"></i> DERECHOS Y OBLIGACIONES DEL CONSUMIDOR FINANCIERO</a>
                            <br><br>
                            <a class="color-2 mb-3 text-center" href="https://youtu.be/RsApN2vHxxo" target="_blank"><i class="fa fa-external-link-square" aria-hidden="true"></i> PUNTO DE RECLAMO </a>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

