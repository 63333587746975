<app-header></app-header>

<swiper [config]="config">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Seguros</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Conoce sobre los Seguros</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center vh-custom text-center">
                    <div class="col-12">
                      <h2 class="color-4 wow bounceIn mb-4" data-wow-delay = ".5s">Conoce sobre los Seguros</h2>
                      <p class=" wow bounceInDown" data-wow-delay="1s">Es importante conocer más a detalle las definiciones respectivas al tema de Seguros, así como los tipos de seguros que ofrecen las entidades financieras.</p>
                      <div class="mx-5 wow bounceInDown" data-wow-delay="1.5s">
                        <h4 class="text-left color-4 wow bounceInDown" data-wow-delay="1s">Seguro</h4>
                        <p class="wow bounceInDown" data-wow-delay="1s">Es el contrato por el cual la Entidad Aseguradora se obliga a indemnizar un daño o a cumplir la prestación convenida, al producirse la eventualidad prevista y el asegurado o Tomador del Seguro a pagar la prima.</p>
                        <h4 class="text-left color-4">Tomador del Seguro</h4>
                        <p>Entidad supervisada que por cuenta y a nombre de sus clientes contrata con la Entidad Aseguradora el seguro colectivo.</p>
                        <h4 class="text-left color-4">Asegurado</h4>
                        <p>Es el cliente de la entidad supervisada que acepta su adhesión al seguro colectivo contratado por el Tomador del Seguro.</p>
                        <h4 class="text-left color-4">Beneficiario</h4>
                        <p>Es la persona designada por el asegurado para recibir el beneficio de la indemnización en caso de siniestro. El Beneficiario puede ser el asegurado, personas designadas por el asegurado o la misma entidad supervisada, según el seguro que corresponda.</p>
                        <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <a class="btn bg-4 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
                    </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 2 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Seguros</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Seguro de Desgravamen</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-4 pb-4 wow bounceIn" data-wow-delay = ".5s">Seguro de Desgravamen</h2>
                          <p class="mb-4 text-left wow bounceInDown" data-wow-delay = "1s">El mismo tiene por objeto cubrir el saldo de la deuda del cliente financiero, en caso de fallecimiento y/o invalidez total o parcial, liberando a los familiares y/o beneficiarios de este pago. El seguro incluye gastos de sepelio y cobertura por desempleo.</p>
                          <div class="row align-items-center justify-content-center mb-4">
                              <div class="col-4">
                                <div class="wow bounceInDown ml-auto img-container image-xl d-flex gap-2 align-items-center justify-content-center text-left" data-wow-delay = "1.5s">
                                  <img src="assets/images/advanced/chapter4/1.jpg">
                                </div>
                              </div>
                              <div class="col-8">
                                  <div class="wow bounceInDown text-left" data-wow-delay = "1.5s">
                                    <p>La aprobación del Seguro podrá ser automática al llenado del Formulario de Solicitud de Seguro y Declaración de Salud.</p>
                                    <p>En caso de que el riesgo requiera de evaluación y no sea aprobado de manera automática, el pronunciamiento de la Entidad Aseguradora no podrá exceder los 5 (cinco) días hábiles de haber sido recibido los formularios de Solicitud de Seguro y Declaración de Salud, entendiéndose aceptada la solicitud de no pronunciarse en dicho plazo.</p>
                                    <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 25%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:25.5%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 3 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Seguros</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Seguro de Desgravamen</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-4 pb-4 wow bounceIn" data-wow-delay = ".5s">Seguro de Desgravamen</h2>
                          <p class="mb-4 text-justify wow bounceInDown" data-wow-delay = "1s">Si la Entidad Aseguradora para su pronunciamiento requiera previamente de exámenes médicos sobre el estado de salud del solicitante del Seguro de Desgravamen Hipotecario, deberá solicitar los mismos en el plazo de los cinco (5) días hábiles establecidos para su pronunciamiento computables de la fecha de recepción del Formulario de Solicitud de Seguro y Declaración de Salud.</p>
                          <p class="mb-4 text-justify wow bounceInDown" data-wow-delay = "1.5s">Una vez conocidos los resultados de los exámenes médicos, la Entidad Aseguradora en el plazo de diez (10) días hábiles computables a partir de la recepción de los mismos, deberá pronunciarse comunicando de manera expresa la aceptación, o el establecimiento de condiciones particulares de aseguramiento para el caso, o el rechazo.</p>
                          <p class="mb-4 text-justify wow bounceInDown" data-wow-delay = "1.5s">La falta de dicha comunicación significará la tácita aceptación de la Entidad Seguradora.</p>
                          <p class="mb-0 text-right wow bounceInDown" data-wow-delay = "1.5s">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
                      </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 50%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.5%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 4 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Seguros</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Seguro de Garantías Hipotecarias</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-4 pb-4 wow bounceIn" data-wow-delay = ".5s">Seguro de Garantías Hipotecarias</h2>
                          <div class="row">
                            <div class="col-8">
                              <p class="mb-0 text-justify wow bounceInDown" data-wow-delay = "1s">Estos seguros tienen por objeto brindar cobertura a las garantías ofrecidas y entregadas por los clientes para la obtención de créditos, contamos con dos pólizas: Una de Todo Riesgo que abarca bienes e inmuebles y maquinarias, la segunda es una póliza de automotor que abarca todo tipo de vehículos livianos y pesados, con estas coberturas las garantías quedan aseguradas ante cualquier eventualidad que pueda suceder. </p>
                            </div>
                            <div class="col-4 wow bounceInDown" data-wow-delay = "1.5s">
                              <div class="img-container image-xl mr-auto">
                                <img src="assets/images/advanced/chapter4/2-1.png" class="doc-icon">
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

                <app-controls ngClass="chapter4"></app-controls>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 75%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:75.5%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
        <!-- SLIDE 5 -->
        <div class="swiper-slide">
            <section class="module">

              <div class="px-5 py-3 module_header text-left">
                <ol class="justify-content-start">
                  <li class="light-text">
                      <h2 class="color-4">Seguros</h2>
                  </li>
                  <li>
                      <h2 class="color-4">Seguro de Protección de Tarjetas de Crédito</h2>
                  </li>
                </ol>
              </div>

                <div class="container module_body">
                  <div class="row content_center text-center">
                      <div class="col-12">
                          <h2 class="color-4 pb-4 wow bounceIn" data-wow-delay = ".5s">Seguro de Protección de Tarjetas de Crédito</h2>
                          <div class="row">
                            <div class="col-4 wow bounceInDown" data-wow-delay = "1s">
                              <div class="img-container image-xl ml-auto">
                                <img src="assets/images/advanced/chapter4/3-1.png" class="doc-icon">
                              </div>
                            </div>
                            <div class="col-8">
                              <p class="mb-4 text-justify wow bounceInDown" data-wow-delay = "1.5s">Es un seguro que tiene por objeto proteger el uso de las tarjetas, ante posibles hechos de asalto, robo y delitos relacionados. La cobertura implica que, ante algún siniestro, el daño económico es cubierto por la aseguradora.</p>
                              <p class="mb-4 text-justify wow bounceInDown" data-wow-delay = "1.5s">El costo de este seguro viene a ser acorde al crédito al que accede la persona y le permitirá tener la tranquilidad de que, en caso de sufrir un siniestro; los daños económicos que se sufran serán cubiertos.</p>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-4">
                      <a class="btn bg-4 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s" (click)="activeWow()">ANTERIOR</a>
                      <a *ngIf="this.finalModule == false" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
                      <a *ngIf="this.finalModule == true" class="btn bg-4 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s" (click)="goToHome()">IR AL INICIO</a>
                  </div>
                </div>

                <div class="row module_progress align-items-center">
                    <div class="col">
                      <div class="progress">
                        <div class="progress-bar bg-4 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="1"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-4/flechas.svg">
                      </div>
                    </div>
                    <div class="col-md-1 col-3 wow pulse" data-wow-delay = "3s" data-wow-iteration="infinite">
                        <img src="assets/images/module-4/phone.svg" class="doc-icon">
                    </div>
                </div>

                <app-circles></app-circles>

            </section>
        </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE DÉBITO</h4>
                        <p>Es un IEP que se le otorga al cliente, de forma gratuita, cuando obtiene una cuenta de ahorro o una cuenta corriente. Con ella se pueden realizar pagos, retiros en efectivo, rescate de cuotas y consultar los movimientos de la cuenta bancaria. El dinero es debitado del saldo de la cuenta asociada. </p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">TARJETA DE CRÉDITO</h4>
                        <p>Es un IEP que dispone el cliente al cual el Banco ha otorgado un límite de crédito en respuesta a su solicitud. A través de esta tarjeta se pueden realizar compras o extraer efectivo hasta el límite aprobado. A diferencia de la tarjeta de débito, en vez de debitarse de la cuenta, se utiliza un monto del dinero prestado, mismo que se debe pagar de forma periódica con intereses.  Por esta razón, antes de conceder una tarjeta de crédito, el banco estudia la viabilidad del cliente, asegurándose de que sea solvente.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-2-3.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12">
                        <h4 class="color-4">TARJETA PREPAGADA</h4>
                        <p>Es un IEP que dispone de un monto de dinero almacenado, pagado previamente a su emisor. Con ella se realizan pagos hasta el valor del importe cargado.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/step-3-1.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">BANCA ELECTRÓNICA</h4>
                        <p>Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de un dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <p>La afiliación al servicio es gratuita y difiere de entidad a entidad. Consulta con el banco de tu preferencia para averiguar los requisitos.</p>
                        <p>Se pueden realizar distintas acciones:</p>
                        <ul>
                            <li>Consultar Saldos y movimientos</li>
                            <li>Transferencias entre cuentas propias, a cuentas de terceros o interbancarias, es decir a otros bancos.</li>
                            <li>Pago de Préstamos</li>
                            <li>Pago de servicios</li>
                            <li>Solicitar ayuda</li>
                            <li>Información sobre los Puntos de Atención</li>
                        </ul>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/cajero.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">CAJERO AUTOMÁTICO:</h4>
                        <p>Un Cajero Automático es un ordenador, una máquina, empleada por una entidad financiera que permite realizar operaciones financieras, entre las que destacan el ingreso o retiro de dinero en efectivo, sin la necesidad de la presencia de un empleado de un banco.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-6 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide6modal" tabindex="-1" role="dialog" aria-labelledby="slide6-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-5 col-12">
                        <img src="assets/images/module-4/step-5-3.svg" width="80%">
                    </div>
                    <div class="col-lg-7 col-12 text-left">
                        <h3 class="color-4">ÓRDENES ELECTRÓNICAS DE TRANSFERENCIA DE FONDOS</h3>
                    </div>
                    <div class="col-lg-11 col-12">
                        <h4 class="color-4">BANCA MÓVIL:</h4>
                        <p class="text-justify">Es un servicio de banca electrónica proporcionado por una entidad financiera. Permite a los clientes realizar una serie de transacciones financieras de forma digital mediante el uso de dispositivo móvil (celular, tableta, etc.) y un software (aplicación) del banco. Normalmente está disponible las 24 horas del día, 7 días a la semana.</p>
                        <h4 class="color-4">ECONET:</h4>
                        <p class="text-justify">EcoNet, es una plataforma que proveé un servicio digital a los clientes, a través de una aplicación o de la Página Web del Banco, con la cual pueden realizar operaciones bancarias de forma ágil, sencilla y segura.</p>
                        <p class="text-justify">EcoNet, además de las transacciones generales previamente mencionadas, tiene disponible el P2P (elemento innovador del Banco a través del cual se pueden hacer transferencias entre cuentas utilizando únicamente el número del teléfono móvil del receptor) y finalmente, SIMPLE (que permite realizar operaciones bancarias a través del uso de un código QR).</p>
                        <div class="center text-center mt-3">
                            <a class="btn bg-4 text-white px-4" href="https://www.bancoecofuturo.com.bo/sitio/canales-contenido/69" target="_blank"><strong>HAZ CLICK AQUÍ</strong></a>
                            <p class="mt-1">Si requieres más información sobre EcoNet.</p>
                        </div>
                        <a class="btn bg-4 text-white px-5 mt-4 text-right" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SLIDE-7 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide7modal" tabindex="-1" role="dialog" aria-labelledby="slide7-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
            <div class="modal-body py-4">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-6 text-center">
                        <img class="animated infinite wobble" src="assets/images/module-4/2.svg" width="80%">
                    </div>
                    <div class="col-lg-8 col-12 text-justify">
                        <h4 class="color-4">Billetera Móvil:</h4>
                        <p>El servicio de Billetera Móvil para transferir recursos desde un celular es prestado por diferentes empresas de telecomunicaciones, como ser Entel, etc. Este aspecto debe ser consultado con el Banco que ofrezca el servicio de Billetera Móvil.</p>
                        <p>Se trata de un servicio cómodo, ágil y rápido, que no requiere un saldo mínimo de apertura, cuenta con un costo de las transacciones accesible y en algunos casos gratuito y está disponible para cualquier persona.</p>
                        <a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title text-white">¡FELICIDADES!</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body bg-module-completed">
                <div class="container-fluid center">
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
                            <img class="img-1 w-75" src="assets/images/icons/advanced/4.png">
                            <h2 class="color-4 mt-2">COMPLETASTE EL MÓDULO 4</h2>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
                            <img class="img-2" src="assets/images/module-4/quiz.svg">
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
                            <h4 class="color-4">Completa el QUIZ
                              <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                              <a routerLink="/avanzado/modulo-5" data-dismiss="modal">aquí</a>
                            </h4>
                            <!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
                        </div>
                        <!-- <div class="col-9 text-center mt-2">
                            <p><small>No te olvides que por cada <strong class="color-4">módulo que termines,</strong> obtienes un cupón más, es decir, <strong class="color-4">una oportunidad más de ganar.</strong></small></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
