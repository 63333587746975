import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { PreRegisterForm } from '../interfaces/preregister-form.interface';
import { LoginForm } from '../interfaces/login-form.interface';
import { Client } from '../models/client.model';
import { RegisterForm } from '../interfaces/register-form.interface';
import * as CryptoJS from 'crypto-js';

const base_url     = ( environment.base_url ).replace('%E2%80%8B', '');
const publicToken  = environment.publicToken;
const appUserId    = environment.appUserId;
const authorizationBasic = environment.authorizationBasic;
const userAplication     = environment.userAplication;
const passwordAplication = environment.passwordAplication;
const tokenTimeExpiration = 525600;
const timeLoginErrorExpiration = 30;
const headers = {
  'Content-Type':'application/json',
  'Authorization' : `Basic ${authorizationBasic}`,
  'Cache-Control' : 'no-cache, no-store, must-revalidate',
};

@Injectable({
    providedIn: 'root'
})

export class ClientService {

    public auth2: any;
    public usuario: Client
    
  
    constructor( private http: HttpClient, 
                  private router: Router,
                  private ngZone: NgZone ) {
  
    }
  
    crearUsuario( formData: PreRegisterForm ) {
      formData.publicToken = publicToken;
      formData.appUserId   = appUserId;
      var ciComplement         = formData.ciComplement || '';
      formData.ci              = this.encrypt( (`${( formData.ci ).toString()} ${ciComplement}`).trim() );
      formData.ciExpedition    = this.encrypt( formData.ciExpedition );
      formData.email           = this.encrypt( formData.email );
      formData.password        = this.encrypt( formData.password );
      formData.confirmPassword = this.encrypt( formData.confirmPassword );
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/PreRegister', formData, { headers: headers } )
              .pipe(
                tap( (resp: any) => {
                  localStorage.setItem('token', resp.token )
                })
              )
    }

    completarRegistroUsuario( formData: RegisterForm, disabilityString ) {
      const headers = {
        'Content-Type':'application/json',
        'Authorization' : `Bearer ${localStorage.getItem('token') || ''}`,
        'Cache-Control' : 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
      };
      formData.cellPhone = ( formData.cellPhone ).toString();
      formData['disability'] = formData.disability_condition == 'si' ? true : false;
      formData.passwordAplication = passwordAplication;
      formData.publicToken = publicToken;
      formData.appUserId   = appUserId;
      formData.userAplication = userAplication;
      formData.referencePhone = ( formData.referencePhone ).toString();
      formData['typeDisability'] = disabilityString;

      formData.email          = this.encrypt(formData.email);
      formData.nameComplete   = this.encrypt(formData.nameComplete);
      formData.gender         = this.encrypt(formData.gender);
      formData.department     = this.encrypt(formData.department);
      formData.city           = this.encrypt(formData.city);
      formData.address        = this.encrypt(formData.address);
      formData.cellPhone      = this.encrypt(formData.cellPhone);
      formData.phone          = this.encrypt(formData.phone);
      formData.educationLevel = this.encrypt(formData.educationLevel);
      formData.referenceName  = this.encrypt(formData.referenceName); 
      formData.referencePhone = this.encrypt(formData.referencePhone);
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/ClientService/RegistrationComplete', formData, { headers: headers } )
      .pipe(
        tap( (resp: any) => {
        })
      );
    }

    encrypt( field ) {
      var key = CryptoJS.enc.Utf8.parse('7061737323313233');
      var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(field), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    }
  
    login( formData: LoginForm ) {
      formData['appUserId'] = appUserId;
      formData['publicToken'] = publicToken;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Cache-Control' : 'no-cache, no-store, must-revalidate',
          Authorization: `Base ${authorizationBasic}`,
          'Pragma': 'no-cache',
        }),
          withCredentials: true
      };

      formData.email    = this.encrypt(formData.email);
      formData.password = this.encrypt(formData.password);
      var now = new Date();
      now.setMinutes( now.getMinutes() + tokenTimeExpiration ).toString();
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/ClientService/Authenticate', formData, httpOptions )
        .pipe(
          tap( (resp: any) => {
            localStorage.setItem('token', resp['data'].token );
            localStorage.setItem('date_expiration', now.toString()  );
            localStorage.setItem('email',  resp['data'].email );
          }),
        catchError( error => {
          return of(error);
        } )
      );
  
    }

      // Revoke Token no funciona
    logout() {
      var email = localStorage.getItem('email') || '';
      var token = localStorage.getItem('token') || '';
      var headers = {
        'Content-Type':'application/json',
        'Authorization' : `Bearer ${token}`,
        'Cache-Control' : 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
      };
        // Aumentar refresh token
      var formData = {
        'publicToken'        : publicToken,
        'appUserId'          : appUserId,
        'email'              : email,
        'token'              : 'token',
        'userAplication'     : userAplication,
        'passwordAplication' : passwordAplication
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Token/RevokeToken', formData, { headers: headers } )
      .pipe(
        tap( (resp: any) => {
          localStorage.removeItem( 'email' );
          localStorage.removeItem( 'date_expiration' );
          localStorage.removeItem( 'token' );
        }),
      catchError( error => {
        return of(error);
      } )
    );
    }

    obtenerUsuario() {
      var token = localStorage.getItem('token') || '';
      var email = localStorage.getItem('email') || '';
      var headers = {
        'Content-Type':'application/json',
        'Authorization' : `Bearer ${token}`,
        'Cache-Control' : 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
      };

      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId,
        'email'       : email,
        'userAplication' : userAplication,
        'passwordAplication' : passwordAplication
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/ClientService/MyInformation', formData, { headers: headers })
      .pipe(
        catchError( error => {
          return of(false);
      } ) )
    }

    refreshToken( dateNow ) {
      var email = localStorage.getItem('email') || '';
      dateNow.setMinutes( dateNow.getMinutes() + tokenTimeExpiration ).toString();
      var headerRefresh = {
        'Content-Type':'application/json',
        'Authorization' : `Basic ${authorizationBasic}`
      };
      var formDataRefresh = {
        'publicToken' : publicToken,
        'appUserId' : appUserId,
        'email' : email
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Token/RefreshToken', formDataRefresh, { headers: headerRefresh } )
      .pipe(
        tap( (resp: any) => {
          localStorage.setItem('token', resp['data'].token );
          localStorage.setItem('date_expiration', dateNow.toString()  );
          localStorage.setItem('email',  resp['data'].email );
        })
      );
    }
    
    obtenerQuiz( numeroQuiz ) {
      var headers = {
        'Content-Type':'application/json',
        'Authorization' : `Bearer ${localStorage.getItem('token') || ''}`,
        'Cache-Control' : 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
      };

      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId,
        'moduleNumber'   : numeroQuiz,
        'email'       : localStorage.getItem('email') || '',
        'userAplication' : userAplication,
        'passwordAplication' : passwordAplication
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/ClientService/QuestionsAndAswers', formData, {
        headers: headers
      });
    }

    guardarQuiz( numeroQuiz ) {
      var headers = {
        'Content-Type':'application/json',
        'Authorization' : `Bearer ${localStorage.getItem('token') || ''}`,
        'Cache-Control' : 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
      };
      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId,
        'userAplication' : userAplication,
        'passwordAplication' : passwordAplication,
        'email'       : localStorage.getItem('email') || '',
        'moduleNumber'   : numeroQuiz
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/ClientService/AnswerQuestion', formData, {
        headers: headers
      });
    }

    obtenerUsuarios() {
      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId
      }
      // /api/v1/Administration/GetWinners
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/GetWinners', formData, {
        headers: headers
      });
    }

    verificarEmail( token ) {
      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId,
        'tokenEmailVerify': token
      }
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/VerifyEmail', formData, {
        headers: headers
      });
    }

    recuperarCuenta( email ) {
      var formData = {
        'publicToken' : publicToken,
        'appUserId'   : appUserId,
        'email': email
      }
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/ForgotPassword', formData, {
        headers: headers
      });
    }

    cambiarPassword( data, token ) {
      var formData = {
        'publicToken'     : publicToken,
        'appUserId'       : appUserId,
        'token'           : token,
        'email'           : data['email'],
        'password'        : data['password'],
        'confirmPassword' : data['confirmPassword'],
      }
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/ResetPassword', formData, {
        headers: headers
      });
    }

    descargarCertificado( nombre ) {
      var formData = {
        'publicToken'     : publicToken,
        'appUserId'       : appUserId,
        "email"           : localStorage.getItem('email'),
        'certificateParameters' : [
          {
            "label": "",
            "value": nombre,
            "fontSizeLabel": 0,
            "fontSize": 25,
            "verticalTextDirection": 354,
            "horizontalTextDirection": 410
          }
        ]
      }
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/SendCertificate', formData, {
        headers: headers
      });
    }

    refrescarToken() {
      var formData = {
        "publicToken": publicToken,
        "appUserId": appUserId,
        "email": localStorage.getItem('email') || ''
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: `Basic ${authorizationBasic}`,
          // 'Cache-Control' : 'no-cache, no-store, must-revalidate',
          // 'Pragma': 'no-cache',
        }),
          withCredentials: true
      };
      var now = new Date();
      now.setMinutes( now.getMinutes() + tokenTimeExpiration ).toString();
      // return;
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Token/RefreshToken', formData, httpOptions)
      .pipe(
        tap( (resp: any) => {
          localStorage.setItem('token', resp['data'].token );
          localStorage.setItem('date_expiration', now.toString());
        }),
        catchError( error => {
          return of(error);
        } )
      );
    }

    revokeToken() {
      var formData = {
        "publicToken"         : publicToken,
        "appUserId"           : appUserId,
        "email"               : localStorage.getItem('email') || '',
        "userAplication"      : userAplication,
        "passwordAplication"  : passwordAplication
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
          'Cache-Control' : 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
        }),
          withCredentials: true
      };
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Token/RevokeToken', formData, httpOptions);      
    }

    eliminarUsuario( email ) {
      var formData = {
        'publicToken'     : publicToken,
        'appUserId'       : appUserId,
        "email"           : email,
      }
      return this.http.post('https://192.168.0.8/WebApi_Financial_Education_Ecofuturo/api/v1/Administration/DeleteRegister', formData, {
        headers: headers
      });
    }
}