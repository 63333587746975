import { Component, OnInit } from '@angular/core';
import { Client} from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';
import { NgxSpinnerService } from "ngx-spinner";
import { concatMap, switchMap } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { NgwWowService } from 'ngx-wow';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  stagger,
  query,
  keyframes
} from '@angular/animations';
import * as CryptoJS from 'crypto-js';
declare function stopVideo();

@Component({
  selector: 'app-home-intermediate',
  templateUrl: './home-intermediate.component.html',
  styleUrls: ['./home-intermediate.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '100px',
        opacity: 1,
        backgroundColor: '#ff2626',
        color: '#ffffff',
      })),
      state('closed', style({
        height: '100px',
        opacity: 1,
        backgroundColor: 'green',
        color: '#ffffff'
      })),
      transition('open => closed', [
        animate('2s', keyframes([
          style({ backgroundColor: 'blue', offset: 0}),
          style({ backgroundColor: 'red', offset: 0.8}),
          style({ backgroundColor: 'orange', offset: 1.0})
        ])),
      ]),
      transition('closed => open', [
        animate('2s', keyframes([
          style({ backgroundColor: 'orange', offset: 0}),
          style({ backgroundColor: 'red', offset: 0.2}),
          style({ backgroundColor: 'blue', offset: 1.0})
        ])),
      ]),
    ]),
  ]
})
export class HomeIntermediateComponent implements OnInit {
  isOpen = true;
  certificate = false;
  toggle() {
    this.isOpen = !this.isOpen;
  }

  public contentUser = {};
  public client = new Client('', '');
  public isAuth: boolean = false;
  public probar = true;
  public openModal = true;
  public finishedModules = {};
  constructor( private router: Router,private wowService: NgwWowService, private clientService: ClientService, private spinnertwo: NgxSpinnerService ) {
    this.wowService.init();
  }

  config: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 5000
    }
  };

  allcompleted = false;
  seccion:boolean;

  ngOnInit(): void {
    // this.openModal = history.state[0] || false;
    // var el = document.getElementsByClassName("delete-this")[0];
    // if( typeof el != 'undefined' ) {
    //   el.remove();
    // }
    // if( this.openModal ) {
    //   var btnInit = document.getElementById('btn-init') as HTMLElement;
    //   btnInit.click();
    // }
    // var now = new Date();
    // var isTrue = true;
    // //
    // if( now.getTime() > Date.parse( localStorage.getItem('date_expiration') ) && localStorage.getItem('date_expiration') != null ) {
    //   const requests = [
    //     this.clientService.refrescarToken(),
    //   ];
    //   from(requests).pipe(
    //     concatMap( x => x)
    //   ).subscribe((data) => {
    //       this.clientService.obtenerUsuario().subscribe(
    //         res => {
    //           if( res == false ) {
    //             // alert( 'Existen errores al procesar su solicitud.' );
    //             this.router.navigateByUrl('/inicio');
    //             return;
    //           }
    //           this.contentUser = res['data'];
    //           this.client.address = this.contentUser['address'];
    //           this.client.age = this.contentUser['age'];
    //           this.client.cellPhone = this.contentUser['cellPhone'];
    //           this.client.ci = this.contentUser['ci'];
    //           this.client.city = this.contentUser['city'];
    //           this.client.completeRegister = this.contentUser['completeRegister'];
    //           this.client.currentModule = this.contentUser['currentModule'];
    //           this.client.disability = this.contentUser['disability'];
    //           this.client.educationLevel = this.contentUser['educationLevel'];
    //           this.client.email = this.contentUser['email'];
    //           this.client.gender = this.contentUser['gender'];
    //           this.client.nombre = this.contentUser['nameComplete'];
    //           this.client.nameComplete = this.contentUser['nameComplete'];
    //           this.client.phone = this.contentUser['phone'];
    //           this.client.role = this.contentUser['role'];
    //           this.isAuth = true;
    //           this.certificate = this.contentUser['finishedModules'].length == 5 ? true : false;
    //           this.contentUser['finishedModules'].forEach( element => {
    //             this.finishedModules[element.moduleNumber] = true;
    //           });
    //           if( this.client.completeRegister ) {
    //             if( Object.entries(this.finishedModules ).length === 0 ) {
    //               let app = document.querySelector('#module-1');
    //               let a = document.createElement('a');
    //               a.href = '#/modulo-1';
    //               a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //               a.setAttribute( 'routerLink', '/modulo-1' );
    //               a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //               a.textContent = 'Empezar el módulo';
    //               app.append( a );
    //             } else if( localStorage.getItem('module') != null && localStorage.getItem('email') ==  this.client.email ){
    //               let app = document.querySelector('#module-' + localStorage.getItem('module') );
    //               let a = document.createElement('a');
    //               a.href = '#/modulo-' + localStorage.getItem('module');
    //               a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //               a.setAttribute( 'routerLink', '/modulo-' + localStorage.getItem('module') );
    //               a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //               a.textContent = 'Empezar el módulo';
    //               app.append( a );
    //             } else {
    //               for( var i = 1; i<= 5; i++ ) {
    //                 if( this.finishedModules[i] == true ) {
    //                   continue;
    //                 }
    //                 let app = document.querySelector('#module-' + i );
    //                 let a = document.createElement('a');
    //                 a.href = '#/modulo-' + i;
    //                 a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //                 a.setAttribute( 'routerLink', '/modulo-' + i );
    //                 a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //                 a.textContent = 'Empezar el módulo';
    //                 app.append( a );
    //               }
    //             }
    //           }
    //         }
    //       )
    //   })
    // } else {
    //   if( localStorage.getItem('email') != null ) {
    //     this.clientService.obtenerUsuario()
    //     .subscribe(
    //       data => {
    //         if( data == false ) {
    //           this.router.navigateByUrl('/inicio');
    //           return;
    //         }
    //         this.contentUser = data['data'];
    //         this.client.address = this.contentUser['address'];
    //         this.client.age = this.contentUser['age'];
    //         this.client.cellPhone = this.contentUser['cellPhone'];
    //         this.client.ci = this.contentUser['ci'];
    //         this.client.city = this.contentUser['city'];
    //         this.client.completeRegister = this.contentUser['completeRegister'];
    //         this.client.currentModule = this.contentUser['currentModule'];
    //         this.client.disability = this.contentUser['disability'];
    //         this.client.educationLevel = this.contentUser['educationLevel'];
    //         this.client.email = this.contentUser['email'];
    //         this.client.gender = this.contentUser['gender'];
    //         this.client.nombre = this.contentUser['nameComplete'];
    //         this.client.nameComplete = this.contentUser['nameComplete'];
    //         this.client.phone = this.contentUser['phone'];
    //         this.client.role = this.contentUser['role'];
    //         this.certificate = this.contentUser['finishedModules'].length == 5 ? true : false;
    //         this.contentUser['finishedModules'].forEach( element => {
    //           this.finishedModules[element.moduleNumber] = true;
    //         });
    //         this.isAuth = true;
    //         // AGREGANDO ENLACE PARA EL ACCESO AL MÓDULO QUE LE CORRESPONDE
    //         if( this.client.completeRegister ) {
    //           if( Object.entries(this.finishedModules ).length === 0 ) {
    //             let app = document.querySelector('#module-1');
    //             let a = document.createElement('a');
    //             a.href = '#/modulo-1';
    //             a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //             a.setAttribute( 'routerLink', '/modulo-1' );
    //             a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //             a.textContent = 'Empezar el módulo';
    //             app.append( a );
    //           } else if( localStorage.getItem('module') != null && this.finishedModules[localStorage.getItem('module')] != true ){
    //             let app = document.querySelector('#module-' + localStorage.getItem('module') );
    //             let a = document.createElement('a');
    //             a.href = '#/modulo-' + localStorage.getItem('module');
    //             a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //             a.setAttribute( 'routerLink', '/modulo-' + localStorage.getItem('module') );
    //             a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //             a.textContent = 'Empezar el módulo';
    //             app.append( a );
    //           } else {
    //             for( var i = 1; i<= 5; i++ ) {
    //               if( this.finishedModules[i] == true ) {
    //                 continue;
    //               }
    //               let app = document.querySelector('#module-' + i );
    //               let a = document.createElement('a');
    //               a.href = '#/modulo-' + i;
    //               a.setAttribute( 'class', 'delete-this btn btn-module color-4 py-2 px-4');
    //               a.setAttribute( 'routerLink', '/modulo-' + i );
    //               a.setAttribute( 'style', 'border: 1px solid #b5b4b5;');
    //               a.textContent = 'Empezar el módulo';
    //               app.append( a );
    //             }
    //           }

    //         }
    //       },
    //       err => {
    //         this.router.navigateByUrl('/inicio');
    //         // this.contentUser = JSON.parse(err.error).message;
    //       }
    //     );
    //   }
    // }
  }

  goTo(location: string): void {
    window.location.hash = location;
  }

  revokeToken(){
    this.clientService.revokeToken().subscribe(
      data => {
      }
    );
  }

  descargarCertificado() {
    this.spinnertwo.show();
    this.clientService.descargarCertificado( this.decryptText( this.client.nameComplete ) ).subscribe(
      res => {
        this.spinnertwo.hide();
        var linkSource = `${res['data']['pdfCertificate']}`;
        var downloadLink = document.createElement("a");
        var fileName = `CERTIFICADO-${this.client.nameComplete}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = 'CERTIFICADO-' + ( this.decryptText((this.client.nameComplete))).replace(/ /g, '-');
        downloadLink.click();
      },
      err => {
        alert( err.error.message );
      }
    );
  }

  finalizarModulo( module ) {
    localStorage.setItem( 'finish-' + module, 'final' );
    this.router.navigateByUrl('/modulo-' + module );
    window.scroll(0,0);
  }

  encryptText() {
    this.clientService.refrescarToken().subscribe();
  }

  decryptText( value ) {
    var key = CryptoJS.enc.Utf8.parse('4512631236589784');
    var iv = CryptoJS.enc.Utf8.parse('4512631236589784');
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
