<app-header></app-header>

<swiper [config]="config">
	<div class="swiper-wrapper">
		<!-- SLIDE 1 -->
		<div class="swiper-slide">
			<section class="module">

        <div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Contratos de Créditos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center">
              <div class="col-12">
                  <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">Contratos de Créditos</h2>
                  <h4 class="text-left color-3 text-left wow bounceInDown" data-wow-delay = "1.5s">¿Qué es un contrato?</h4>
                  <div class="row align-items-center justify-content-center mb-4">
                      <div class="col-3">
                          <div class="wow bounceInDown img-container image-l m-auto" data-wow-delay = "1.5s">
                              <img class="max-w-l" src="assets/images/advanced/chapter3/1.png">
                          </div>
                      </div>
                      <div class="col-9">
                        <div class="wow bounceInDown" data-wow-delay = "1.5s">
                          <p class="mb-0 text-justify">Es un documento suscrito entre la entidad financiera y el cliente financiero, a través del cual se obligan al cumplimiento de las estipulaciones propias y de las operaciones comunes o recurrentes o de las operaciones no recurrentes o especiales.</p>
                          <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
                        </div>
                      </div>
                  </div>
                  <p class="text-justify mb-4 wow bounceInUp" data-wow-delay = "1s">Dentro de los modelos de contratos que tiene Banco Ecofuturo para todas sus operaciones de crédito, están los contratos con hipotecarias, contratos con garantía que no sean hipotecarias, contratos modelos de ASFI para los sectores de Vivienda, Vivienda de Interés Social y para el sector productivo.</p>
              </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-4">
              <a class="js-stop btn bg-3 text-white py-2 px-4 swiper_next wow fadeIn" data-wow-delay="2s" (click)="activeWow()">SIGUIENTE</a>
          </div>
        </div>

				<div class="row module_progress align-items-center">
					<div class="col">
            <div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 3%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:3.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 2 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Tipos de Contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center">
              <div class="col-12 mb-auto">
                  <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">Tipos de Contratos</h2>
                  <h4 class="text-left color-3 text-left wow bounceInDown" data-wow-delay = "1s">Contrato Matriz</h4>
                  <div class="row align-items-center justify-content-center mb-4">
                      <div class="col-5">
                          <div class="wow bounceInDown image-xl type-95 m-auto" data-wow-delay = "1.5s">
                              <img style="max-width: 100%;" src="assets/images/advanced/chapter3/2.png">
                          </div>
                      </div>
                      <div class="col-7">
                        <div class="wow bounceInDown" data-wow-delay = "1.5s">
                          <p class="mb-0 text-justify">Es una estructura básica de contrato para operaciones comunes y recurrentes establecida por ASFI, que contiene cláusulas estándar sobre la base de las cuales, las entidades financieras deben elaborar sus contratos modelo.</p>
                          <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 12.5%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:13%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 3 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Tipos de Contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
          <div class="row content_center text-center">
              <div class="col-12">
                <h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">Tipos de Contratos</h2>
                <div class="row mb-4">
                  <div class="col-6 wow bounceInDown" data-wow-delay = "1s">
                    <h4 class="color-3">Contrato Modelo</h4>
                    <div class="img-container image-l m-auto">
                      <img src="assets/images/advanced/chapter3/3.jpg">
                    </div>
                    <p class="mb-0 text-justify">Estos son los contratos elaborados por las entidades financieras, se basan en el contrato matriz establecido por ASFI para operaciones comunes o recurrentes.</p>
                  </div>
                  <div class="col-6 wow bounceInDown" data-wow-delay = "1s">
                    <h4 class="color-3">Contrato no Recurrente o Especial</h4>
                    <div class="img-container image-l m-auto">
                      <img src="assets/images/advanced/chapter3/4.png">
                    </div>
                    <p class="mb-0 text-justify m-auto">Estos son contratos elaborados por las entidades financieras para las operaciones no recurrentes o especiales.</p>
                    </div>
                  </div>
                </div>
                <p class="mb-0 m-auto wow bounceInDown" data-wow-delay = "1.5s">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
              </div>
          </div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 25%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:25.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 4 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Consumidores Financieros</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-resize text-center">
						<div class="col-12">
							<h2 class="color-3 pb-4 wow bounceIn" data-wow-delay = ".5s">Consumidores Financieros</h2>
              <p class="pb-4 text-justify wow bounceInDown" data-wow-delay = "1s">Los consumidores financieros tienen derecho a suscribir contratos que contengan cláusulas claras, legibles, unívocas y comprensibles. Adicionalmente, recordamos a la población que las entidades financieras deben facilitar a sus clientes financieros el conocimiento y la comprensión de los contratos en su idioma originario.</p>
							<div class="row">
                <div class="col-7 wow bounceInDown" data-wow-delay = "1.5s">
                  <div style="max-width: 432px; margin-left: auto;">
                    <h4 class="text-left color-3 text-left">Personas con Discapacidad</h4>
                    <p class="mb-0 text-justify">En el caso de personas con discapacidad, las entidades financieras deben disponer de medios adecuados que conlleven a ese propósito y también permitan atender y absolver las consultas de las mismas, sobre los términos del contrato.</p>
                  </div>
                </div>
                <div class="col-5" data-wow-delay = "1.5s">
                  <div class="wow bounceInDown img-container image-semi-xl type-95 mr-auto">
                    <img src="assets/images/advanced/chapter3/5.jpg">
                  </div>
                </div>
              </div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 37.5%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:38%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 5 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Requisitos de contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3 wow bounceIn" data-wow-delay = ".5s">Requisitos de contratos</h2>
							<p class="pb-4 text-left wow bounceInDown" data-wow-delay="1s">En cuanto a los contratos de créditos, hoy te presentamos las partes más importantes que se solicita al consumidor financiero completar:</p>
							<div class="row">
								<div class="col-6 wow bounceInDown" data-wow-delay="1.5s">
									<div>
										<img class="max-w-xxl" src="assets/images/advanced/chapter3/6.png">
									</div>
								</div>
								<div class="col-6 text-left wow bounceInDown" data-wow-delay="1.5s">
                  <p class="mb-0 p-grid">Nombres y apellidos del deudor y/o codeudor</p>
                  <p class="mb-0 p-grid">Importe del crédito – numeral y literal</p>
                  <p class="mb-0 p-grid">Moneda</p>
                  <p class="mb-0 p-grid">Descripción del destino</p>
                  <p class="mb-0 p-grid">Plazo</p>
                  <p class="mb-0 p-grid">Tasa de interés Fija o Variable</p>
                  <p class="mb-0 p-grid">Cargos o Seguros</p>
                  <p class="mb-0 p-grid">Garantías</p>
                  <p class="mb-0 p-grid">Fecha de contrato</p>
                  <p class="mb-0 p-grid">Firmas correspondientes</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-auto">
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_prev wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a class="btn bg-3 text-white py-2 px-4 mx-2 swiper_next wow slideInRight" data-wow-delay="2s"
							(click)="activeWow()">SIGUIENTE</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 50%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:50.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 6 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Requisitos de contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3 wow bounceIn" data-wow-delay = ".5s">Requisitos de contratos</h2>
              <h4 class="text-left color-3 wow bounceInDown" data-wow-delay="1s">Requisitos de forma</h4>
							<p class="pb-4 wow bounceInDown" data-wow-delay="1s">Los contratos elaborados por las entidades financieras deben instrumentarse en idioma castellano y cumplir los siguientes aspectos:</p>
							<div class="mx-5 wow bounceInDown" data-wow-delay="1.5s">
                <h4 class="text-left color-3 ">Utilización de caracteres</h4>
							  <p>Las entidades financieras están obligadas a utilizar en sus contratos caracteres que sean legibles, cuyo tamaño no debe ser inferior a tres (3) milímetros.</p>
                <h4 class="text-left color-3 ">Redacción</h4>
							  <p>Las entidades financieras deben redactar las cláusulas contractuales, en un lenguaje sencillo y claro que permita una adecuada comprensión por parte de los clientes financieros en relación a sus derechos y obligaciones.</p>
                <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
              </div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 62.5%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:63%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 7 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Requisitos de contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3 wow bounceIn" data-wow-delay = ".5s">Requisitos de contratos</h2>
              <h4 class="text-left color-3 wow bounceInDown" data-wow-delay="1s">Requisitos de fondo</h4>
							<p class="pb-4 wow bounceInDown" data-wow-delay="1s">Para la elaboración de los contratos, la entidad financiera debe tomar en cuenta lo siguiente:</p>
							<div class="mx-5 wow bounceInDown" data-wow-delay="1.5s">
                <h4 class="text-left color-3 ">Contratos individuales</h4>
							  <p>Las operaciones y/o servicios financieros efectuados por las entidades financieras deben estar contenidas en contratos individuales, pudiendo incorporar los servicios relacionados;</p>
                <h4 class="text-left color-3 ">Comisiones y cargos</h4>
							  <p>Las entidades financieras quedan prohibidas de efectuar el cobro de cargos o comisiones que no impliquen una contraprestación efectiva de servicios para el cliente financiero. Tampoco podrán cobrar cargos o comisiones por servicios no aceptados o no solicitados expresamente por el cliente financiero o que en su caso representen el cobro de más de una comisión por un mismo acto, hecho o evento.</p>
                <h4 class="text-left color-3 ">Primas de Seguro</h4>
							  <p>El cobro por concepto de primas, relacionado con el seguro que deba pagar el cliente financiero por los seguros asociados a las operaciones y/o servicios financieros, no podrá ser mayor a aquel que la entidad financiera efectivamente ha contratado con la compañía aseguradora.</p>
                <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
              </div>
						</div>
					</div>
				</div>


				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 75%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:75.5%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 8 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Requisitos de contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3 wow bounceIn" data-wow-delay = ".5s">Requisitos de contratos</h2>
              <h4 class="text-left color-3 wow bounceInDown" data-wow-delay="1s">Requisitos de fondo</h4>
							<div class="mx-5 wow bounceInDown" data-wow-delay="1.5s">
                <h4 class="text-left color-3">Modificación unilateral</h4>
							  <p>Las entidades financieras no pueden modificar unilateralmente los términos y condiciones pactadas en los contratos de operaciones y/o servicios financieros, salvo que dicha modificación beneficie al cliente financiero.</p>
                <h4 class="text-left color-3 ">Castigo de Créditos</h4>
							  <p>Los contratos de operaciones y/o servicios financieros deben señalar de manera clara el tratamiento de las deudas castigadas, su registro en la Central de Información Crediticia (CIC), la permanencia en el mismo y sus consecuencias.</p>
                <h4 class="text-left color-3">Información sobre tasas de interés</h4>
							  <p>Los contratos de operaciones financieras que contemplen tasas de interés deben contener la información establecida en el Reglamento de Tasas de Interés de la Recopilación de Normas para Servicios Financieros.</p>
                <p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
              </div>
						</div>
					</div>
				</div>

				<app-controls ngClass="chapter3"></app-controls>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 87.5%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:88%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>

		<!-- SLIDE 9 -->
		<div class="swiper-slide">
			<section class="module">

				<div class="px-5 py-3 module_header text-left">
          <ol class="justify-content-start">
            <li class="light-text">
                <h2 class="color-3">Requisitos y alcances del contrato</h2>
            </li>
            <li>
                <h2 class="color-3">Requisitos de contratos</h2>
            </li>
          </ol>
        </div>

				<div class="container module_body">
					<div class="row content_center vh-custom text-center">
						<div class="col-12">
							<h2 class="color-3 wow bounceIn" data-wow-delay = ".5s">Requisitos de contratos</h2>
              				<h4 class="text-left color-3 text-left wow bounceInDown" data-wow-delay="1s">Requisitos de fondo</h4>
							<div class="mx-5 wow bounceInDown" data-wow-delay="1.5s">
                <h4 class="text-left color-3 text-left">Prohibición de cláusulas abusivas</h4>
								<p class="p-grid">Exonerar o limitar la responsabilidad de la entidad financiera por contingencias de cualquier naturaleza de las operaciones y/o servicios financieros.</p>
								<p class="p-grid">La renuncia, exclusión y/o limitación de los derechos del cliente financiero, así como la renuncia o restricción de formular reclamos.</p>
								<p class="p-grid">Contener cualquier precepto que imponga la carga de la prueba en perjuicio del cliente financiero.</p>
								<p class="p-grid">La renuncia del cliente financiero al derecho a ser resarcido o reembolsado de cualquier erogación que sea indebidamente cobrada.</p>
								<p class="p-grid">Facultar a la entidad financiera a suministrar otros productos o servicios no incluidos en el contrato, sin la previa y expresa aceptación del cliente financiero y/o imponiéndole un plazo para comunicar que no los acepta.</p>
								<p class="p-grid">Facultar a la entidad financiera a realizar débitos automáticos por las operaciones financieras que se encuentren en estado de ejecución.</p>
								<p class="p-grid">Establecer la posibilidad de que se proceda a la retención de fondos sin previa orden o instrucción emitida por autoridad competente.</p>
								<p class="p-grid">La incorporación de cláusulas que dispongan la aceleración del contrato sin justificativo técnico ni legal.</p>
								<p class="p-grid">Facultar a la entidad financiera a utilizar y compartir la información personal y/o crediticia del cliente financiero con otras empresas, entidades y/o proveedores.</p>
                				<p class="mb-0 text-right">- Autoridad de Supervisión del Sistema Financiero (ASFI)</p>
              				</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-4" style="z-index: 2;">
						<a class="btn bg-3 text-white py-2 px-4 swiper_prev mx-2 wow slideInLeft" data-wow-delay="2s"
							(click)="activeWow()">ANTERIOR</a>
						<a *ngIf="this.finalModule == false"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							data-toggle="modal" data-target=".module-completed">FINALIZAR</a>
						<a *ngIf="this.finalModule == true"
							class="btn bg-3 text-white py-2 px-4 swiper_next mx-2 wow slideInRight" data-wow-delay="2s"
							(click)="goToHome()">IR AL INICIO</a>
					</div>
				</div>

				<div class="row module_progress align-items-center">
					<div class="col">
						<div class="progress">
              <div class="progress-bar bg-3 wow fadeInLeft" data-wow-duration = "2s" data-wow-delay = "2s" role="progressbar" style="width: 100%;" aria-valuenow="1"
                  aria-valuemin="0" aria-valuemax="100"></div>
              <img class="icon_progress wow slideInLeft" data-wow-delay = "2s" style="left:97%" src="assets/images/module-3/pencil.svg">
            </div>
					</div>
					<div class="col-md-1 col-3 wow pulse" data-wow-delay="3s" data-wow-iteration="infinite">
						<img src="assets/images/module-3/doc.svg" class="doc-icon">
					</div>
				</div>

				<app-circles></app-circles>

			</section>
		</div>
	</div>
	<!-- <div class="swiper-pagination"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div> -->
</swiper>

<app-footer></app-footer>

<!-- SLIDE-1 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide1modal" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="text-left color-1">Crédito Empresarial:</h4>
						<p class="text-justify">Crédito cuyo objeto es financiar actividades de producción,
							comercialización o servicios de empresas de gran tamaño.</p>
						<a class="btn bg-1 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-2 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide2modal" tabindex="-1" role="dialog" aria-labelledby="slide2-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-2" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="text-left color-2">Crédito PYME:</h4>
						<p class="text-justify">Crédito otorgado a pequeñas y medianas empresas, con el objeto de
							financiar actividades de producción, comercialización o servicios.</p>
						<a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-3 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide3modal" tabindex="-1" role="dialog" aria-labelledby="slide3-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-3" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="text-left color-3">Microcrédito:</h4>
						<p class="text-justify">Crédito otorgado a una persona natural o jurídica, o a un grupo de
							prestatarios, con el objeto de financiar actividades económicas de pequeña escala, cuya
							fuente principal de pago la constituye el producto de las ventas e ingresos generados por
							dichas actividades.</p>
						<a class="btn bg-3 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-4 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide4modal" tabindex="-1" role="dialog" aria-labelledby="slide4-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-4" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="text-left color-4">Crédito de Vivienda:</h4>
						<p class="text-justify">Crédito otorgado a una persona natural, destinado a la adquisición de un
							terreno, compra o construcción de una vivienda, o para la ampliación, refacción y/o
							mejoramiento de una vivienda.</p>
						<a class="btn bg-4 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- SLIDE-5 MODAL DESCRIPTIONS -->
<div class="modal fade" id="slide5modal" tabindex="-1" role="dialog" aria-labelledby="slide5-modal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content slide_modal">
			<button type="button" class="close color-5" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
           </button>
			<div class="modal-body py-4">
				<div class="row align-items-center justify-content-center">
					<div class="col-12">
						<h4 class="text-left color-5">Crédito de Consumo:</h4>
						<p class="text-justify">Crédito concedido a una persona natural, con el objeto de financiar la
							adquisición de bienes de consumo o el pago de servicios, cuya fuente principal de pago es el
							salario de la persona o ingresos provenientes de actividades independientes, adecuadamente
							verificados.</p>
						<a class="btn bg-5 text-white px-5" data-dismiss="modal">Cerrar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- MODULE COMPLETED POP UP -->
<div class="modal fade module-completed popup_form" tabindex="-1" role="dialog" aria-labelledby="ModuleCompleted"
	aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title text-white">¡FELICIDADES!</h1>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body bg-module-completed">
				<div class="container-fluid center">
					<div class="row justify-content-center mb-4">
						<div class="col-lg-4 col-md-8 col-sm-8 col-10 text-center">
							<img class="img-1 w-75" src="assets/images/icons/advanced/3.png">
							<h2 class="color-3 mt-2">COMPLETASTE EL MÓDULO 3</h2>
						</div>
					</div>
					<div class="row align-items-center justify-content-center" style="margin-bottom: 2rem;">
						<div class="col-lg-2 col-md-3 col-sm-3 col-6 text-right text-center-resp">
							<img class="img-2" src="assets/images/module-3/quiz.svg">
						</div>
						<div class="col-lg-6 col-md-7 col-sm-7 col-12 text-center-resp">
							<h4 class="text-left color-3">Completa el QUIZ
                <!-- <a routerLink="quiz" data-dismiss="modal">aquí</a> -->
                <a routerLink="/avanzado/modulo-4" data-dismiss="modal">aquí</a>
              </h4>
							<!-- <p>para obtener un cupón para el sorteo y recién continuar con el siguiente módulo</p> -->
						</div>
						<!-- <div class="col-9 text-center mt-2">
							<p><small>No te olvides que por cada <strong class="color-3">módulo que termines,</strong>
									obtienes un cupón más, es decir, <strong class="color-3">una oportunidad más de
										ganar.</strong></small></p>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
