import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

declare function stopVideo();

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {

  constructor( private wowService: NgwWowService ) {
    this.activeWow();
    // this.wowService.init();
  }

  ngOnInit(): void {
  }

  getBrowserInfo() {
    var ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
  }

  activeWow() {
    var browserInfo = this.getBrowserInfo();
    if( browserInfo != "Chrome 4" && browserInfo != "Safari 15" && browserInfo != "Safari 14" && browserInfo != "Safari 13" && browserInfo != "Safari 12" && screen.width > 780 ) {
      this.wowService.init();
      stopVideo();
    }
  }
}
