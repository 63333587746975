import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  public api_url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getData() {
  	return this.http.get(this.api_url + `api/videos`);
  }

}
