<section class="vh-100 d-flex align-items-center page_forms">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center bg-3 pt-4 pb-4">                
                <h3 class="text-white">Recuperación de Contraseña</h3>
            </div>
        </div>     
        <div class="row align-items-center border_box">
            <div class="col-md-5 text-center">
                <img class="w-75" src="assets/images/logo-click.svg">
            </div>
            <div class="col-md-7">
                <form autocomplete="off" class="modal-body" [formGroup]="changePasswordForm" (ngSubmit)="cambiarPassword(changePasswordForm.value)">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Correo Electrónico:</label>
                        <input type="email" formControlName="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Ingresa tu Correo Electrónico">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nueva Contraseña:</label>
                        <input type="password" formControlName="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Ingresa tu nueva contraseña">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Confirmar Nueva Contraseña:</label>
                        <input type="password" formControlName="confirmPassword" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Confirma tu nueva contraseña">
                    </div>
                    <div>
                        <p class="text-danger" *ngIf="contrasenasInvalidas()">Las contraseñas deben coincidir.</p>
                    </div>
                    <button type="submit" class="btn btn-outline-primary btn-form color-3">Cambiar Contraseña</button>
                </form>

            </div>
        </div>
        <!-- <div class="row justify-content-center mt-2">
            <div class="col-6 text-center">
                <p class="color-2">Actualización de contraseña realizado correctamente.</p>
                <a class="btn bg-1 text-white px-5" data-dismiss="modal" routerLink="/inicio"><strong>Ir al Inicio</strong></a>
            </div>
        </div> -->
    </div>
    <ngx-spinner
        bdColor="rgba(255,255,255)"
        size="medium"
        color="#FF5000"
        type="ball-scale-multiple"
    >
        <p style="font-size: 20px; color: #FF5000">{{ this.textLoading }}</p>
    </ngx-spinner>
</section>