<app-header></app-header>
<div class="home-page type-2" style="margin-bottom: 4rem;">
  <div class="row">
    <div class="col-lg-12 text-center">
        <a *ngIf="this.isAuth && this.client.role == 'Admin'" class="btn main_btn bg-2 text-white py-2 px-4" routerLink="/admin"><strong>Administrar</strong></a>
    </div>
  </div>
  <!-- <div class="d-flex flex-column align-items-center justify-content-center" style="padding: 3.5rem .54rem; background-color: #FFC000;">
    <div class="highlighted">
      Curso Avanzado
    </div>
    <h4 class="text-white text-center mx-auto" style="max-width: 800px; margin-bottom: 2rem;">En este curso aprenderás de una manera muy sencilla, educación financiera nivel avanzado</h4>
    <div class="row mb-3">
        <div class="col-lg-12 text-center">
            <a routerLink="/modulo-1" class="btn main_btn bg-1 text-white wow bounceIn" data-wow-delay = "2.5s">QUIERO <STRONG>INICIAR</STRONG></a>
            <a *ngIf="certificate" (click)="descargarCertificado()" class="btn main_btn bg-2 text-white" data-wow-delay = ".5s"><span><STRONG>DESCARGAR CERTIFICADO</STRONG></span></a>
            <a id="btn-init" data-wow-delay = ".5s" data-toggle="modal" data-target=".login-modal"><span class="btn main_btn bg-white color-6" *ngIf="!this.isAuth">QUIERO <STRONG>INICIAR</STRONG></span></a>
            <a id="btn-init" data-wow-delay = ".5s">
              <span class="btn main_btn bg-white color-6" routerLink="/avanzado/modulo-1">
                QUIERO <STRONG>INICIAR</STRONG>
              </span>
            </a>
            <a *ngIf="this.client.completeRegister == false" routerLink="/registro" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>COMPLETA TU REGISTRO</STRONG></a>
            <p *ngIf="this.client.completeRegister == true && certificate == false" class="btn main_btn cursor-default bg-1 text-white">REGISTRO COMPLETO</p>
            <p *ngIf="allcompleted" class="btn main_btn bg-1 text-white">PROGRAMA COMPLETADO</p>

            <a (click)="encryptText()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>Encript</STRONG></a>

            <a (click)="refreshToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REFRESH TOKEN</STRONG></a>
            <a (click)="revokeToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REVOKE TOKEN</STRONG></a>
        </div>
    </div>
  </div> -->
  <img class="banner-home" src="assets/images/NIVEL-AVANZADO.png" alt="banner innicial ecofuturo">
</div>

<div class="home-page type-2" style="margin-bottom: 4rem;">
  <div style="display: block; width: fit-content; margin: auto;">
    <a href="assets/images/certificates/CERTIFICADO-CLICK-FINANCIERO-AVANZADO-2024.pdf" download class="btn main_btn bg-2 text-white" data-wow-delay = ".5s"><span><STRONG>DESCARGAR CERTIFICADO</STRONG></span></a>
  </div>
</div>

<div class="container home-page type-2">
  <div class="row justify-content-center icons_section mb-4">
      <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center {{ this.finishedModules[1] == true ? ''  : 'locked' }}">
          <div class="border-eco">
            <img src="assets/images/icons/advanced/1.png">
          </div>
          <h4>Crédito</h4>
          <!-- <h4 class="color-2">Crédito</h4> -->
      </div>
      <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[2] == true ? ''  : 'locked' }}" data-wow-delay = "1s">
          <div class="border-eco">
            <img src="assets/images/icons/advanced/2.png">
          </div>
          <h4>Aspectos a evaluar para otorgación de crédito</h4>
          <!-- <h4 class="color-2">Aspectos a evaluar para otorgación de crédito</h4> -->
      </div>
      <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[3] == true ? ''  : 'locked' }}" data-wow-delay = "1.5s">
          <div class="border-eco">
            <img src="assets/images/icons/advanced/3.png">
          </div>
          <h4>Requisitos y alcances del contrato</h4>
          <!-- <h4 class="color-2">Requisitos y alcances del contrato</h4> -->
      </div>
      <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[4] == true ? ''  : 'locked' }}" data-wow-delay = "2s">
          <div class="border-eco">
            <img src="assets/images/icons/advanced/4.png">
          </div>
          <h4>Seguros</h4>
          <!-- <h4 class="color-2">Seguros</h4> -->
      </div>
      <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[5] == true ? ''  : 'locked' }}" data-wow-delay = "2.5s">
          <div class="border-eco">
            <img src="assets/images/icons/advanced/5.png">
          </div>
          <h4>Recomendaciones para el prestatario</h4>
          <!-- <h4 class="color-2">Recomendaciones para el prestatario</h4> -->
      </div>
  </div>
  <div class="row btn_down_section">
      <div class="col text-center">
          <span style="font-size: 1.6em;" class="color-3" fragment="queaprenderas"><strong>¿QUÉ APRENDERÁS?</strong></span>
          <br>
          <img class="logo-click wow bounceIn" src="assets/images/icons/btn-down.svg" data-wow-iteration = "infinite">
      </div>
  </div>
</div>

<section class="py-5 home-modules-page" id="queaprenderas">
  <div class="container">

      <div class="row justify-content-start module_line_2 wow fadeIn" data-wow-duration="3s">
          <div class="col-lg-9 col-md-10">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-3 col-5 text-center wow zoomIn">
                      <img src="assets/images/icons/advanced/1.png" width="70%">
                  </div>
                  <div class="col-lg-9 col-12 text-justify">
                      <h1 class="color-1">Crédito</h1>
                      <h4 *ngIf="this.finishedModules[1] == true"><span class="badge text-white mt-2">Módulo completado</span></h4>
                      <p>Aprende qué es el Sistema Financiero, la labor que desempeña la Autoridad de Supervisión del Sistema Financiero (ASFI) y el alcance que tiene la recopilación de normativa y la Ley 393 de Servicios Financieros.</p>
                      <!-- <a class="btn btn-module color-4 py-2 px-4" routerLink="/modulo-1"><strong>COMENZAR</strong></a> -->
                      <!-- <a *ngIf="this.isAuth == false" class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a> -->
                      <a *ngIf="this.finishedModules[1] == true" (click)="finalizarModulo(1)" class="btn btn-module color-4 py-2 px-4"><strong>VER MÓDULO</strong></a>
                      <div id="module-1"></div>
                      <a class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a>
                  </div>
              </div>
          </div>
      </div>

      <div class="row justify-content-end module_line_3 wow fadeIn" data-wow-duration="3s">
          <div class="col-lg-8">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-3 col-5 text-center wow zoomIn">
                    <img src="assets/images/icons/advanced/2.png" width="70%">
                  </div>
                  <div class="col-lg-9 col-12 text-justify">
                      <h1 class="color-2">Aspectos a evaluar para otorgación de crédito</h1>
                      <h4 *ngIf="this.finishedModules[2] == true"><span class="badge text-white mt-2">Módulo completado</span></h4>
                      <p>Administrar tu dinero es muy importante. Conoce los beneficios de ahorrar y algunos consejos para hacerlo de forma correcta, cómo realizar tu presupuesto mensual y los distintos productos destinados a la administración de efectivo que ofrece Banco PYME Ecofuturo y las entidades financieras en Bolivia.</p>
                      <!-- <a *ngIf="this.isAuth == false" class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a> -->
                      <a *ngIf="this.finishedModules[2] == true" (click)="finalizarModulo(2)" class="btn btn-module color-4 py-2 px-4"><strong>VER MÓDULO</strong></a>
                      <div id="module-2"></div>
                      <a class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a>
                  </div>
              </div>
          </div>
      </div>

      <div class="row justify-content-start module_line_4 wow fadeIn" data-wow-duration="3s">
          <div class="col-lg-8">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-3 col-5 text-center wow zoomIn">
                    <img src="assets/images/icons/advanced/3.png" width="70%">
                  </div>
                  <div class="col-lg-9 col-12 text-justify">
                      <h1 class="color-3">Requisitos y alcances del contrato</h1>
                      <h4 *ngIf="this.finishedModules[3] == true"><span class="badge text-white mt-2">Módulo completado</span></h4>
                      <p>El Crédito es una opción para aquellas personas que no tienen efectivo inmediato. Descubre sus características, condiciones y beneficios. Asimismo, conocerás los tipos de Crédito que existen, los que te ofrece el Banco y algunas recomendaciones para su adecuada gestión, así como las responsabilidades que implica ser el Garante de una persona. Finalmente podrás adquirir información sobre el Diferimiento de Créditos.</p>
                      <!-- <a *ngIf="this.isAuth == false" class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a> -->
                      <a *ngIf="this.finishedModules[3] == true" (click)="finalizarModulo(3)" class="btn btn-module color-4 py-2 px-4"><strong>VER MÓDULO</strong></a>
                      <div id="module-3"></div>
                      <!-- <a class="btn btn-module color-4 py-2 px-4" routerLink="/modulo-3"><strong>COMENZAR</strong></a> -->
                      <a class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a>
                  </div>
              </div>
          </div>
      </div>

      <div class="row justify-content-end module_line_5 wow fadeIn" data-wow-duration="3s">
          <div class="col-lg-8">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-3 col-5 text-center wow zoomIn">
                    <img src="assets/images/icons/advanced/4.png" width="70%">
                  </div>
                  <div class="col-lg-9 col-12 text-justify">
                      <h1 class="color-4">Seguros</h1>
                      <h4 *ngIf="this.finishedModules[4] == true"><span class="badge text-white mt-2">Módulo completado</span></h4>
                      <p>Conoce los distintos Instrumentos Electrónicos de Pago que existen y sus características, los beneficios que implica utilizarlos y los que brinda Banco PYME Ecofuturo. De igual manera, aprenderás sobre las medidas que debes tomar en cuenta para realizar transacciones eficientes y seguras.</p>
                      <!-- <a *ngIf="this.isAuth == false" class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a> -->
                      <a *ngIf="this.finishedModules[4] == true" (click)="finalizarModulo(4)" class="btn btn-module color-4 py-2 px-4"><strong>VER MÓDULO</strong></a>
                      <div id="module-4"></div>
                      <!-- <a class="btn btn-module color-4 py-2 px-4" routerLink="/modulo-4"><strong>COMENZAR</strong></a> -->
                      <a class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a>
                  </div>
              </div>
          </div>
      </div>

      <div class="row justify-content-start wow fadeIn" data-wow-duration="3s">
          <div class="col-lg-8">
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-3 col-5 text-center wow zoomIn">
                    <img src="assets/images/icons/advanced/5.png" width="70%">
                  </div>
                  <div class="col-lg-9 col-12 text-justify">
                      <h1 class="color-5">Recomendaciones para el prestatario</h1>
                      <h4 *ngIf="this.finishedModules[5] == true"><span class="badge text-white mt-2">Módulo completado</span></h4>
                      <p>Existen diferentes tipos de Puntos de Atención Financiera (PAF’s) y las transacciones u operaciones bancarias que puedes realizar en cada una de ellos son distintas. Infórmate sobre sus características para apersonarte a los que sean únicamente de tu necesidad. Asimismo, aprenderás las cualidades sobre uno de los PAF´s más relevantes: el Cajero Automático, así como los pasos que necesitas seguir para retirar dinero del mismo.</p>
                      <!-- <a *ngIf="this.isAuth == false" class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a> -->
                      <a *ngIf="this.finishedModules[5] == true" (click)="finalizarModulo(5)" class="btn btn-module color-4 py-2 px-4"><strong>VER MÓDULO</strong></a>
                      <!-- <a class="btn btn-module color-4 py-2 px-4" routerLink="/modulo-5"><strong>COMENZAR</strong></a> -->
                      <div id="module-5"></div>
                      <a class="btn btn-module color-4 py-2 px-4" data-toggle="modal" data-target=".login-modal"><strong>COMENZAR</strong></a>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="home-page type-2" style="margin-top: 4rem;">
    <div style="display: block; width: fit-content; margin: auto;">
      <a href="" class="btn main_btn bg-3 text-white" data-wow-delay = ".5s"><span><STRONG>VOLVER A LA PAGINA DE INICIO</STRONG></span></a>
    </div>
  </div>

  <!-- <img class="elements wow zoomIn green_circle" data-wow-delay = "0.5s" src="assets/images/elements/green-circle.svg">
  <img class="elements wow zoomIn orange_circle" data-wow-delay = "1s" src="assets/images/elements/orange-circle.svg">
  <img class="elements wow zoomIn purple_circle" data-wow-delay = "1.5s" src="assets/images/elements/purple-circle.svg">
  <img class="elements wow zoomIn orange_circle_2" data-wow-delay = "1s" src="assets/images/elements/orange-circle.svg">
  <img class="elements wow zoomIn gray_circle" data-wow-delay = "1s" src="assets/images/elements/gray-circle.svg"> -->

</section>

<!-- <div [@openClose]="isOpen ? 'open' : 'closed'" class="open-close-container pointer d-flex align-items-center" (click)="toggle()">
  <p class="m-0 py-5">The box is now {{ isOpen ? 'Open' : 'Closed' }}!</p>
</div> -->

<section class="container-fluid affter_footer">
  <div class="row justify-content-center align-items-center py-3">
      <!-- <div class="col-lg-auto inline-block text-lg-left text-center">
          <img src="assets/images/elements/aj.png">
          <p class="aj-legend"><small>Actividad Autorizada y Fiscalizada por la Autoridad de Juegos.<br>CLICK Financiero, promoción válida del 28 de octubre de 2021 al 07 de febrero de 2022.</small></p>
      </div>

      <div class="col-lg-auto col-12 text-center">

          <a class="btn main_btn bg-3 text-white my-2" href="./assets/pdf/bases-legales.pdf" target="_blank">Ver bases legales</a>
      </div> -->

      <div class="col-lg-auto text-center">
          <h6 class="color-3">Síguenos en las redes</h6>
          <ul class="social_buttons mb-0">
              <li><a href="https://www.facebook.com/BancoEcofuturo/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="https://www.instagram.com/bancoecofuturo/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UC2doer5UJozi4Rx89nEM_0g" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
              <li><a href="https://www.linkedin.com/company/banco-ecofuturo/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
      </div>
  </div>
</section>

<!-- TERMS & CONDITIONS-->
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content slide_modal">
          <div class="modal-body">
              <div class="row p-1 p-md-3 p-xl-5">
                  <div class="col text-justify">
                      <h3 class="color-1 text-center mt-2 mb-3">Bases Legales</h3>
                      <ul>
                          <li><p>Si el contenido es puntual, breve y no tuviera demasiados elementos gráficos se propone el actual popup.</p></li>
                          <li><p>Si el contenido abarca mucho texto o de alguna manera es extenso y tuviera otros elementos gráficos se recomiendo que esta vista este en una página y no un popup.</p></li>
                      </ul>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus qui ratione, minima aliquam voluptatem nihil! Dolores, necessitatibus! Obcaecati rerum atque veniam adipisci sequi, aliquam tenetur odit facilis facere. Eligendi, impedit magnam. Commodi, earum aliquid asperiores velit animi esse sint ad nostrum quisquam repellat quasi, veniam cumque neque error, odit eaque!</p>
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas eaque, reprehenderit unde reiciendis officiis quisquam minus a ducimus doloribus incidunt dicta officia eligendi voluptatum similique deserunt expedita molestiae neque fugiat voluptates ipsam minima ea consequuntur. Accusantium eius sapiente magnam dicta ratione distinctio maxime quidem! Repellat maiores sunt dolor dolorem numquam! Numquam, ducimus! Ipsa, iste, necessitatibus officiis reiciendis ex praesentium est dolores ratione vitae vel adipisci, dolore animi. Quibusdam repellat, quod molestias obcaecati, quos tempore voluptas aperiam, doloribus ullam ipsum reprehenderit!</p>
                      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium et sit impedit dolor inventore molestiae illo quibusdam neque vitae tenetur ipsa at hic facere voluptatum, laboriosam aliquid a? Explicabo blanditiis amet quo libero aliquid iste cum eos facere corporis, laborum nisi earum, dolores aliquam omnis, magnam aut soluta possimus adipisci?</p>
                  </div>
                  <div class="col-12 text-center">
                      <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<app-footer></app-footer>
