import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../services/client.service';
import { Client} from '../../models/client.model';
import { Router } from '@angular/router';
import { forkJoin, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { $ } from 'protractor';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public timeLoginErrorExpiration = 30;
  protected aFormGroup: FormGroup;
  public formSubmitted = false;
  public client = new Client( '', '', '');
  public isAuth: boolean = false;
  public contentUser = {};
  public classCurrentModule = '';
  public openModal = true;
  public finishedModules = {};
  public textInitSession = 'Cargando...';
  // public textInitSession = 'Iniciando sesión...';
  public siteKey:string;
  // public usuario : Client;

  public PreRegisterForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    ci: ['', [Validators.required, Validators.minLength(5)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    acceptTerms: [true, Validators.required],
    ciExpedition: ['', [Validators.nullValidator]],
    ciComplement: ['',[Validators.nullValidator]],
    recaptcha: ['', Validators.required],
  },
    {
      validators: this.passwordsIguales('password', 'confirmPassword')
  });

  public loginForm = this.fb.group({
    email: [ '' , [ Validators.required, Validators.email ] ],
    password: ['', Validators.required ],
  });

  public accountRecuperation = this.fb.group({
    emailRecuperation: ['', [Validators.required]],
  },
    {}
  );  

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService,
    private spinner: NgxSpinnerService
  ) {
    this.siteKey = '6LdBPxAcAAAAAN_UsFMSAAtU1Bo99mDZWEYL09v5';
   }

  ngOnInit(): void {
    var now = new Date();
    // 
    var isTrue = true;
    if( now.getTime() > Date.parse( localStorage.getItem('date_expiration') ) && localStorage.getItem('date_expiration') != null ) {
      this.clientService.refrescarToken()
      .subscribe(
        data => {
          this.client.email = localStorage.getItem('email');
          this.isAuth       = true;
        },
        err => {
          this.router.navigateByUrl('/inicio');
        }
      )
    } else {
      if( localStorage.getItem('email') != null ) {
        this.isAuth       = true;
        this.client.email = localStorage.getItem('email');
      }
    }
  }

  acceptTerms() {
    this.formSubmitted = true;
    if (this.PreRegisterForm.invalid) {
      return;
    }
    var btnAcceptTerms           = document.getElementById('aceppt_terms') as HTMLElement;
    btnAcceptTerms.click();
  }

  crearUsuario() {
    var btnPreregisterModal      = document.getElementById('preregister-modal') as HTMLElement;
    var btnPreregisterModalClose = document.getElementById('preregister-modal-close') as HTMLElement;
    this.formSubmitted = true;
    if (this.PreRegisterForm.invalid) {
      return;
    }
    var btnCloseTerms = document.getElementById('close_terms') as HTMLElement;
    btnCloseTerms.click();
    this.textInitSession = 'Creando cuenta...';
    this.spinner.show();

    this.clientService.crearUsuario(this.PreRegisterForm.value)
    .subscribe( resp => {
      setTimeout(() => {
        btnPreregisterModalClose.click();
        btnPreregisterModal.click();    
        this.spinner.hide();
      }, 2000);
    }, (err)=> {
      setTimeout(() => {
        this.spinner.hide();
        alert(err.error.message);
      }, 2000);
      return;
    });
  }

  campoNoValido(campo: string): boolean {
    if (this.PreRegisterForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  campoLoginNoValido(campo: string): boolean {
    if (this.loginForm.get(campo).invalid && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  contrasenasInvalidas() {
    const pass1 = this.PreRegisterForm.get('password').value;
    const pass2 = this.PreRegisterForm.get('confirmPassword').value;

    if ((pass1 != pass2) && this.formSubmitted) {
      return true;
    } else {
      return false;
    }
  }

  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup)=> {
      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);
      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null)
      } else {
        pass2Control.setErrors({ noEsigual: true })
      }
    }
  }

  login() {
    this.clientService.login( this.loginForm.value )
      .subscribe( resp => {
       if ( this.loginForm.get('remember').value ){ 
          localStorage.setItem('email', this.loginForm.get('email').value );
        } else {
          localStorage.removeItem('email');
          location.reload(); 
        }
        // Navegar al Dashboard
        this.router.navigateByUrl('/admin');
        location.reload(); 

      }, (err) => {
        alert( err.error.message );
      });
  }

  addEmailFails( email ) {
    if( localStorage.getItem('restrict_emails') == null ) {
      var restrict_email = {
        'restricts': [ { 'email' : this.loginForm.value.email, 'count':1, 'date': ''} ]
      };
      localStorage.setItem('restrict_emails',  JSON.stringify(restrict_email) );
    } else {
      var restricts = JSON.parse( localStorage.getItem('restrict_emails') )['restricts'];
      var tmpEmail  = email;
      var el = restricts.filter( function( elem ){
        return elem.email == tmpEmail
      });
      if( el.length == 0 ) {
          // Agregar al array
        var emailLoginError = { 'email' : this.loginForm.value.email, 'count':1, 'date': ''};
        restricts.push( emailLoginError );
      } else {
          // Adicionar número de errores
        el[0]['count'] = el[0]['count'] + 1;
        if( el[0]['count'] >= 3 ) {
          var dateNow = new Date(); 
          dateNow.setMinutes( dateNow.getMinutes() + this.timeLoginErrorExpiration ).toString();
          el[0]['date'] = dateNow; 
        }
      }
      localStorage.setItem('restrict_emails',  JSON.stringify({'restricts':restricts}) );
    }
  }

  verifyNumberAttemps( email ) {
    if( localStorage.getItem('restrict_emails') == null ) return {'status': false, 'date':''};
    var restricts = JSON.parse( localStorage.getItem('restrict_emails') )['restricts'];
    var tmpEmail  = email;
    var el = restricts.filter( function( elem ){
      return elem.email == tmpEmail
    });
    if( el.length == 0 ) return {'status': false, 'date':''};
    if( el[0]['count'] >= 3 ) {
      return {'status': true, 'date':el[0]['date']};
    }
    return {'status': false, 'date':''};
  }

  resetNumberAttempts( email ) {
    var restricts = JSON.parse( localStorage.getItem('restrict_emails') )['restricts'];
    var tmpEmail  = email;
    var el = restricts.filter( function( elem ){
      return elem.email == tmpEmail
    });
    if( el.length > 0 ) {
      el[0]['count'] = 1;
      el[0]['date']  = '';
      localStorage.setItem('restrict_emails',  JSON.stringify({'restricts':restricts}) );
    }
  }

  removeEmailStorage( email ) {
    if( localStorage.getItem('restrict_emails') != null ) {
      var restricts = JSON.parse( localStorage.getItem('restrict_emails') )['restricts'];
      var tmpEmail  = email;
      var elems = restricts.filter(function(elem){ 
        return elem.email != tmpEmail; 
      });
      localStorage.setItem('restrict_emails',  JSON.stringify({'restricts':elems}) );
    }
  }

  loginTwo() {
    this.formSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    var verifiyNumberAttempts = this.verifyNumberAttemps(this.loginForm.value.email);
    if( verifiyNumberAttempts['status'] ) {
      var now = new Date();
      var compareDate = Date.parse(verifiyNumberAttempts['date']);
      if( now.getTime() <= compareDate  ) {
        var msgAlert = `Usted ha excedido los 3 intentos para poder ingresar a la plataforma. Podrá volver a intentar iniciar sesión a partir de: ${new Date( verifiyNumberAttempts['date'] ).toLocaleString()}`
        alert(msgAlert);
        return;
      }
      this.resetNumberAttempts( this.loginForm.value.email );
    };
    this.textInitSession = 'Iniciando sesión...';
    this.spinner.show();
    this.clientService.login( this.loginForm.value )
      .subscribe( resp => {
        localStorage.removeItem('module');
        localStorage.removeItem('finish-1');
        localStorage.removeItem('finish-2');
        localStorage.removeItem('finish-3');
        localStorage.removeItem('finish-4');
        localStorage.removeItem('finish-5');
        setTimeout(() => {
          this.spinner.hide();
          if( typeof resp.error != 'undefined' ) {
            if( resp.error['state'] == '001' ) {
              this.addEmailFails( this.loginForm.value.email );
            } 
            alert( resp.error['message'] );
          } else{
            this.removeEmailStorage( this.loginForm.value.email );
            this.router.navigateByUrl('/registro');
          }
          var btnClose = document.getElementById('btn-close') as HTMLElement;
          btnClose.click();
        }, 1000);
        
      }, (err) => {
        setTimeout(() => {
          this.spinner.hide();
          alert( err.error.message );
        }, 1000)
      });
  }

  logout() {
    this.textInitSession = 'Cerrando sesión...';
    this.spinner.show();
    var lastWordUrl = (document.location.href).slice(-6);
    setTimeout(() => {
      localStorage.removeItem( 'email' );
      localStorage.removeItem( 'date_expiration' );
      localStorage.removeItem( 'token' );
      localStorage.removeItem('module');
      localStorage.removeItem('finish-1');
      localStorage.removeItem('finish-2');
      localStorage.removeItem('finish-3');
      localStorage.removeItem('finish-4');
      localStorage.removeItem('finish-5');
      this.router.navigateByUrl('/inicio');
      this.spinner.hide();
      if( lastWordUrl == 'inicio' ) {
        location.reload();
      }
    }, 1500);
  }

  recuperarCuenta( data ) {
    this.textInitSession = 'Recuperando cuenta...';
    this.spinner.show();
    this.clientService.recuperarCuenta( data['emailRecuperation'] )
      .subscribe( resp => {
        setTimeout(() => {
          this.spinner.hide();
          alert( resp['message'] ); 
          this.router.navigateByUrl('/inicio');
          location.reload();
        });
        // 
      }, (err) => {
        alert( err );
    });
  }

  clearData() {
    this.loginForm.reset();
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
