<swiper [config]="config" class="swiper_banner">
    <div class="swiper-wrapper">
        <!-- SLIDE 1 -->
        <div class="swiper-slide">
            <img class="w-100" src="assets/images/home-banner-init.png">
        </div>

        <!-- <div class="swiper-slide">
            <img class="w-100" src="assets/images/home-banner-4.png">
        </div>

        <div class="swiper-slide">
            <img class="w-100" src="assets/images/home-banner-5.png">
        </div> -->
    </div>
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
</swiper>

<div class="container home-page type-2">
    <div class="row">
        <div class="col-lg-12 text-center">
            <a *ngIf="this.isAuth && this.client.role == 'Admin'" class="btn main_btn bg-2 text-white py-2 px-4" routerLink="/admin"><strong>Administrar</strong></a>
        </div>
    </div>
    <div class="row mb-6">
        <div class="col-lg-8 text-center grid-resp mx-auto">
            <!-- <a routerLink="/modulo-1" class="btn main_btn bg-1 text-white wow bounceIn" data-wow-delay = "2.5s">QUIERO <STRONG>INICIAR</STRONG></a> -->
            <!-- <a *ngIf="certificate" (click)="descargarCertificado()" class="btn main_btn bg-2 text-white" data-wow-delay = ".5s"><span><STRONG>DESCARGAR CERTIFICADO</STRONG></span></a> -->
            <a id="btn-init" data-wow-delay = ".5s" data-toggle="modal" data-target=".login-modal"><span class="btn main_btn bg-1 text-white" *ngIf="!this.isAuth">QUIERO <STRONG>INICIAR</STRONG></span></a>
            <!-- <a *ngIf="this.client.completeRegister == false" routerLink="/registro" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>COMPLETA TU REGISTRO</STRONG></a>
            <p *ngIf="this.client.completeRegister == true && certificate == false" class="btn main_btn cursor-default bg-1 text-white">REGISTRO COMPLETO</p>
            <p *ngIf="allcompleted" class="btn main_btn bg-1 text-white">PROGRAMA COMPLETADO</p>  -->

            <!-- <a (click)="encryptText()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>Encript</STRONG></a> -->
            <!-- pruebas  -->
            <!-- <a (click)="refreshToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REFRESH TOKEN</STRONG></a>
            <a (click)="revokeToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REVOKE TOKEN</STRONG></a> -->
        </div>
    </div>
    <h1 class="mt-6 mb-5">Cursos disponibles:</h1>
    <div class="row mb-6">
        <div class="col-lg-8 text-center grid-resp mx-auto">
            <!-- <a routerLink="/modulo-1" class="btn main_btn bg-1 text-white wow bounceIn" data-wow-delay = "2.5s">QUIERO <STRONG>INICIAR</STRONG></a> -->
            <!-- <a *ngIf="certificate" (click)="descargarCertificado()" class="btn main_btn bg-2 text-white" data-wow-delay = ".5s"><span><STRONG>DESCARGAR CERTIFICADO</STRONG></span></a>
            <a id="btn-init" data-wow-delay = ".5s" data-toggle="modal" data-target=".login-modal"><span class="btn main_btn bg-1 text-white" *ngIf="!this.isAuth">QUIERO <STRONG>INICIAR</STRONG></span></a>
            <!-- <a *ngIf="this.client.completeRegister == false" routerLink="/registro" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>COMPLETA TU REGISTRO</STRONG></a>
            <p *ngIf="this.client.completeRegister == true && certificate == false" class="btn main_btn cursor-default bg-1 text-white">REGISTRO COMPLETO</p>
            <p *ngIf="allcompleted" class="btn main_btn bg-1 text-white">PROGRAMA COMPLETADO</p>  -->

            <!-- <a (click)="encryptText()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>Encript</STRONG></a> -->
            <!-- pruebas  -->
            <!-- <a (click)="refreshToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REFRESH TOKEN</STRONG></a>
            <a (click)="revokeToken()" class="btn main_btn bg-1 text-white wow bounceIn"><STRONG>REVOKE TOKEN</STRONG></a> -->

              <span routerLink="/inicial" data-wow-delay = ".5s" class="btn main_btn bg-6 text-white d-flex align-items-center justify-content-center">NIVEL INICIAL</span>
              <span routerLink="/intermedio" data-wow-delay = ".5s" class="btn main_btn bg-6 text-white d-flex align-items-center justify-content-center">NIVEL INTERMEDIO</span>
              <span routerLink="/avanzado" data-wow-delay = ".5s" class="btn main_btn bg-6 text-white d-flex align-items-center justify-content-center">NIVEL AVANZADO</span>
        </div>
    </div>
    <!-- <div class="row justify-content-center icons_section">
        <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center {{ this.finishedModules[1] == true ? ''  : 'locked' }}">
            <img src="assets/images/icons/icon-1-success.svg">
            <h4 class="color-2">Sistema Financiero, Rol de la ASFI y el Alcance de la Normativa</h4>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[2] == true ? ''  : 'locked' }}" data-wow-delay = "1s">
            <img src="assets/images/icons/icon-2-success.svg">
            <h4 class="color-2">Ahorro</h4>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[3] == true ? ''  : 'locked' }}" data-wow-delay = "1.5s">
            <img src="assets/images/icons/icon-3-success.svg">
            <h4 class="color-2">Crédito</h4>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[4] == true ? ''  : 'locked' }}" data-wow-delay = "2s">
            <img src="assets/images/icons/icon-4-success.svg">
            <h4 class="color-2">Instrumentos Electrónicos de Pago</h4>
        </div>
        <div class="col-xl-2 col-md-4 col-sm-4 col-4 text-center wow fadeInUp {{ this.finishedModules[5] == true ? ''  : 'locked' }}" data-wow-delay = "2.5s">
            <img src="assets/images/icons/icon-5-success.svg">
            <h4 class="color-2">Puntos de Atención Financiera</h4>
        </div>
    </div>
    <div class="row btn_down_section">
        <div class="col text-center">
            <a class="color-3" routerLink="/inicio" fragment="queaprenderas"><strong>¿QUÉ APRENDERÁS?</strong></a>
            <br>
            <img class="logo-click wow bounceIn" src="assets/images/icons/btn-down.svg" data-wow-iteration = "infinite">
        </div>
    </div> -->
</div>



<!-- <div [@openClose]="isOpen ? 'open' : 'closed'" class="open-close-container pointer d-flex align-items-center" (click)="toggle()">
    <p class="m-0 py-5">The box is now {{ isOpen ? 'Open' : 'Closed' }}!</p>
</div> -->

<section class="container-fluid affter_footer">
    <div class="row justify-content-center align-items-center py-3">
        <!-- <div class="col-lg-auto inline-block text-lg-left text-center">
            <img src="assets/images/elements/aj.png">
            <p class="aj-legend"><small>Actividad Autorizada y Fiscalizada por la Autoridad de Juegos.<br>CLICK Financiero, promoción válida del 28 de octubre de 2021 al 07 de febrero de 2022.</small></p>
        </div>

        <div class="col-lg-auto col-12 text-center">
            <a class="btn main_btn bg-3 text-white my-2" href="./assets/pdf/bases-legales.pdf" target="_blank">Ver bases legales</a>
        </div> -->

        <div class="col-lg-auto text-center">
            <h6 class="color-3">Síguenos en las redes</h6>
            <ul class="social_buttons mb-0">
                <li><a href="https://www.facebook.com/BancoEcofuturo/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/bancoecofuturo/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UC2doer5UJozi4Rx89nEM_0g" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                <li><a href="https://www.linkedin.com/company/banco-ecofuturo/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
        </div>
    </div>
</section>

<!-- TERMS & CONDITIONS-->
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="slide1-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content slide_modal">
            <div class="modal-body">
                <div class="row p-1 p-md-3 p-xl-5">
                    <div class="col text-justify">
                        <h3 class="color-1 text-center mt-2 mb-3">Bases Legales</h3>
                        <ul>
                            <li><p>Si el contenido es puntual, breve y no tuviera demasiados elementos gráficos se propone el actual popup.</p></li>
                            <li><p>Si el contenido abarca mucho texto o de alguna manera es extenso y tuviera otros elementos gráficos se recomiendo que esta vista este en una página y no un popup.</p></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus qui ratione, minima aliquam voluptatem nihil! Dolores, necessitatibus! Obcaecati rerum atque veniam adipisci sequi, aliquam tenetur odit facilis facere. Eligendi, impedit magnam. Commodi, earum aliquid asperiores velit animi esse sint ad nostrum quisquam repellat quasi, veniam cumque neque error, odit eaque!</p>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas eaque, reprehenderit unde reiciendis officiis quisquam minus a ducimus doloribus incidunt dicta officia eligendi voluptatum similique deserunt expedita molestiae neque fugiat voluptates ipsam minima ea consequuntur. Accusantium eius sapiente magnam dicta ratione distinctio maxime quidem! Repellat maiores sunt dolor dolorem numquam! Numquam, ducimus! Ipsa, iste, necessitatibus officiis reiciendis ex praesentium est dolores ratione vitae vel adipisci, dolore animi. Quibusdam repellat, quod molestias obcaecati, quos tempore voluptas aperiam, doloribus ullam ipsum reprehenderit!</p>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laudantium et sit impedit dolor inventore molestiae illo quibusdam neque vitae tenetur ipsa at hic facere voluptatum, laboriosam aliquid a? Explicabo blanditiis amet quo libero aliquid iste cum eos facere corporis, laborum nisi earum, dolores aliquam omnis, magnam aut soluta possimus adipisci?</p>
                    </div>
                    <div class="col-12 text-center">
                        <a class="btn bg-2 text-white px-5" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
